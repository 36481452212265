import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid-flow-row" }
const _hoisted_2 = {
  class: "grid grid-flow-col gap-1 px-2 w-full items-center overflow-hidden",
  style: {"grid-template-columns":"auto 1fr auto"}
}
const _hoisted_3 = { class: "grid-flow-col w-5/12" }
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = { class: "grid-flow-col w-full" }
const _hoisted_6 = { class: "h-full" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "grid-flow-rowtext-xs font-thin"
}
const _hoisted_9 = { class: "grid-flow-col" }
const _hoisted_10 = { class: "justify-center items-end h-full pl-2" }
const _hoisted_11 = { class: "font-thin text-right" }
const _hoisted_12 = { class: "font-thin text-xs text-right" }
const _hoisted_13 = { class: "border-b-2" }
const _hoisted_14 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-8",
  style: {"min-width":"2rem"},
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor",
  "stroke-width": "2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex w-full relative border-b-2 h-12 cursor-pointer", [{'h-auto':_ctx.hasFeatures},{'border-l-8':_ctx.hasAssociatedItems},{'bg-indigo-500 text-white':_ctx.isSelected},{'hover:bg-gray-50':!_ctx.isSelected},{'h-16':!_ctx.isAssociatedItem && (_ctx.rowData.observation === null || (typeof _ctx.rowData.observation !== 'undefined' && _ctx.rowData.observation.length === 0))},{'h-12':_ctx.isAssociatedItem}]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{'pl-8':_ctx.isAssociatedItem}, "w-full justify-center items-center overflow-hidden"]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setAsSelectedRow && _ctx.setAsSelectedRow(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass((_ctx.isAssociatedItem) ? 'font-thin': 'font-bold')
              }, _toDisplayString(_ctx.rowData.quantity), 3),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-thin" }, "×", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", {
                class: _normalizeClass([[{'font-bold':!_ctx.isAssociatedItem},{'font-semibold':_ctx.isAssociatedItem}], "text-xs lg:text-sm"])
              }, _toDisplayString(_ctx.rowData.description), 3),
              (_ctx.rowData.observation)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "text-xs truncate font-thin ml-2 border-l-2 px-2 mt-1",
                    innerHTML: _ctx.rowData.observation.replaceAll('\n','<br>')
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
              (_ctx.hasPickup)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("span", {
                      class: _normalizeClass(["rounded", [{'bg-white text-indigo-600': _ctx.isSelected},{'bg-indigo-600 text-white': !_ctx.isSelected}]]),
                      style: {"padding":"1px 3px"}
                    }, _toDisplayString(_ctx.rowData.pickupDate), 3)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.lineTotal) + " €", 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.unitPrice) + " €", 1)
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rowData.features, (feature) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "grid-flow-col w-full h-auto px-6",
            key: _ctx.index
          }, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(feature.featureValue), 1)
          ]))
        }), 128))
      ])
    ], 2),
    (!_ctx.isPortrait)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["grid grid-flow-col justify-center items-center overflow-hidden bg-red-500 text-white", [_ctx.isSelected ? 'w-1/6' : 'w-0']]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fnRemoveSelectedItem && _ctx.fnRemoveSelectedItem(...args)))
        }, [
          (_openBlock(), _createElementBlock("svg", _hoisted_14, _cache[3] || (_cache[3] = [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            }, null, -1)
          ])))
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}