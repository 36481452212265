
import {defineComponent} from "vue";
import {mapActions, mapMutations} from "vuex";

export default defineComponent({
  name: 'ModalClientSearchResultItem',
  components: {},
  props: ['customer'],
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['setCustomerBeingEditedID', 'setSelectedCustomerData', 'clearCustomerSearchString', 'clearCustomerSearchResults']),
    ...mapActions(['closeActiveModal','openModal']),
    setCustomer() {
      this.setSelectedCustomerData(this.customer);
      this.clearCustomerSearchString()
      this.clearCustomerSearchResults()
      this.closeActiveModal()
    },
    editCustomer() {
      this.setCustomerBeingEditedID(this.customer.id)
      this.closeActiveModal() /* close this modal */
      this.openModal('ModalNewCustomer')
    }
  },
})
