import {POSFunctionObject} from "@/interfaces/POSFunctionObject";

const POSFunctionsData = [
    {
        description: "Adicionar item",
        fn: "fnAddItem",
        usesParameter: true,
    },
    {
        description: "Selecionar cliente",
        fn: "fnSelectCustomer",
        usesParameter: false,
    }, {
        description: "Limpar cliente selecionado",
        fn: "fnSetFinalCustomer",
        usesParameter: false,
    },
    {
        description: "Quantidade artigo",
        fn: "fnChangeItemQuantity",
        usesParameter: false,
    },
    {
        description: "Preço artigo",
        fn: "fnChangeItemPrice",
        usesParameter: false
    },
    {
        description: "Observações da linha",
        fn: "fnChangeItemObservations",
        usesParameter: false
    },
    {
        description: "Pesquisar artigos a inserir",
        fn: "fnSearchItems",
        usesParameter: false
    },
    {
        description: "Limpar artigos",
        fn: "fnClearItems",
        usesParameter: false
    },
    {
        description: "Terminar documento",
        fn: "fnCreateInvoiceFromWorkDocument",
        usesParameter: false
    },
    {
        description: "Tipo de documento",
        fn: "fnSetDocumentType",
        usesParameter: true
    },
    {
        description: "Série do documento",
        fn: "fnSelectDocumentSeries",
        usesParameter: true
    },
    {
        description: "Terminar sessão",
        fn: "fnLogout",
        usesParameter: false
    },
    {
        description: "Mudar página",
        fn: "fnPage",
        usesParameter: true
    },
    {
        description: "Página anterior",
        fn: "fnMoveToPreviousPagination",
        usesParameter: false
    },
    {
        description: "Página seguinte",
        fn: "fnMoveToNextPagination",
        usesParameter: false
    },
    {
        description: "Remover artigo",
        fn: "fnRemoveSelectedItem",
        usesParameter: false
    },
    {
        description: "Dividir conta",
        fn: "fnOpenSplitBillView",
        usesParameter: false
    },
    {
        description: "Alterar linha de preço",
        fn: "fnSelectPriceLine",
        usesParameter: true
    },
    {
        description: "Sem mesa",
        fn: "fnDeselectTable",
        usesParameter: false
    },
    {
        description: "Reimprimir documento",
        fn: "fnInvoiceCopy",
        usesParameter: false
    },
    {
        description: "Fechar caixa",
        fn: "fnCloseSession",
        usesParameter: false
    },
    {
        description: "Reimprimir fechos",
        fn: "fnOpenUserClosedSessionsView",
        usesParameter: false
    },
    {
        description: "Dia de levantamento semanal",
        fn: "fnPickupDateWeekday",
        usesParameter: true
    },
    {
        description: "Observações documento",
        fn: "fnOpenDocumentObservations",
        usesParameter: false
    },

    {
        description: "Consultar sessão atual",
        fn: "fnShowCurrentSessionTotals",
        usesParameter: false
    },
    {
        description: "Movimentos do dia",
        fn: "fnShowOnlineMovements",
        usesParameter: false
    },
    {
        description: "Limpar dia de levantamento",
        fn: "fnClearPickupDate",
        usesParameter: false
    },
    {
        description: "Ecrã de Levantamento / Pickup",
        fn: "fnOpenDeliveryView",
        usesParameter: false
    },
    {
        description: "Abrir gaveta",
        fn: "fnOpenDrawer",
        usesParameter: false
    },
    {
        description: "Gerar páginas",
        fn: "fnGeneratePages",
        usesParameter: false
    },
    {
        description: "Sair da aplicação",
        fn: "fnQuit",
        usesParameter: false
    },
    {
        description: "Comentários",
        fn: "fnFeatures",
        usesParameter: false
    },

] as POSFunctionObject[]

export {
    POSFunctionsData
}