
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {v4} from "uuid";
import {XIcon} from "@heroicons/vue/solid";

export default defineComponent({
  name: 'KeyboardInput',
  components: {
    XIcon,
  },
  props: ['modelValue', 'keyboardLabel', 'inputClass', 'placeholder', 'mainClass', 'buttonClass', 'autoFocus', 'hideClearButton', 'required', 'type'],
  emits: ['update:modelValue', 'enterPressed'],
  data() {
    return {
      content: this.modelValue,
      uuid: v4(),
    }
  },
  computed: {
    ...mapGetters(['getLastInputData', 'getLastKeypress']),
    isMobileDevice(): any {
      return (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))
    }
  },
  watch: {
    modelValue() {
      this.content = this.modelValue
    },
    'getLastInputData'() {
      if (this.getLastInputData[0] === this.uuid) {
        this.content = this.getLastInputData[1]
        this.updateModelValue()
      }
    },
    'getLastKeypress'() {
      if (this.getLastKeypress[0] === this.uuid && this.getLastKeypress[1] === '{enter}') {
        this.emitEnterPressed()
      }
    }
  },
  methods: {
    ...mapMutations(['setLastInputUUID', 'setLastKeypress', 'setKeyboardInputContent', 'setKeyboardInputLabel']),
    ...mapActions(['toggleKeyboard']),
    controlKeyboard() {
      this.setActiveInput()
      this.setKeyboardInputLabel(this.keyboardLabel)
      this.toggleKeyboard(this.uuid)
    },
    updateModelValue() {
      this.$emit('update:modelValue', this.content)
    },
    setActiveInput() {
      this.setLastInputUUID(this.uuid)
      this.setKeyboardInputContent(this.content)
    },
    emitEnterPressed() {
      this.$emit('enterPressed')
    },
    clearInput() {
      this.content = ''
      this.updateModelValue()
    }
  },
  mounted() {
    // AUTOFOCUS
    if (this.autoFocus)
      (this.$refs as any).keyboardInput.focus()
  }
})
