import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full bg-raisinBlack-300 py-8 px-4 flex flex-col" }
const _hoisted_2 = { class: "flex justify-center px-4 py-2 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyboardInput = _resolveComponent("KeyboardInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-lg font-semibold text-white" }, " Observação documento ", -1)),
    _createElementVNode("div", null, [
      _createVNode(_component_KeyboardInput, {
        modelValue: _ctx.documentObservation,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.documentObservation) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
          class: "bg-orange-500 hover:bg-orange-400 text-white text-lg shadow rounded px-2 py-2"
        }, " Cancelar ")
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "bg-green-500 hover:bg-green-400 text-white font-semibold text-lg shadow rounded px-2 py-2 w-32"
        }, " Aplicar ")
      ])
    ])
  ]))
}