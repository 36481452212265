
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "DocumentSerialLabel",
  data() {
    return {}
  },
  props: [],
  computed: {
    ...mapGetters(['workDocumentSeries'])
  },
  methods: {},
  watch: {},
  mounted() {
  }
})
