
import {defineComponent} from "vue";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import Keyboard from "@/components/Keyboards/Keyboard.vue";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";
import {mapActions, mapMutations} from "vuex";

export default defineComponent({
  name: "ModalWorkingCapital",
  components: {KeyboardInput, Keyboard, NumericKeyboard},
  props: [],
  data() {
    return {
      inputPrice: '',
    }
  },
  methods: {
    ...mapActions(['closeActiveModal', 'setAddItemPrice']),
    ...mapMutations(['sessionWorkingCapital']),
    numpadClicked(value: string) {
      if (value === '<') {
        // delete last number
        if (this.inputPrice) {
          this.inputPrice = this.inputPrice.slice(0, -1);
        }
      } else {
        this.inputPrice += value
      }
    },
    save() {
      if (this.inputPrice) {
        this.sessionWorkingCapital(this.inputPrice)
      }
      this.closeActiveModal()
    }
  },
  watch: {},
  mounted() {
  }
})
