
import {defineComponent} from "vue";
import NumericKeyboardKey from "@/components/Keyboards/NumericKeyboardKey.vue";

export default defineComponent({
  name: 'NumericKeyboard',
  components: {NumericKeyboardKey},
  emits: ['clicked'],
  props: [],
  data() {
    return {
      keys: [1, 2, 3, 4, 5, 6, 7, 8, 9, '<', 0, '.'],
    }
  },
  computed: {},
  methods: {
    clicked(value: string) {
      this.$emit('clicked', value)
    }
  },
  mounted() {
  }
})
