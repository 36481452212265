
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: 'DebugView',
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {

  },
  methods: {
    ...mapMutations(['setActiveModal']),
    ...mapActions(['closeActiveModal']),
  },
  mounted() {
    this.setActiveModal('ModalItemQuantity')

    setTimeout(() => {
      this.closeActiveModal()
    },2000)
  }
})
