<template>
  <div class="bg-raisinBlack-200 text-white h-screen">
    <div :class="['p-1 w-full h-screen grid gap-1',gridRowsClass,gridColumnsClass]">
      <PageButton v-for="button of currentPageButtons" :key="button.uuid" :button="button"></PageButton>
    </div>
  </div>
</template>«

<script>
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import PageButton from "@/components/PageButton";

export default defineComponent({
  name: "OptionsView",
  props: [],
  components: {
    PageButton
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['currentPageDimensions', "currentPage", "defaultPageFunctions", "currentPageButtons"]),
    gridRowsClass() {
      return `grid-rows-${this.currentPageDimensions.rows}`
    },
    gridColumnsClass() {
      return `grid-cols-${this.currentPageDimensions.columns}`
    }
  },
  methods: {
    ...mapActions(["generateOptionsPage"])
  },
  mounted() {
    this.generateOptionsPage()
  }
})
</script>

<style scoped>

</style>
