import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute top-0 left-0 w-screen h-screen flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeActiveModal && _ctx.closeActiveModal(...args))),
      class: "absolute top-0 left-0 w-screen h-screen bg-black opacity-30 z-10"
    }),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeModal), { class: "z-20" }))
  ]))
}