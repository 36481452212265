const Series = {
    state: {
        series: localStorage.getItem('series') || '[]'
    },
    getters: {
        series(state: any) {
            return JSON.parse(state.series);
        }
    },
    mutations: {
        series(state: any, series: any) {
            state.series = JSON.stringify(series)
            localStorage.setItem('series', JSON.stringify(series))
        }
    },
    actions: {
        async fetchSeries(state: any) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}series/?ativas=1`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(series => {
                        state.commit('series', series)
                        resolve(series)
                    })
                    .catch(err => reject(err))
            })
        }
    }
}

export {
    Series
}
