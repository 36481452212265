
import {defineComponent} from "vue";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/vue/outline";

export default defineComponent({
  name: "SelectList",
  data() {
    return {
      indexItemsList: 0,
      endItemsList: 0,
      selectedIndex: 9999999,
      itemsHeight: 150,
      sizesItemsList: 0
    }
  },
  components: {
    ChevronUpIcon,
    ChevronDownIcon
  },
  emits: ['clicked'],
  props: ['List', 'label', 'listClass', 'itemClass', 'selectedItem', 'maxListSize'],
  computed: {

    compressedList: function (): any {
      let newList = [];

      for (let i = this.indexItemsList; i < this.endItemsList; i++) {
        if (this.List[i]) {
          newList.push(this.List[i])
        }
      }
      return newList
    },
    nextButtonState: function () {
      return (this.endItemsList < this.List.length);
    },
    previousButtonState: function () {
      return this.indexItemsList > 0;
    }
  },
  methods: {
    maximumGridRows() {
      if (this.maxListSize > 0) return this.maxListSize

      let value = Math.floor(window.innerHeight / this.itemsHeight)

      value = value > this.List.length && this.List.length > 0 ? this.List.length : value

      return value
    },
    clicked(object: any, index: number) {
      this.selectedIndex = index;
      this.$emit('clicked', object)
    },
    next: function () {
      if (this.nextButtonState) {
        this.indexItemsList++;
        this.endItemsList++;
        this.selectedIndex--;
      }
    },
    previous: function () {
      if (this.previousButtonState) {
        this.indexItemsList--;
        this.endItemsList--;
        this.selectedIndex++;
      }
    },
    changeListSize() {
      this.sizesItemsList = this.maximumGridRows()
      this.endItemsList = this.indexItemsList + this.sizesItemsList

      if (this.compressedList.length < this.sizesItemsList && this.indexItemsList > 0) {
        this.endItemsList = this.sizesItemsList
        this.indexItemsList--
      }
    }
  },
  watch: {
    List(newList, oldList) {
      if (newList.length < oldList.length)
        if (newList.length == 0)
          this.selectedIndex = 9999999
        else {
          if (this.selectedIndex + 1 > newList.length) {
            this.selectedIndex = this.selectedIndex - 1
          }
        }
    },
    selectedItem(newItem) {
      if (newItem.id == "")
        this.selectedIndex = 9999999
    }
  },
  mounted() {
    this.changeListSize()

    window.addEventListener('resize', () => {
      this.changeListSize()
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.changeListSize()
    });
  },
})
