import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3 grid-rows-4 w-full border select-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumericKeyboardKey = _resolveComponent("NumericKeyboardKey")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keys, (key) => {
      return (_openBlock(), _createBlock(_component_NumericKeyboardKey, {
        onClicked: _ctx.clicked,
        key: key,
        text: key,
        "click-value": key
      }, null, 8, ["onClicked", "text", "click-value"]))
    }), 128))
  ]))
}