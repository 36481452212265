
import {defineComponent} from "vue";
import {XIcon} from "@heroicons/vue/outline";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";
import {mapActions} from "vuex";
import POSnotyf from "@/libs/POSnotyf"
import {IFetchPagesFromAPIParams} from "@/interfaces/IFetchPagesFromAPIParams";

export default defineComponent({
  name: "ModalSwitchEmployee",
  data() {
    return {
      componentName: 'ModalSwitchEmployee',
      pin: '',
      loading: false
    }
  },
  components: {
    NumericKeyboard,
    XIcon
  },
  props: [],
  computed: {},
  methods: {
    ...mapActions(['fetchPagesFromAPI','closeActiveModal', 'fetchTokenUsingPIN', "showStatusMessage", 'closeStatusMessage',"setUserDefaultDocumentType","setUserDefaultDocumentSeries"]),

    numpadClicked(value: string) {
      if (value === '<') {
        // delete last number
        if (this.pin) {
          this.pin = this.pin.slice(0, -1);
        }
      } else {
        this.pin += value

        if(this.pin.length === 6){
          this.login()
        }
      }
    },
    async login() {
      try {
        this.loading = true
        await this.fetchTokenUsingPIN(this.pin)
        this.loading = false
        // close PIN modal

        //this.addStatusLogMessage('A obter páginas...')

        await this.fetchPagesFromAPI({
          forcePageGeneration: true,
        } as IFetchPagesFromAPIParams)

        await this.closeActiveModal()
        POSnotyf.success(`Utilizador alterado!`)

        // set user document type and series
        await this.setUserDefaultDocumentType()
        await this.setUserDefaultDocumentSeries()


      } catch (err) {
        POSnotyf.error("PIN não existente!")
        this.loading = false
        this.pin = ''
      }
    },
    async close() {
      await this.closeActiveModal()
    }
  },
  watch: {},
  mounted() {
  }
})
