import {StartNewSessionParams} from "@/interfaces/StartNewSessionParams";
import {EndUserSessionParams} from "@/interfaces/EndUserSessionParams";
import {FetchUserClosedSessionsParams} from "@/interfaces/FetchUserClosedSessionsParams";

const Sessions = {
    state: {
        sessionStations: [],
        sessionData: {},
        sessionStatus: {},
        sessionWorkingCapital: 0 as number,
    },
    mutations: {
        sessionStations(state: any, sessionStations: any) {
            state.sessionStations = sessionStations
        },
        sessionData(state: any, sessionData: any) {
            state.sessionData = sessionData
        },
        sessionStatus(state: any, sessionStatus: any) {
            state.sessionStatus = sessionStatus
        },
        sessionWorkingCapital(state: any, sessionWorkingCapital: number) {
            state.sessionWorkingCapital = sessionWorkingCapital
        },
    },
    getters: {
        sessionStations(state: any) {
            return state.sessionStations
        },
        getSessionData(state: any) {
            return state.sessionData
        },

        getSessionStatus(state: any) {
            return state.sessionStatus
        },
        getDefaultSessionAgent(state: any, getters: any) {
            if (state.sessionData) {
                if (state.sessionData.posto_defeito) {
                    if (state.sessionData.posto_defeito.length > 0) {
                        return state.sessionData.posto_defeito
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        getSessionWorkingCapital(state: any): number {
            return state.sessionWorkingCapital
        }
    },
    actions: {
        fetchSessionData(state: any) {
            return new Promise((resolve, reject) => {

                let formData = new FormData()
                formData.append('id_utilizador', state.getters.userData.id)

                fetch(`${state.getters.apiURL}sessaoutilizador`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData
                })
                    .then(res => res.json())
                    .then(sessionData => {
                        state.commit('sessionData', sessionData)
                        resolve(sessionData)
                    })
                    .catch(err => reject(err))
            })
        },
        startNewSession(state: any, options: StartNewSessionParams) {
            return new Promise<void>(async (resolve, reject) => {

                // prepare POST data
                let formData = new FormData()
                formData.append('id_utilizador', options.user_id.toString())
                formData.append('id_caixa', options.station_id.toString())
                formData.append('valor_abertura', options.start_value.toString())


                // send API request
                fetch(`${state.getters.apiURL}abrirsessao`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then(async res => {
                        if (res === 'ok') {
                            await state.dispatch('fetchSessionData')
                            resolve()
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        endUserSession(state: any, params: EndUserSessionParams) {
            return new Promise<void>(async (resolve, reject) => {

                // check for station id
                if (params.station_id && params.user_id) {
                    // get current session data
                    const sessionData = await state.dispatch('fetchSessionData')

                    // POST data
                    let formData = new FormData()
                    formData.append('id_utilizador', params.user_id.toString())
                    formData.append('id_caixa', params.station_id.toString())

                    fetch(`${state.getters.apiURL}fecharsessao`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        },
                        body: formData,
                    })
                        .then(res => res.text())
                        .then(res => {
                            if (res === 'ok') {
                                resolve(sessionData)
                            } else {
                                reject(res)
                            }
                        })
                        .catch(err => reject(err))
                } else {
                    resolve()
                }


            })
        },
        fetchSessionStations(state: any) {
            return new Promise<void>((resolve, reject) => {
                fetch(`${state.getters.apiURL}obtercaixas/?ativas=1`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                })
                    .then(res => res.json())
                    .then(sessionStations => {
                        state.commit('sessionStations', sessionStations)
                        resolve(sessionStations)
                    })
                    .catch(err => reject(err))
            })
        },
        fetchUserClosedSessions(state: any, params: FetchUserClosedSessionsParams) {
            return new Promise<any>((resolve, reject) => {

                let formData = new FormData()
                formData.append('id_utilizador', state.getters.userData.id)
                formData.append('data_inicial', params.startDate)
                formData.append('data_final', params.endDate)

                fetch(`${state.getters.apiURL}sessoesutilizador`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: formData,
                })
                    .then(res => res.json())
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },
        /**
         * get the current session information
         * @param state
         */
        async fetchCurrentSessionStatus(state: any) {
            try {
                /* append session id to form data */
                const formData = new FormData()
                formData.append('id_sessao', state.getters.getSessionData.id_sessao)

                /* fetch session information from API */
                const response = await fetch(`${state.getters.apiURL}consultasessao`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: formData,
                })

                /* if good response we parse the data to json */
                if (response.ok) {

                    /* get JSON session status data */
                    const sessionStatus = await response.json()
                    console.log("sessionStatus", sessionStatus)
                    /* store session status */
                    state.commit('sessionStatus', sessionStatus)

                    return sessionStatus
                } else {
                    throw new Error(response.statusText)
                }
            } catch (e) {
                throw new Error(e.message)
            }
        }
    },
}

export {
    Sessions
}