
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: 'StatusView',
  components: {},
  props: [],
  data() {
    return {
      statusTimeout: false
    }
  },
  computed: {
    ...mapGetters(['getStatusMessage', 'getStatusLogProgress', "getStatusLogMessages"]),
    statusMessage() {
      return this.getStatusMessage;
    },
    progressbarValueStyle() {
      return `width:30%`
    }
  },
  methods: {
    ...mapActions(['closeStatusMessage']),
  },
  mounted() {
    // show cancel button after 10s
    setTimeout(() => {
      this.statusTimeout = true
    }, 10000)
  }
})
