import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-96 bg-white rounded px-6 py-3" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex flex-col gap-2" }
const _hoisted_4 = { class: "grid grid-cols-3" }
const _hoisted_5 = { class: "col-span-2" }
const _hoisted_6 = { class: "grid grid-cols-12 auto-cols-auto gap-2" }
const _hoisted_7 = { class: "col-span-5" }
const _hoisted_8 = { class: "col-span-7" }
const _hoisted_9 = { class: "grid grid-cols-2 auto-cols-auto gap-2 mt-6" }
const _hoisted_10 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_KeyboardInput = _resolveComponent("KeyboardInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "font-bold text-xl" }, "DADOS CLIENTE", -1)),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "h-8 w-8 border bg-red-500 hover:bg-red-600 text-white rounded shadow",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, [
          _createVNode(_component_XIcon)
        ])
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
      class: "mt-2"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "font-semibold text-gray-700 text-sm" }, "NIF", -1)),
            _createVNode(_component_KeyboardInput, {
              required: true,
              modelValue: _ctx.customer.vat,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.vat) = $event)),
              "auto-focus": true,
              "main-class": ['border shadow'],
              "keyboard-label": 'NIF'
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[12] || (_cache[12] = _createElementVNode("label", { class: "font-semibold text-gray-700 text-sm" }, "Nome", -1)),
          _createVNode(_component_KeyboardInput, {
            modelValue: _ctx.customer.name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.name) = $event)),
            "main-class": ['border shadow'],
            "keyboard-label": 'Nome'
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", null, [
          _cache[13] || (_cache[13] = _createElementVNode("label", { class: "font-semibold text-gray-700 text-sm" }, "Morada", -1)),
          _createVNode(_component_KeyboardInput, {
            modelValue: _ctx.customer.address,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customer.address) = $event)),
            "main-class": ['border shadow'],
            "keyboard-label": 'Morada'
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[14] || (_cache[14] = _createElementVNode("label", { class: "font-semibold text-gray-700" }, "CP", -1)),
            _createVNode(_component_KeyboardInput, {
              modelValue: _ctx.customer.zipcode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.zipcode) = $event)),
              "main-class": ['border shadow'],
              "input-class": ['text-xs'],
              "keyboard-label": 'Código Postal'
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { class: "font-semibold text-gray-700 text-sm" }, "Localidade", -1)),
            _createVNode(_component_KeyboardInput, {
              modelValue: _ctx.customer.city,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.city) = $event)),
              "main-class": ['border shadow'],
              "input-class": ['text-xs'],
              "keyboard-label": 'Localidade'
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", null, [
            _cache[16] || (_cache[16] = _createElementVNode("label", { class: "font-semibold text-gray-700 text-sm" }, "Telefone", -1)),
            _createVNode(_component_KeyboardInput, {
              modelValue: _ctx.customer.phone,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customer.phone) = $event)),
              "main-class": ['border shadow'],
              "keyboard-label": 'Telefone'
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[17] || (_cache[17] = _createElementVNode("label", { class: "font-semibold text-gray-700 text-sm" }, "Telemóvel", -1)),
            _createVNode(_component_KeyboardInput, {
              modelValue: _ctx.customer.mobile,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.mobile) = $event)),
              "main-class": ['border shadow'],
              "keyboard-label": 'Telemóvel'
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[18] || (_cache[18] = _createElementVNode("label", { class: "font-semibold text-gray-700 text-sm" }, "Email", -1)),
          _createVNode(_component_KeyboardInput, {
            "input-type": 'email',
            modelValue: _ctx.customer.email,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customer.email) = $event)),
            "main-class": ['border shadow'],
            "keyboard-label": 'Email'
          }, null, 8, ["modelValue"])
        ])
      ]),
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "mt-6 py-2 border-t" }, [
        _createElementVNode("button", {
          type: "submit",
          class: "w-full p-1 border shadow rounded text-white bg-green-400 hover:bg-green-500"
        }, "OK")
      ], -1))
    ], 32)
  ]))
}