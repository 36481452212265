const Pickup = {
    state: {
        pickupPendingLines: [],
    },
    mutations: {
        pickupPendingLines(state: any, pickupPendingLines: any) {
            state.pickupPendingLines = pickupPendingLines
        }
    },
    getters: {
        pickupPendingLines(state: any): any {
            return state.pickupPendingLines
        }
    },
    actions: {
        fetchCustomerPendingPickups(state: any, customerID: number) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}linhascliente/?p=${customerID}&satisfeitas=0`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                })
                    .then(res => res.json())
                    .then(pickupPendingLines => resolve(pickupPendingLines))
                    .catch(err => reject(err))
            })
        },
        setLineQuantityAsProcessed(state: any, lineID: number, quantityToProcess: number = 1) {
            return new Promise<void>((resolve, reject) => {

                // build form data
                let formData = new FormData()
                formData.append('mov', lineID.toString())
                formData.append('qtd', quantityToProcess.toString())

                fetch(`${state.getters.apiURL}adicionarqtdencomenda`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: formData,
                })
                    .then(res => res.json())
                    .then(res => {
                        (res.codigo === 0) ? resolve() : reject(res)
                    })
                    .catch(err => reject(err))
            })
        }
    }
}

export {
    Pickup
}
