import {NewCustomerData} from "@/interfaces/NewCustomerData";

const Customers = {
    state: {
        searchingCustomersStatus: false,
        customerSearchString: '',
        customersSearchResults: [],
        selectedCustomerData: {},
        customerDataEmail: '',
        customerBeingEditedID: 0,
    },
    getters: {
        customerSearchString(state: any): string {
            return state.customerSearchString
        },
        customersSearchResults(state: any): any {
            return state.customersSearchResults
        },
        getSearchingCustomerStatus(state: any): boolean {
            return state.searchingCustomersStatus
        },
        getSelectedCustomerData(state: any): any {
            return state.selectedCustomerData
        },
        getCustomerDataEmail(state: any): any {
            return state.customerDataEmail
        },
        customerBeingEditedID(state: any): number {
            return state.customerBeingEditedID
        }
    },
    mutations: {
        setCustomerSearchString(state: any, customerSearchString: string) {
            state.customerSearchString = customerSearchString
        },
        clearCustomerSearchString(state: any) {
            state.customerSearchString = ''
        },
        setCustomersSearchResults(state: any, customersSearchResults: any) {
            state.customersSearchResults = customersSearchResults
        },
        clearCustomerSearchResults(state: any) {
            state.customersSearchResults = []
        },
        toggleSearchingCustomersStatus(state: any) {
            state.searchingCustomersStatus = !state.searchingCustomersStatus
        },
        setSelectedCustomerData(state: any, customerData: any) {
            state.selectedCustomerData = customerData
            this.commit('setCustomerDataEmail',customerData)
        },
        // save the email for send email costumer data gets erase on printerSelection view
        setCustomerDataEmail(state: any, customerData: any) {
            state.customerDataEmail = customerData.email;
        },
        clearSelectedCustomerData(state: any) {
            state.selectedCustomerData = {}
        },
        setSelectedCustomerVAT(state: any, customerVAT: string) {
            state.selectedCustomerData = {
                id: "0",
                nif: customerVAT,
                nome: "",
                morada: "",
                morada2: "",
                telefone: "",
                telemovel: "",
                cp: "",
                email: "",
                designacao_comercial: "",
                codigo_pais: "PT",
            }
        },
        setCustomerBeingEditedID(state: any, customerBeingEditedID: number) {
            state.customerBeingEditedID = customerBeingEditedID
        },
        clearCustomerBeingEditedID(state: any) {
            state.customerBeingEditedID = 0
        }
    },
    actions: {
        searchCustomers(state: any) {
            state.commit('toggleSearchingCustomersStatus')
            return new Promise<any>((resolve, reject) => {
                state.commit('setCustomersSearchResults', [])
                fetch(`${state.getters.apiURL}clientesactivos/?p=${state.getters.customerSearchString}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(customersSearchResults => {
                        state.commit('setCustomersSearchResults', customersSearchResults)
                        resolve(customersSearchResults)
                        state.commit('toggleSearchingCustomersStatus')
                    })
                    .catch(err => {
                        reject(err)
                        state.commit('toggleSearchingCustomersStatus')
                    })
            })
        },
        createCustomer(state: any, newCustomerData: NewCustomerData) {
            return new Promise<number>((resolve, reject) => {

                // generate form data
                const formData = new FormData();
                formData.append('nome', newCustomerData.name)
                formData.append('morada', newCustomerData.address)
                formData.append('cp', `${newCustomerData.zipcode} ${newCustomerData.city}`)
                formData.append('nif', newCustomerData.vat)
                formData.append('telefone', newCustomerData.phone)
                formData.append('telemovel', newCustomerData.mobile)
                formData.append('email', newCustomerData.email)

                let customerIDParam = ''
                if (newCustomerData.id) {
                    customerIDParam = `p=${newCustomerData.id}`
                }

                // send customer data to Goldylocks
                fetch(`${state.getters.apiURL}gerircliente/?${customerIDParam}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then(res => {
                        if (parseInt(res) > 0) {
                            /* new customer */
                            resolve(parseInt(res))
                        } else if (res === 'ok') {
                            /* edit customer */
                            resolve(0)
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        fetchCustomerByID(state: any, customerID: number) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}cliente/?p=${customerID}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(customerData => resolve(customerData))
                    .catch(err => reject(err))
            })
        },
        setCustomerByVAT(state: any, VATNumber: string) {
            return new Promise<void>(async (resolve) => {
                state.commit('setCustomerSearchString', VATNumber)
                let customerData = await state.dispatch('searchCustomers')

                if (customerData.length > 0) {
                    // customer already exists
                    state.commit('setSelectedCustomerData', customerData[0])
                } else {
                    // new customer
                    state.commit('setSelectedCustomerVAT', VATNumber)
                }

                resolve()
            })
        },
    }
}

export {
    Customers
}
