import {WorkDocumentItem} from "@/interfaces/WorkDocumentItem";
import {WorkDocument} from "@/store/modules/WorkDocument";
import POSnotyf from "@/libs/POSnotyf";

const SplitInvoice = {
    state: {
        originalSplitItems: [],
        remainingSplittedItems: [],
        // use to make divideBy people
        splitByPeople: [],
        listDividedPerson: [],
        //number of people to divide bill
        numOfPeople: 0 as number,

    },
    mutations: {
        setOriginalSplitItems(state: any, originalItems: WorkDocumentItem[]) {
            state.originalSplitItems = JSON.parse(JSON.stringify(originalItems))
            state.remainingSplittedItems = originalItems
        },
        setRemainingSplittedItems(state: any, remainingItems: WorkDocumentItem[]) {
            state.remainingSplittedItems = remainingItems
        },
        clearOriginalSplitItems(state: any) {
            state.originalSplitItems = []
        },
        clearRemainingSplittedItems(state: any) {
            state.remainingSplittedItems = []
        },
        clearSplittedItems(state: any) {
            state.originalSplitItems = []
            state.remainingSplittedItems = []
        },
        setDivideListPerson(state: any, listPersons: WorkDocumentItem[]) {
            state.listDividedPerson = listPersons
        },
        removeLineFromSplittedItems(state: any, line: WorkDocumentItem) {

            let arr = [];
            for (let item of state.remainingSplittedItems) {
                if (item !== line) {
                    arr.push(item)
                }
            }

            state.remainingSplittedItems = arr
        },

        addLineToSplittedItems(state: any, line: WorkDocumentItem) {
            if (line) {
                let arr = state.remainingSplittedItems
                arr.push(line)
                state.remainingSplittedItems = arr
            }
        },

        incrementSplittedItemQuantity(state: any, attr: { line: WorkDocumentItem, nItems: number }) {
            let arr = []
            let itemFound = false

            // check the array for the item
            for (let item of state.remainingSplittedItems) {
                if (item.id === attr.line.id && item.description === attr.line.description && attr.line.price === item.price) {
                    // if the item exists increments the quantity by 1
                    item.quantity = item.quantity + (attr.nItems ?? 1)
                    itemFound = true
                }

                // add the item to the array to be stored
                arr.push(item)
            }

            // if the item doesn't exist on the split list its created with 1 quantity
            if (!itemFound) {
                let copy = {...attr.line}
                copy.quantity = (attr.nItems ?? 1)
                arr.push(copy)
            }

            state.remainingSplittedItems = arr
        },

        decrementSplittedItemQuantity(state: any, attr: { line: WorkDocumentItem, nItems: number }) {
            let arr = []

            for (let item of state.remainingSplittedItems) {
                if (item === attr.line) {
                    item.quantity = item.quantity - attr.nItems ?? 1
                }

                arr.push(item)
            }

            state.remainingSplittedItems = arr
        },


        /*------------------------------------------------ Functions for split bill per person ---------------------------------------------------------*/

        clearlistDividedPersonList(state: any, i: number) {
            // clear the list of items on split by person
            state.listDividedPerson[i].items = []
        },

        clearlistDividedPerson(state: any) {
            state.listDividedPerson = []

        },
        clearSplitByPeople(state: any) {
            state.splitByPeople = []
        },

        lengthListDividedPerson(state: any) {
            return state.listDividedPerson.length - 1
        },

        personIncrementSplittedItemLine(state: any, attr: { line: WorkDocumentItem, bill: number }) {

            //move all line from work documents to split bill by person for the activeView (bill: number)
            let arr = [];
            let itemFound = false;
            let i = attr.bill

            // check the array for the item
            for (let item of state.listDividedPerson[i].items) {
                if ((item.id === attr.line.id) && (item.price === attr.line.price) && (item.description === attr.line.description)) {

                    item.quantityPerPersonTotal = Number((item.quantityPerPersonTotal + attr.line.quantity).toFixed(2))

                    item.quantity = item.quantityPerPersonTotal

                    itemFound = true

                }

            }

            // if the item doesn't exist on the split list its created
            if (!itemFound) {
                let copy = {...attr.line}

                // now we need to work the quantity transform to quantityPerPersonTotal

                copy.quantityPerPersonTotal = attr.line.quantity
                // here we prepare the field quantity to move from split side to worDocument side
                copy.quantity = attr.line.quantityPerPerson
                arr.push(copy)
            }

        },

        personIncrementSplittedItemQuantity(state: any, attr: { line: WorkDocumentItem, bill: number }) {

            // move quantityPerPerson from WorkDocument to split
            let itemFound = false;
            let i = attr.bill;

            // check the array for the item
            for (let item of state.listDividedPerson[i].items) {

                if (item.id === attr.line.id && item.description === attr.line.description && item.price === attr.line.price) {

                    // when divide by person the item may not have a equal % to each person, so the last one get the difference.
                    // verify if the item have the difference
                    if (item.difference !== 0) {
                        // verify is the last part where have difference and move that part
                        if ((item.quantityPerPerson + item.difference) === attr.line.quantity) {

                            item.quantityPerPersonTotal = Number((item.quantityPerPersonTotal + attr.line.quantity).toFixed(2));
                        } else {
                            item.quantityPerPersonTotal = Number((item.quantityPerPersonTotal + attr.line.quantityPerPerson).toFixed(2))

                        }

                        itemFound = true;
                    } else {

                        item.quantityPerPersonTotal = Number((item.quantityPerPersonTotal + item.quantityPerPerson).toFixed(2));

                        itemFound = true;
                    }
                }
            }


            if (!itemFound) {
                let copy = attr.line;

                if (attr.line.difference !== 0) {
                    // verify is the last part where have difference and move that part
                    if ((attr.line.quantityPerPerson + attr.line.difference) === attr.line.quantity) {
                        copy.quantityPerPersonTotal = attr.line.quantity
                        copy.quantityPerPerson = Math.floor(copy.quantityPerPerson*100)/100

                    } else {
                        copy.quantityPerPersonTotal = attr.line.quantityPerPerson
                        copy.quantityPerPerson = Math.floor(copy.quantityPerPerson*100)/100
                    }

                } else {

                    copy.quantityPerPersonTotal = attr.line.quantityPerPerson
                    copy.quantityPerPerson = Math.floor(copy.quantityPerPerson*100)/100
                }


                state.listDividedPerson[i].items.push(copy);
            }
        },

        personAddLineToSplittedItems(state: any, item: { line: WorkDocumentItem, bill }) {

            //its call when we move an entire line to split and the item is not on split side

            if (item.line) {

                let i = item.bill;
                item.line.quantityPerPersonTotal = item.line.quantity
                let arr = state.listDividedPerson[i].items
                arr.push(item.line)
                state.listDividedPerson[i].items = arr
            }
        },

        PersonRemoveLineFromSplittedItems(state: any, params: { line: WorkDocumentItem, bill: number }) {

            // this function remove item from split side, for when divide by number of people
            // on splitByPerson only can get items from split by Quantity per person
            // only work the quantity, price is made by work document  and is calculated Quantity * price no need to change price

            let arr = [];
            let i = params.bill

            for (let item of state.listDividedPerson[i].items) {

                if (item !== params.line) {
                    arr.push(item)
                } else {

                    //person can pay for the entire item, so we verify if there is more quantity from the divised bill per person
                    // quantityPerPersonTotal is the quantity we calculate on the split side
                    if (item.quantityPerPersonTotal > item.quantityPerPerson) {

                        item.quantityPerPersonTotal = Number((item.quantityPerPersonTotal - item.quantityPerPerson).toFixed(2))

                        arr.push(item)
                    }
                }

            }

            state.listDividedPerson[i].items = arr
        },

        splitNumOfPeople(state: any, num: number) {
            state.numOfPeople = num
        },

        setSplitByPeople(state: any, data: []) {
            state.splitByPeople = data
        },
        clearNumOfPeople(state: any) {
          state.numOfPeople = 0
        },

        orderDivideListByPeople(state: any) {

            //calculate the total price for each person
            for (let j=0; j<state.numOfPeople; j++) {
                let countPrice = 0
                state.listDividedPerson[j].items.forEach(getItem => {
                    countPrice = (countPrice+(getItem.quantityPerPersonTotal*parseFloat(getItem.price)))
                })
                countPrice = Math.floor(countPrice * 100) / 100 + 0.01;
                state.listDividedPerson[j].totalPrice = countPrice
            }
            // order desc the division
            state.listDividedPerson.sort((a, b) => b.totalPrice - a.totalPrice);

        },

    },
    getters: {
        getOriginalSplitItems(state: any) {
            return state.originalSplitItems
        },
        getRemainingSplittedItems(state: any) {
            return state.remainingSplittedItems
        },
        getNumOfPeople(state: any) {
            return state.numOfPeople
        },
        getSplitByPeople(state: any) {
            return state.splitByPeople
        },
        getListDividedPerson(state: any) {
            return state.listDividedPerson
        },

    },
    actions: {
        copyRemainingSplittedItemsToWorkDocument(state: any) {
            if (state.getters.getRemainingSplittedItems.length > 0) {
                state.commit('workDocumentItems', state.getters.getRemainingSplittedItems)
                state.commit('clearSplittedItems')
            }
        },

        copyListDividedPerson(state: any) {
            let arr = state.getters.getListDividedPerson
            let newArr = []

            if(state.getters.workDocumentHasItems){
                newArr = state.getters.workDocumentItems

            }

            for (let i = 0; i < arr.length; i++) {

                if (arr[i].items && arr[i].items.length > 0) {

                    for (let item of arr[i].items) {
                        // Check if an identical item already exists in newArr
                        const existingItemIndex = newArr.findIndex(
                            existingItem =>
                                existingItem.id === item.id &&
                                existingItem.description === item.description &&
                                existingItem.price === item.price
                        );

                        if (existingItemIndex !== -1) {
                            // If item exists, update its quantity
                            newArr[existingItemIndex].quantity = Number((newArr[existingItemIndex].quantity + item.quantityPerPersonTotal).toFixed(2));

                        } else {
                            // If item doesn't exist, add it to newArr
                            newArr.push({
                                ...item,
                            });
                        }
                    }
                }
            }
                state.commit('workDocumentItems', newArr);

        },

        copyListDividedPersonAfterPaymente(state: any) {
            let arr = state.getters.getListDividedPerson;
            let newArr: any[] = [];

            for (let i = 0; i < arr.length; i++) {

                if (arr[i].items && arr[i].items.length > 0) {

                    for (let item of arr[i].items) {
                        // Check if an identical item already exists in newArr
                        const existingItemIndex = newArr.findIndex(
                            existingItem =>
                                existingItem.id === item.id &&
                                existingItem.description === item.description &&
                                existingItem.price === item.price
                        );

                        if (existingItemIndex !== -1) {
                            // If item exists, update its quantity
                            newArr[existingItemIndex].quantity = Number((newArr[existingItemIndex].quantity + item.quantityPerPersonTotal).toFixed(2));

                        } else {
                            // If item doesn't exist, add it to newArr
                            newArr.push({
                                ...item,
                            });
                        }
                    }
                }
            }

            if(newArr.length > 0) {
                state.commit('workDocumentItems', newArr);
            }
            else{
                state.commit('clearlistDividedPerson');
                state.commit('clearSplitByPeople')
                state.commit('clearNumOfPeople')
                state.commit('workDocumentItems', newArr);
                state.dispatch('saveTableItems')
            }

        },


        verifySplitItems(state: any): boolean {

            let arr = state.getters.getListDividedPerson;
            let newArr: any[] = [];

            for (let i = 0; i < arr.length; i++) {

                if (arr[i].items && arr[i].items.length > 0) {

                    for (let item of arr[i].items) {
                        // Check if an identical item already exists in newArr
                        const existingItemIndex = newArr.findIndex(
                            existingItem =>
                                existingItem.id === item.id &&
                                existingItem.description === item.description &&
                                existingItem.price === item.price
                        );

                        if (existingItemIndex !== -1) {
                            // If item exists, update its quantity
                            newArr[existingItemIndex].quantity = Number((newArr[existingItemIndex].quantity + item.quantityPerPersonTotal).toFixed(2));

                        } else {
                            // If item doesn't exist, add it to newArr
                            newArr.push({
                                ...item,
                            });
                        }
                    }
                }
            }

            return !!newArr.length;

        },

        copyOriginalSplitItemsToWorkDocument(state: any) {
            if (state.getters.getOriginalSplitItems.length > 0) {
                state.commit('workDocumentItems', state.getters.getOriginalSplitItems)
                state.commit('clearSplittedItems')
            }
        },

        IncrementSplittedItemLineOption(state: any, params: {
            item: WorkDocumentItem,
            isQuantityPerPerson: boolean,
            isQuantityTotal: boolean,
        }) {
            if (params.isQuantityPerPerson) {
                params.item.quantity = params.item.quantityPerPerson
                state.commit('addItem', params.item)
                state.isQuantityPerPerson = false
            }
            if (params.isQuantityTotal) {
                params.item.quantityPerPersonTotal = params.item.quantity
                state.commit('addItem', params.item)
                state.isQuantityPerPersonTotal = false
            }
        },

       canDivideListByPeople(state: any) {
            let isNumberValid = true;



           let newArray = [...state.getters.getOriginalSplitItems];
            // Create list for each person with new fields for calculations
            let tempArray = newArray.map(newArrayItem => ({
                ...newArrayItem,

                quantity: Math.floor(newArrayItem.quantity * 100) / 100,
                quantityPerPerson: Math.floor((newArrayItem.quantity / state.getters.getNumOfPeople) * 100) / 100,
                quantityPerPersonTotal: Math.floor((newArrayItem.quantity / state.getters.getNumOfPeople) * 100) / 100,
                difference: 0,  // this value is to get difference to entire product
            }))

            // Adjust difference to each item using a for...of loop
            for (let item of tempArray) {

                if (!isNumberValid) {
                    break;  // Break the loop if an invalid condition is detected
                }

                // Variables used for exact decimal
                let quantityRet = (item.quantityPerPerson * state.getters.getNumOfPeople);
                let difference = item.quantity - quantityRet;
                let temp: number = Number(difference.toFixed(2));

                let newPrice = Number(item.price);
                let pricePerPerson = (newPrice / state.getters.getNumOfPeople);
                item.quantity = item.quantityPerPersonTotal;
                item.difference = temp;

                if (item.quantityPerPerson < 0.01 || pricePerPerson < 0.01) {
                    isNumberValid = false;

                }
            }


            // Commit if the check was valid
            if (isNumberValid){
                state.commit('setSplitByPeople', tempArray)
                state.dispatch('divideListByPeople');
            }

            return isNumberValid;
        },

        divideListByPeople(state: any) {

            state.commit('clearlistDividedPerson')
            let newArray = []

            // make list of products to each person use on SplitByPeopleView
            for (let i = 0; i < state.getters.getNumOfPeople; i++) {

                const personItems = state.getters.getSplitByPeople.map(item => ({
                    ...item,

                }));

                newArray.push({
                    person: i,
                    items: personItems,
                    totalPrice: 0,
                });
            }
            state.commit('setDivideListPerson', newArray)
            state.dispatch('differenceOnListByPeople')
        },

        differenceOnListByPeople(state: any ) {
            let newArray = state.getters.getListDividedPerson;

            // Function to apply difference
            const applyDifferenceToPerson = (product) => {
                // order list by total price
                state.commit('orderDivideListByPeople');
                let orderedArray = state.getters.getListDividedPerson;
                let targetPerson = orderedArray[orderedArray.length - 1]; // Última pessoa (quem paga menos)

                for (let item of targetPerson.items) {
                    if (
                        item.id === product.id &&
                        item.description === product.description &&
                        item.price === product.price
                    ) {
                        // add difference
                        item.quantityPerPersonTotal += product.difference;
                        item.quantityPerPersonTotal = Math.floor(item.quantityPerPersonTotal * 100) / 100;
                        item.quantity = item.quantityPerPersonTotal;

                        // refresh status
                        state.commit('setDivideListPerson', orderedArray);

                        return; // Sai após aplicar a diferença
                    }
                }
            };


            // get last person
            const lastPersonIndex = newArray.length - 1;
            let lastPerson = newArray[lastPersonIndex];

            lastPerson.items.forEach((product) => {
                if (product.difference > 0) {
                    // Apply difference
                    applyDifferenceToPerson(product);
                    state.commit('orderDivideListByPeople');
                }
            });

        },

    },
}

export {
    SplitInvoice
}
