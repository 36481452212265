import {NewCustomerData} from "@/interfaces/NewCustomerData";
import {WorkDocumentItem} from "@/interfaces/WorkDocumentItem";
import {PrintCurrentInvoiceParameters} from "@/interfaces/PrintCurrentInvoiceParameters";
import {swal} from "@/libs/POSSwal";
import splitBillView from "@/components/SplitBillView.vue";

const Invoicing = {
    state: {
        invoice: {
            createdInvoiceId: 0 as number,
            customerData: {} as NewCustomerData,
            docTypeId: 0 as number,
            docSeries: '' as string,
            items: [] as WorkDocumentItem[],
            paymentMethodId: '' as string,
        },
        invoiceView: false,
        lastInvoicesResultsLimit: '100',
        isFetchingLastInvoices: false,
        lastInvoices: [],
        lastInvoicesStartDate: '',
        lastInvoicesEndDate: '',
        lastInvoiceDocumentType: '',
    },
    mutations: {
        invoiceCustomerData(state: any, customerData: NewCustomerData) {
            state.invoice.customerData = customerData
        },
        invoiceDocTypeId(state: any, docTypeId: number) {
            state.invoice.docTypeId = docTypeId
        },
        invoiceDocSeries(state: any, docSeries: string) {
            state.invoice.docSeries = docSeries
        },
        invoiceItems(state: any, items: WorkDocumentItem[]) {
            state.invoice.items = items
        },
        invoicePaymentMethodId(state: any, paymentMethodId: string) {
            state.invoice.paymentMethodId = paymentMethodId
        },
        createdInvoiceId(state: any, createdInvoiceId: number) {
            state.createdInvoiceId = createdInvoiceId
        },
        lastInvoices(state: any, lastInvoices: any) {
            state.lastInvoices = lastInvoices
        },
        clearLastInvoices(state: any) {
            state.lastInvoices = []
        },
        setLastInvoicesStartDate(state: any, startDate: string) {
            state.lastInvoicesStartDate = startDate
        },
        setLastInvoicesEndDate(state: any, endDate: string) {
            state.lastInvoicesEndDate = endDate
        },
        setInvoiceViewState(state: any, invoiceViewState: boolean) {
            state.invoiceView = invoiceViewState
        },
        startedFetchingLastInvoices(state: any) {
            state.isFetchingLastInvoices = true
        },
        stoppedFetchingLastInvoices(state: any) {
            state.isFetchingLastInvoices = false
        },
        setLastInvoiceDocumentTypeId(state: any, documentTypeId: string) {
            state.lastInvoiceDocumentType = documentTypeId
        },
    },
    getters: {
        invoiceData(state: any): any {
            return state.invoice
        },
        createdInvoiceId(state: any): number {
            return state.createdInvoiceId
        },
        invoiceDocTypeId(state: any): number {
            return state.invoice.docTypeId
        },
        invoiceTotal(state: any, getters: any): string {
            let total = 0
            for (let item of getters.invoiceData.items) {
                total += item.price * item.quantity

            }

            return parseFloat(total.toString()).toFixed(2)
        },
        lastInvoices(state: any) {
            return state.lastInvoices
        },
        getLastInvoicesStartDate(state: any) {
            return state.lastInvoicesStartDate
        },
        getLastInvoicesEndDate(state: any) {
            return state.lastInvoicesEndDate
        },
        getInvoiceViewState(state: any) {
            return state.invoiceView
        },
        getIsFetchingLastInvoices(state: any) {
            return state.isFetchingLastInvoices
        },
        lastInvoicesResultsLimit(state: any) {
            return state.lastInvoicesResultsLimit
        },
        getLastInvoiceDocumentType(state: any): string {
            return state.lastInvoiceDocumentType
        }
    },
    actions: {
        async createBaseInvoiceDocument(state: any) {
            return new Promise<string>((resolve, reject) => {
                // invoice data
                let invoice = state.getters.invoiceData

                // FORM DATA to send
                const formData = new FormData()

                // invoice type and series
                formData.append('tipo_documento', invoice.docTypeId)
                formData.append('serie_documento', invoice.docSeries)
                formData.append('id_utilizador', state.getters.userData.id)

                // Goldylocks document creation API request
                fetch(`${state.getters.apiURL}criardocumento`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then((createdInvoiceId: string) => {
                        if (isFinite(parseInt(createdInvoiceId))) {
                            state.commit('createdInvoiceId', createdInvoiceId)
                            resolve(createdInvoiceId)
                        } else {
                            reject(createdInvoiceId)
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        setInvoiceCustomerFromSelectedCustomer(state: any) {
            // set invoice customer data
            if (state.getters.getSelectedCustomerData) {
                let customerData = state.getters.getSelectedCustomerData
                state.commit('invoiceCustomerData', {
                    id: customerData.id,
                    vat: customerData.nif,
                    name: customerData.nome,
                    address: customerData.morada,
                    zipcode: customerData.cp,
                    city: '',
                    country: customerData.codigo_pais,
                    phone: customerData.telefone,
                    mobile: customerData.mobile,
                    email: customerData.email,
                })
            }
        },
        async setInvoiceDocumentCustomerData(state: any) {
            return new Promise<void>((resolve, reject) => {

                let formData = new FormData()
                let customer = state.getters.invoiceData.customerData

                // fill customer data
                let customerID = (customer.id > 0) ? customer.id : '999999990'
                formData.append('id', customerID)
                formData.append('nif', customer.vat || '')
                formData.append('nome', customer.name || '')
                formData.append('morada', customer.address || '')
                formData.append('morada2', customer.address || '')
                formData.append('cp', customer.zipcode || '')
                formData.append('codigoPais', customer.country || '')
                formData.append('telefone', customer.phone || '')
                formData.append('telemovel', customer.mobile || '')
                formData.append('email', customer.email || '')


                fetch(`${state.getters.apiURL}alterarclientedocumento/?p=${state.getters.createdInvoiceId}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then(res => {
                        if (res === '[]') {
                            resolve()
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        async setInvoiceDocumentPaymentMethod(state: any, value: number = 0) {
            return new Promise<void>(async (resolve, reject) => {

                let formData = new FormData()
                let paymentMethod = await state.dispatch('getPaymentMethodById', state.getters.invoiceData.paymentMethodId)

                let payedValue = (value > 0) ? value : state.getters.invoiceTotal

                // fill payment method data
                formData.append('tipopagamento', state.getters.invoiceData.paymentMethodId)
                formData.append('categoriaTipoPagamento', paymentMethod.tipo_meio_pagamento)
                formData.append('banco', '')
                formData.append('n_cheque', '')
                formData.append('pag_net', '')
                formData.append('trans', '')
                formData.append('valor', payedValue)
                formData.append('serieDocumentoDevolucao', state.getters.invoiceData.docSeries)
                formData.append('numeroDocumentoDevolucao', '')

                fetch(`${state.getters.apiURL}inserirpagamentodocumento/?p=${state.getters.createdInvoiceId}&td=${state.getters.invoiceData.docTypeId}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then(res => {
                        if (res === 'ok') {
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        async setInvoiceLicensePlate(state: any) {

            // instante a form data object
            const formData = new FormData();

            // fill form data
            formData.append("id_documento", state.getters.createdInvoiceId);
            formData.append("matricula", state.getters.getLicensePlate);

            try {
                const response = await fetch(`${state.getters.apiURL}definirmatriculadocumento`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })

                if (response.ok) {
                    const textResponse = await response.text()
                    return (textResponse === 'ok')
                } else {
                    return false
                }
            } catch (e) {
                console.error(e)
                return false
            }


        },
        async addInvoiceLines(state: any) {
            return new Promise<void>(async (resolve, reject) => {
                for (let item of state.getters.invoiceData.items) {

                    // prepare POST body
                    let formData = new FormData()
                    formData.append('id_documento', state.getters.createdInvoiceId)
                    formData.append('id_artigo', item.id)
                    formData.append('linha', '1')
                    formData.append('descricao', item.description)
                    formData.append('observacao', item.observation)
                    formData.append('quantidade', item.quantity)
                    formData.append('preco_imposto', item.price)
                    formData.append('desconto', item.discount)

                    // pickup date
                    if (item.pickupDate)
                        formData.append('data_entrega', item.pickupDate)

                    // POST request
                    try {
                        await fetch(`${state.getters.apiURL}inserirlinha`, {
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer ${state.getters.token}`
                            },
                            body: formData,
                        })
                    } catch (err) {
                        reject(err)
                    }
                }

                resolve()
            })
        },
        addInvoiceObservation(state: any, observation: string) {
            return new Promise<void>((resolve, reject) => {

                let formData = new FormData()
                formData.append('observacao', observation)

                fetch(`${state.getters.apiURL}observacoesdocumento/?p=${state.getters.createdInvoiceId}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then(res => {
                        (res === 'ok') ? resolve() : reject(res)
                    })
                    .catch(err => reject(err))
            })
        },
        async terminateInvoice(state: any) {
            return new Promise<void>(async (resolve, reject) => {

                try {
                    state.dispatch('setInvoiceCustomerFromSelectedCustomer')


                    // set customer data to empty
                    if (state.getters.invoiceData.customerData)
                        await state.dispatch('setInvoiceDocumentCustomerData')

                    // add invoice lines
                    await state.dispatch('addInvoiceLines')

                    // set invoice payment method
                    // Removed on 1.1.9
                    // await state.dispatch('setInvoiceDocumentPaymentMethod')

                    // set invoice observations
                    if (state.getters.hasObservation) {
                        // send invoice observation
                        await state.dispatch('addInvoiceObservation', state.getters.observation)

                        // clear stored observation
                        state.commit('setObservation', '')
                    }
                } catch (err) {
                    reject(err)
                }

                // store previous total
                state.commit('previousTotal', state.getters.workDocumentTotal)


                // clear current work document data
                await state.dispatch('newWorkDocument')

                // send invoice end to API
                await fetch(`${state.getters.apiURL}fechardocumento/?p=${state.getters.createdInvoiceId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.text())
                    .then(res => {
                        if (res === 'ok') {
                            // clear table status
                            if (state.getters.currentTable) {

                                //this is use to SplitByPeopleView
                                if (state.getters.getListDividedPerson.length > 0) {
                                    // call copyListDividedPersonAfterPaymente and there now what to do next

                                    state.dispatch('copyListDividedPersonAfterPaymente')

                                }
                                    //this is use by SplitBillView
                                // check for remaining splitted items and save them to the table
                                else if (state.getters.getRemainingSplittedItems.length > 0) {

                                    state.dispatch('copyRemainingSplittedItemsToWorkDocument')
                                    state.dispatch('saveTableItems')
                                    state.commit('clearSplittedItems')

                                } else {
                                    // clears the table if all the items have been paid
                                    state.dispatch('clearTableStatus', state.getters.currentTable.id_mesa)
                                }
                            }

                            resolve()
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        async createGoldylocksInvoice(state: any) {
            return new Promise<number>(async (resolve, reject) => {
                try {
                    // create and get base invoice id
                    await state.dispatch('createBaseInvoiceDocument')
                } catch (err) {
                    reject(err)
                }

                if (state.getters.createdInvoiceId) {
                    resolve(state.getters.createdInvoiceId)
                } else {
                    reject()
                }
            })
        },
        async printCurrentInvoice(state: any, params: PrintCurrentInvoiceParameters) {
            return new Promise<void>(async (resolve, reject) => {
                try {
                    await state.dispatch('printDocument', {
                        agentID: params.agentID,
                        printerName: params.printerName,
                        invoiceID: state.getters.createdInvoiceId,
                        documentType: state.getters.invoiceDocTypeId,
                    })

                    resolve()
                } catch (err) {
                    reject(err)
                    console.log(err)
                }
            })
        },
        async fetchLastInvoices(state: any) {

            return new Promise<any>((resolve, reject) => {
                state.commit('startedFetchingLastInvoices')
                fetch(`${state.getters.apiURL}/documentos/?td=${state.getters.getLastInvoiceDocumentType}&p=venda&limite=${state.getters.lastInvoicesResultsLimit}&estado=fechados&data_inicial=${state.getters.getLastInvoicesStartDate}&data_final=${state.getters.getLastInvoicesEndDate}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                }).then(res => res.json())
                    .then(lastInvoices => {
                        state.commit('lastInvoices', lastInvoices)

                        resolve(lastInvoices)
                    })
                    .catch(err => reject(err))
                    .finally(() => state.commit('stoppedFetchingLastInvoices'))
            })
        },

        async setFetchValues(state: any, body: any) {
            state.commit('setLastInvoiceDocumentTypeId', body.workDocument)
            state.commit('setLastInvoicesStartDate', body.startDate)
            state.commit('setLastInvoicesEndDate', body.endDate)
        },

        async getDocumentRows(state: any, id: number) {
            const response = await fetch(`${state.getters.apiURL}linhas/?serie=todas&num=${id}&tipodoc=todos&order=ASC`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`
                }
            })

            return response.json()

            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}linhas/?serie=todas&num=${id}&tipodoc=todos&order=ASC`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                }).then(res => res.json())
                    .then(rows => {
                        resolve(rows)
                    })
            })
        },

    },
}

export {
    Invoicing
}