
import {defineComponent} from "vue";
import {SearchIcon} from "@heroicons/vue/outline";
import {mapActions, mapGetters} from "vuex";
import SelectList from "@/components/Lists/SelectList.vue";

export default defineComponent({
  name: "ModalPaymentCheck",
  data() {
    return {
      componentName: "ModalPaymentMethods",
    }
  },
  components: {
    SearchIcon,
    SelectList
  },
  props: [],
  computed: {
    ...mapGetters(['paymentMethods'])
  },
  methods: {
    ...mapActions(['closeActiveModal']),
  },
  watch: {},
  mounted() {
  }
})
