
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectList from "@/components/Lists/SelectList.vue";
import {SearchIcon} from "@heroicons/vue/outline";

export default defineComponent({
  name: "ModalDocumentSerie",
  data() {
    return {}
  },
  components: {
    SelectList,
    SearchIcon
  },
  props: [],
  computed: {
    ...mapGetters(['series'])
  },
  methods: {
    ...mapMutations(['setSeries']),
    ...mapActions(['closeActiveModal']),
    selected(seriesData: any) {
      this.setSeries(seriesData.serie)
      this.close()
    },
    close() {
      this.closeActiveModal()
    }
  },
  watch: {},
  mounted() {
  }
})
