import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "primary-bg-color h-screen w-full p-8 flex flex-col gap-8" }
const _hoisted_2 = { class: "flex justify-around" }
const _hoisted_3 = { class: "flex flex-col sm:flex-row justify-around" }
const _hoisted_4 = { class: "w-full flex justify-center" }
const _hoisted_5 = { class: "w-full flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectList = _resolveComponent("SelectList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "primary-text text-2xl uppercase" }, " Selecione fecho a reimprimir ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-white font-lg font-semibold" }, "Inicio", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          class: "text-lg px-2 py-2",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event))
        }, null, 512), [
          [_vModelText, _ctx.startDate]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-white font-lg font-semibold" }, "Fim", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          class: "text-lg px-2 py-2",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event))
        }, null, 512), [
          [_vModelText, _ctx.endDate]
        ])
      ])
    ]),
    _createVNode(_component_SelectList, {
      onClicked: _ctx.selected,
      List: _ctx.closedUserSessions,
      label: 'datahora'
    }, null, 8, ["onClicked", "List"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: _normalizeClass([[{'opacity-30':_ctx.selectedSession===null}], "bg-green-600 hover:bg-green-700 text-white p-4 w-64 uppercase font-semibold"]),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.print && _ctx.print(...args)))
        }, "Imprimir", 2)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "bg-indigo-600 hover:bg-indigo-700 text-white p-4 w-64 uppercase font-semibold",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.searchUserClosedSessions && _ctx.searchUserClosedSessions(...args)))
        }, "Procurar")
      ])
    ])
  ]))
}