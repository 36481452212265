import { withKeys as _withKeys, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["type", "required", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex w-full bg-white", _ctx.mainClass])
  }, [
    _withDirectives(_createElementVNode("input", {
      type: _ctx.type?_ctx.type:'text',
      ref: "keyboardInput",
      required: _ctx.required,
      placeholder: _ctx.placeholder,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setActiveInput && _ctx.setActiveInput(...args))),
      onKeypress: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitEnterPressed && _ctx.emitEnterPressed(...args)), ["enter"])),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateModelValue && _ctx.updateModelValue(...args))),
      class: _normalizeClass(["p-2 w-full", _ctx.inputClass]),
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.content) = $event))
    }, null, 42, _hoisted_1), [
      [_vModelDynamic, _ctx.content]
    ]),
    (!_ctx.hideClearButton)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clearInput && _ctx.clearInput(...args))),
          class: "flex items-center justify-center w-12 text-gray-400 hover:text-gray-500 cursor-pointer"
        }, [
          _createVNode(_component_XIcon, { class: "h-4" })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isMobileDevice)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.controlKeyboard && _ctx.controlKeyboard(...args))),
          class: _normalizeClass([_ctx.buttonClass, "cursor-pointer flex items-center justify-center text-gray-400 hover:text-gray-500 w-12 border-l"])
        }, _cache[6] || (_cache[6] = [
          _createStaticVNode("<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z\" fill=\"currentColor\"></path><path d=\"M9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H9Z\" fill=\"currentColor\"></path><path d=\"M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z\" fill=\"currentColor\"></path><path d=\"M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z\" fill=\"currentColor\"></path><path d=\"M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z\" fill=\"currentColor\"></path><path d=\"M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.55228 15.4477 9 16 9Z\" fill=\"currentColor\"></path><path d=\"M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z\" fill=\"currentColor\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5Z\" fill=\"currentColor\"></path></svg>", 1)
        ]), 2))
      : _createCommentVNode("", true)
  ], 2))
}