import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col h-screen" }
const _hoisted_2 = { class: "flex flex-row justify-between items-center text-white" }
const _hoisted_3 = { class: "grid grid-cols-5 w-full text-white" }
const _hoisted_4 = { class: "bg-white flex-auto overflow-y-scroll" }
const _hoisted_5 = { class: "grid" }
const _hoisted_6 = { class: "text-white bg-raisinBlack-600 shadow-lg" }
const _hoisted_7 = { class: "grid grid-cols-3" }
const _hoisted_8 = { class: "flex w-full text-xs p-2" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "text-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_OperationsSectionDetailsRow = _resolveComponent("OperationsSectionDetailsRow")!
  const _component_DocumentTypeLabel = _resolveComponent("DocumentTypeLabel")!
  const _component_DocumentSerialLabel = _resolveComponent("DocumentSerialLabel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMobileOperations && _ctx.openMobileOperations(...args))),
      class: "h-16 bg-raisinBlack-600 flex justify-center items-center cursor-pointer select-none"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ChevronLeftIcon, { class: "h-8" }),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, "Voltar", -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openView('TableSelection'))),
        class: "bg-raisinBlack-400 p-4 col-span-4"
      }, _toDisplayString(_ctx.currentTable.descricao), 1),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openSettings && _ctx.openSettings(...args))),
        class: "bg-teal-600 p-4 col-span-1 flex items-center justify-center"
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          class: "h-6 w-6",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          }),
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          })
        ], -1)
      ]))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workDocumentItems, (itemRow, index) => {
          return (_openBlock(), _createBlock(_component_OperationsSectionDetailsRow, {
            key: index,
            "row-data": itemRow
          }, null, 8, ["row-data"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          class: "bg-orange-500 p-4 uppercase font-semibold",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.fnRemoveSelectedItem && _ctx.fnRemoveSelectedItem(...args)))
        }, "Apagar Linha"),
        _createElementVNode("button", {
          class: "bg-red-500 p-4 uppercase font-semibold",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.fnClearItems && _ctx.fnClearItems(...args)))
        }, "Limpar Artigos"),
        _createElementVNode("button", {
          class: "bg-green-400 p-4 uppercase font-semibold flex justify-center",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.saveTableItemsMobile && _ctx.saveTableItemsMobile(...args)))
        }, " Guardar Mesa ")
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.workDocumentTypeName)
          ? (_openBlock(), _createBlock(_component_DocumentTypeLabel, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(" Sem fatura ")
            ], 64)),
        _cache[9] || (_cache[9] = _createElementVNode("span", null, " - ", -1)),
        (_ctx.workDocumentSeries)
          ? (_openBlock(), _createBlock(_component_DocumentSerialLabel, { key: 2 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createTextVNode(" Sem série ")
            ], 64))
      ]),
      _createElementVNode("button", {
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.createInvoice && _ctx.createInvoice(...args))),
        class: "h-16 w-screen bg-raisinBlack-700 hover:bg-green-600 flex justify-between items-center"
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("div", null, null, -1)),
        _createElementVNode("div", _hoisted_9, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "font-semibold text-2xl" }, "Total: ", -1)),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.workDocumentTotal.toFixed(2)) + " €", 1)
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "ml-5 text-green-500 w-16" }, [
          _createElementVNode("svg", {
            width: "24",
            height: "24",
            viewBox: "0 0 24 24",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M8 4H16V6H8V4ZM18 6H22V18H18V22H6V18H2V6H6V2H18V6ZM20 16H18V14H6V16H4V8H20V16ZM8 16H16V20H8V16ZM8 10H6V12H8V10Z",
              fill: "currentColor"
            })
          ])
        ], -1))
      ])
    ])
  ]))
}