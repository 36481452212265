import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white grid grid-flow-row" }
const _hoisted_2 = { class: "grid grid-flow-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      class: "flex justify-center bg-gray-300 border-b-2 rounded-t border-gray-900"
    }, [
      _createVNode(_component_ChevronDownIcon, { class: "h-4" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setEditMode && _ctx.setEditMode(...args))),
        class: "p-2 lg:p-4 bg-green-500 text-white hover:bg-green-600"
      }, "Editar página"),
      (((_ctx.isTableSelected && _ctx.workDocumentItemsCount >0 ) && (_ctx.activeView !== 'splitByPeopleView' && _ctx.activeView !== 'splitBillView')))
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openView('SplitBill'))),
            class: "p-2 lg:p-4 bg-orange-500 text-white hover:bg-orange-600"
          }, "Dividir conta"))
        : _createCommentVNode("", true),
      ((_ctx.isTableSelected && _ctx.workDocumentItemsCount >0 ))
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (this.moveTable && this.moveTable(...args))),
            class: "p-2 lg:p-4 bg-green-500 text-white hover:bg-green-600"
          }, "Transferir mesa"))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.addNewFamiliesToPos && _ctx.addNewFamiliesToPos(...args))),
        class: "p-2 lg:p-4 bg-green-500 text-white hover:bg-green-600"
      }, "Atualizar itens"),
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openModal('ModalClientSearch'))),
        class: "p-2 lg:p-4 bg-blue-500 text-white hover:bg-blue-600"
      }, "Selecionar Clientes"),
      _createElementVNode("button", {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openModal('ModalPriceLine'))),
        class: "p-2 lg:p-4 bg-blue-500 text-white hover:bg-blue-600"
      }, "Selecionar Linha de Preço"),
      _createElementVNode("button", {
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openModal('ModalDocumentType'))),
        class: "p-2 lg:p-4 bg-blue-500 text-white hover:bg-blue-600"
      }, "Alterar tipo de documento"),
      _createElementVNode("button", {
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openModal('ModalDocumentSerie'))),
        class: "p-2 lg:p-4 bg-blue-500 text-white hover:bg-blue-600"
      }, "Alterar série de documento"),
      _createElementVNode("button", {
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openView('Delivery'))),
        class: "p-2 lg:p-4 bg-indigo-500 text-white hover:bg-indigo-600"
      }, "Abrir delivery")
    ])
  ]))
}