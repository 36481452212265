import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 lg:w-2/3 rounded shadow p-8 m-2 max-h-screen" }
const _hoisted_2 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectList = _resolveComponent("SelectList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"text-black font-semibold text-xl mb-5\"> Pagamento </div><div class=\"grid grid-cols-5 grid-flow-row\"><div class=\"col-span-2 py-2 px-1 hover:bg-gray-200\"><p class=\"font-light\">Fatura simplificada</p></div><div class=\"col-span-3 text-right py-2 px-1 hover:bg-gray-200\"><p><span class=\"font-semibold\">000000000</span> - <span class=\"font-light\">João Carlos Silva Cunha</span></p></div></div>", 2)),
    _createVNode(_component_SelectList, {
      List: _ctx.paymentMethods,
      label: 'meio_pagamento'
    }, null, 8, ["List"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeActiveModal && _ctx.closeActiveModal(...args))),
        class: "w-full h-16 bg-green-400 hover:bg-green-500 rounded text-white"
      }, "OK ")
    ])
  ]))
}