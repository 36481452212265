import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 flex flex-col rounded shadow p-4 lg:p-8 w-4/5 xl:w-3/5 2xl:w-2/5 h-60 max-h-screen relative" }
const _hoisted_2 = { class: "flex flex-col h-full w-full" }
const _hoisted_3 = { class: "flex items-center mb-1" }
const _hoisted_4 = { class: "flex flex-col justify-between h-full" }
const _hoisted_5 = { class: "flex flex-row w-full h-1/3 mt-5" }
const _hoisted_6 = { class: "flex justify-center w-full h-1/3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_KeyboardInput = _resolveComponent("KeyboardInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-black font-semibold text-xl flex-1" }, " Enviar fatura por Email ", -1)),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeActiveModal && _ctx.closeActiveModal(...args))),
          class: "bg-red-400 hover:bg-red-500 rounded text-white h-full",
          style: {"aspect-ratio":"1 / 1"}
        }, [
          _createVNode(_component_XIcon)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_KeyboardInput, {
            onEnterPressed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.prepareParamsEmail())),
            class: "h-full",
            placeholder: 'Introduza Email...',
            modelValue: _ctx.getEmail,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getEmail) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.prepareParamsEmail())),
            class: "bg-green-600 hover:bg-green-500 text-white px-4 py-2 w-full"
          }, " Enviar ")
        ])
      ])
    ])
  ]))
}