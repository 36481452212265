import POSnotyf from "@/libs/POSnotyf";
import {IItemsFeatures} from "@/interfaces/IItemsFeatures";

const fnFeaturesItems = {

    actions: {
        async fnFeatures(state: any) {
            // get item selected
            let item = state.getters.getWorkDocumentSelectedItem

            let featuresType: IItemsFeatures[] = []
            //if selected continue
            if (state.getters.getWorkDocumentSelectedItem.id !== undefined) {
              const feature = await state.dispatch("fetchFeatures", item.id)

                // if have features get the type
                if (feature.length >0 ) {
                    for (const feat of feature) {

                        const itemFeature = await state.dispatch("fetchTypeFeatures", {item: item.id, feature: feat.id_tipo_caracteristica})
                        for (const feature of itemFeature) {
                            featuresType.push({
                                featureValue: feature.valor,
                                idFeatureType: feat.id_tipo_caracteristica,
                                featureType: feat.tipo_caracteristica,
                                idFeatureItem: feature.id_caracteristica_artigo,

                            });

                        }
                    }

                    state.commit("setFeaturesType", featuresType)
                    state.dispatch('openModal', 'ModalFeatures')
                    return

                }
                else{

                    POSnotyf.error("Artigo sem comentários!");
                }

            } else {
                POSnotyf.error("Deve ter o artigo selecionado!");
            }

        },
        async fetchTypeFeatures(state: any, param:{item: number, feature: number}) {

            const response = await fetch(`${state.getters.apiURL}/valorestiposcaracteristicasartigo/?p=${param.feature}&artigo=${param.item}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            const data = await response.json()

            return data

        },
        async fetchFeatures(state: any, item: number) {

            const response = await fetch(`${state.getters.apiURL}/tiposcaracteristicasartigo/?p=${item}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })

            const data = await response.json()

            return data

        },
    }
}
export {fnFeaturesItems}