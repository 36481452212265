import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-gray-100 flex flex-col gap-2 lg:w-1/3 rounded shadow p-8 max-h-screen" }
const _hoisted_2 = { class: "flex justify-between items-center text-black font-semibold text-xl select-none" }
const _hoisted_3 = { class: "flex justify-end" }
const _hoisted_4 = { class: "mt-4 flex justify-center gap-2" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 0,
  class: "flex justify-center items-center animate-spin"
}
const _hoisted_7 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-6 w-6",
  style: {"transform":"scaleX(-1)"},
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor",
  "stroke-width": "2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_NumericKeyboard = _resolveComponent("NumericKeyboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Alterar funcionário", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeActiveModal && _ctx.closeActiveModal(...args))),
          class: "bg-red-400 hover:bg-red-500 rounded text-white h-10 w-10"
        }, [
          _createVNode(_component_XIcon)
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pin) = $event)),
          type: "password",
          placeholder: "PIN",
          class: "appearance-none w-full text-center text-lg p-2 border"
        }, null, 512), [
          [_vModelText, _ctx.pin]
        ]),
        _createVNode(_component_NumericKeyboard, {
          class: "mt-1",
          onClicked: _ctx.numpadClicked
        }, null, 8, ["onClicked"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.login())),
          disabled: !_ctx.pin || _ctx.loading,
          class: _normalizeClass([_ctx.pin && !_ctx.loading ? 'bg-green-400 hover:bg-green-500 cursor-pointer' : 'bg-gray-400 cursor-default', "w-1/2 h-16 rounded text-white"])
        }, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(), _createElementBlock("svg", _hoisted_7, _cache[4] || (_cache[4] = [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  }, null, -1)
                ])))
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" OK ")
              ], 64))
        ], 10, _hoisted_5)
      ])
    ])
  ]))
}