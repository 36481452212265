import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 lg:w-1/3 rounded shadow p-8 max-h-screen" }
const _hoisted_2 = { class: "flex justify-between items-center text-black font-semibold text-xl select-none mb-5" }
const _hoisted_3 = { class: "flex justify-end" }
const _hoisted_4 = { class: "flex justify-items-stretch" }
const _hoisted_5 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("p", null, "Observações da linha", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "bg-red-400 text-center hover:bg-red-500 rounded text-white h-10 w-10"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            class: "h-full w-full",
            fill: "none",
            viewBox: "0 0 24 24",
            stroke: "currentColor",
            "stroke-width": "2"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M6 18L18 6M6 6l12 12"
            })
          ], -1)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("textarea", {
        ref: "textarea",
        class: "flex-1 shadow rounded p-2",
        rows: "7",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemObservations) = $event))
      }, null, 512), [
        [_vModelText, _ctx.itemObservations]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
        class: "w-full h-16 bg-green-400 hover:bg-green-500 rounded text-white"
      }, "Guardar")
    ])
  ]))
}