
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import DocumentClientLabel from "@/components/DocumentClientLabel.vue";
import DocumentNifLabel from "@/components/DocumentNifLabel.vue";
import {UserIcon} from "@heroicons/vue/outline";


const getTime = function () {
  const date = new Date();
  let hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  let minutes = (date.getMinutes() < 10) ? `0${date.getMinutes()}` : date.getMinutes();
  return `${hour}:${minutes}`
}

export default defineComponent({
  name: 'OperationsSectionHeader',
  components: {
    DocumentClientLabel,
    DocumentNifLabel,
    UserIcon,
  },
  props: [],
  data() {
    return {
      time: getTime()
    }
  },
  computed: {
    ...mapGetters(['savedTableZone','getRemainingSplittedItems', 'workDocumentItemRaw', 'isTableSelected', 'currentPage', 'userData', 'appVersion', "currentTable", 'editMode', 'getSelectedCustomerData']),
  },
  methods: {
    ...mapMutations(['currentTablesZone','saveTableZone','clearEditModeButtonData', 'setCurrentPage', 'editModeButtonData', 'clearWorkDocumentSelectedRow']),
    ...mapActions(['saveTableItems', 'fnOpenView', "fnOpenModal", 'toggleEditMode', 'fnSelectCustomer']),
    headerButton() {
      if (this.editMode) {
        this.clearEditModeButtonData()
        this.toggleEditMode()
      } else {
        this.goToInitialPage()
      }
      this.clearWorkDocumentSelectedRow();
    },
    goToInitialPage() {
      this.fnOpenView('Items')
      this.setCurrentPage(0)
    },
    timeCycle: function () {
      setInterval(() => {
        this.time = getTime()
      }, 60000);
    },
    openTableSelectionView() {
      // check if the table items are different from the work document
      if (this.isTableSelected && !(this.currentTable.artigos_json === this.workDocumentItemRaw) && this.getRemainingSplittedItems.length === 0) {
        //when get out of the table without save,
        // save automatically and go to table zone

        this.saveTableZone(this.savedTableZone)
        this.saveTableItems()
      }

      this.saveTableZone(this.savedTableZone)

      // open table selection view
      this.fnOpenView('TableSelection')
    },
  },
  mounted() {
    this.timeCycle()
  }
})
