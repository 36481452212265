import {SetTableStatusParams} from "@/interfaces/SetTableStatusParams";
import {Authentication} from "@/store/modules/Authentication";
import POSnotyf from "@/libs/POSnotyf";
import TableSelectionView from "@/components/TableSelectionView.vue";
import {fnPages} from "@/store/modules/POSFunctions/fnPages";

const TablesManagement = {
    state: {
        tables: [] as any,
        tablesZones: [] as any,
        currentTablesZone: 0 as number,
        currentTable: null,
        savedTableZone: 0 as number,
    },
    getters: {
        tables(state: any, getters: any): any {
            let processedTables = []

            for (let table of state.tables) {

                if(typeof table.artigos_json=== 'undefined'){
                    return processedTables
                    console.log('table.artigos_json is undefined', table.artigos_json)
                }
                // convert table items string to JSON
                let tableItems = JSON.parse(table.artigos_json)

                // initialize table totals object
                let tableTotals = {
                    items: 0,
                    value: 0,
                }

                // process table items total
                if (tableItems.length > 0) {
                    let minimumTimestamp = 9999999999
                    for (let tableItem of tableItems) {
                        tableTotals.items += tableItem.quantity
                        tableTotals.value += (tableItem.quantity * parseFloat(parseFloat(tableItem.price).toFixed(getters.goldylocksConfig.casas_decimais)))

                        if (tableItem.requestTimestamp && (tableItem.requestTimestamp < minimumTimestamp)) {
                            minimumTimestamp = tableItem.requestTimestamp
                        }
                    }

                    // store initial request timestamp
                    if (minimumTimestamp < 9999999999 && (table.initialRequestTime === null || typeof table.initialRequestTime === 'undefined')) {
                        const date = new Date(minimumTimestamp * 1000);


                        const hours = date.getHours().toString().padStart(2, '0');
                        const minutes = date.getMinutes().toString().padStart(2, '0');

                        table.initialRequestTime = `${hours}:${minutes}`;
                    }
                } else {
                    if (typeof table.initialRequestTime === 'undefined') {
                        table.initialRequestTime = table.initialRequestTimestamp = null
                    }
                }


                // round table totals
                tableTotals.value = parseFloat(parseFloat(tableTotals.value.toString()).toFixed(2))

                // append totals property to processed table information
                table.totals = tableTotals

                // add processed table data to array
                processedTables.push(table)
            }

            // return processed table array
            return processedTables
        },
        tablesZones(state: any): any {
            return state.tablesZones
        },
        getCurrentTablesZone(state: any) {
            return state.currentTablesZone
        },
        activeTableZoneID(state: any) {
            return state.currentTablesZone
        },
        currentTable(state: any, getters: any) {
            if (state.currentTable) {
                return state.currentTable
            } else {
                return {
                    id_mesa: 0,
                    descricao: 'Sem mesa',
                }
            }
        },
        isTableSelected(state: any, getters: any) {
            return state.currentTable !== null
        },
        savedTableZone(state: any, getters: any) {
            return state.savedTableZone
        }
    },
    mutations: {
        tables(state: any, tables: any) {
            state.tables = tables
        },
        currentTable(state: any, tableData: any) {
            state.currentTable = tableData
        },
        tablesZones(state: any, tablesZones: any) {
            state.tablesZones = tablesZones
        },
        currentTablesZone(state: any, zone: number) {
            state.currentTablesZone = zone
        },
        saveTableZone(state: any) {
            state.savedTableZone = state.currentTablesZone
        },
    },
    actions: {
        fetchTablesZones(state: any) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}zonasmesas`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(tablesZones => {
                        state.commit('tablesZones', tablesZones)
                        resolve(tablesZones)
                    })
                    .catch(err => reject(err))
            })
        },
        fetchTables(state: any) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}mesas`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(tables => {
                        state.commit('tables', tables)
                        resolve(tables)
                    })
                    .catch(err => reject(err))
            })
        },
        fetchTableStatus(state: any, tableID: number) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}estadomesas/${tableID}`, {
                    method: 'GET',
                    headers: {
                        Authentication: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(tableStatus => resolve(tableStatus))
                    .catch(err => reject(err))
            })
        },
        fetchZoneTablesStatus(state: any, zoneID: number) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.api2URL}estadomesas/zona/${zoneID}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                })
                    .then(res => res.json())
                    .then(zoneTablesStatus => {
                        state.commit('tables', zoneTablesStatus)
                        resolve(zoneTablesStatus)
                    })
                    .catch(err => reject(err))
            })
        },
        setTableStatus(state: any, tableStatus: SetTableStatusParams) {
            return new Promise<void>((resolve, reject) => {
                fetch(`${state.getters.api2URL}estadomesas`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: JSON.stringify(tableStatus),
                })
                    .then(res => {
                        if (res.status === 200) {
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        clearTableStatus(state: any, tableID: number) {
            return new Promise<void>((resolve, reject) => {
                fetch(`${state.getters.api2URL}estadomesas/${tableID}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => {
                        (res.status === 200) ? resolve() : reject()
                    })
                    .catch(err => reject(err))
            })
        },
        // call the tables to view, after close table gets the zoneID to go
        activateFirstTablesZone(state: any, zoneID:number=0) {

            // if have a zoneID get to the same zone
            if (zoneID > 0) {
                //find the zone from the table close
                for(let i=0;i<=state.getters.tablesZones.length-1;i++) {

                    if(state.getters.tablesZones[i].id_zona === zoneID){
                        state.commit('currentTablesZone', state.getters.tablesZones[i].id_zona)
                        state.dispatch('fetchZoneTablesStatus', state.getters.tablesZones[i].id_zona)
                    }
                }
            }
            else{
                //on open the pos get to the first zone
                state.commit('currentTablesZone', state.getters.tablesZones[0].id_zona)
                state.dispatch('fetchZoneTablesStatus', state.getters.tablesZones[0].id_zona)
            }
        },

        async saveTableItems(state: any, keepActiveTable: boolean = false) {
            if (state.getters.currentTable.id_mesa > 0 && state.getters.workDocumentHasItems) {

                // send workdocument items to API
                await state.dispatch('setTableStatus', {
                    id_mesa: state.getters.currentTable.id_mesa,
                    artigos_json: state.getters.workDocumentItemRaw,
                })

                // clear work document items
                if (!keepActiveTable) {
                    state.commit('clearItems')
                }



                POSnotyf.success(`Mesa guardada com sucesso!`)

            } else if (state.getters.currentTable.id_mesa) {
                await state.dispatch('clearTableStatus', state.getters.currentTable.id_mesa)
            }

            if (state.getters.currentTable.id_mesa > 0) {
                // set default table clear current select table
                if (!keepActiveTable) {
                    state.commit('currentTable', null)
                }

            }
        }
    },
}

export {
    TablesManagement
}