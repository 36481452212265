
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import {XIcon} from "@heroicons/vue/outline";

export default defineComponent({
  name: "ModalTableSelect",
  data() {
    return {
      componentName: "ModalTableSelect"
    }
  },
  components: {
    XIcon
  },
  props: [],
  computed: {},
  methods: {
    ...mapActions(['closeActiveModal'])
  },
  watch: {},
  mounted() {
  }
})
