
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {CreditCardIcon, CashIcon, LibraryIcon, UserIcon, CalculatorIcon} from "@heroicons/vue/outline";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";
import NumericKeyboardKey from "@/components/Keyboards/NumericKeyboardKey.vue";

export default defineComponent({
  name: 'PaymentMethodSelectionView',
  components: {
    NumericKeyboardKey,
    NumericKeyboard,
    CreditCardIcon,
    CashIcon,
    LibraryIcon,
    UserIcon,
    CalculatorIcon
  },
  data() {
    return {
      paymentMethodSelected: {},
      showChangeScreen: false,
      moneyGivenString: '0',
    }
  },
  computed: {
    ...mapGetters(['paymentMethods', 'getSelectedCustomerData', 'currentTable', 'workDocumentTotal']),
    moneyGiven(): number {
      let value = parseFloat(this.moneyGivenString)

      if (isNaN(value)) {
        return 0.00
      } else {
        return value
      }
    },
    changeValue(): number {
      return this.workDocumentTotal - this.moneyGiven
    },
    changeValueString(): string {
      return parseFloat(Math.abs(this.workDocumentTotal - this.moneyGiven).toString()).toFixed(2)
    }
  },
  methods: {
    ...mapActions(['fnOpenModal','fnOpenView', 'setInvoiceDocumentPaymentMethod', 'fnSelectCustomer', 'terminateInvoice', 'showStatusMessage', 'closeStatusMessage', 'fnSelectCustomerVAT']),
    ...mapMutations(['invoicePaymentMethodId', 'setInvoiceViewState']),

    getTransport(){
      this.fnOpenModal('ModalPlate')
    },
    clearValues() {
      this.moneyGivenString = '0'
      this.showChangeScreen = false
    },
    toggleChangeView() {
      this.showChangeScreen = !this.showChangeScreen
    },
    appendMoneyGiven(number: string) {
      if (number !== '<') {
        this.moneyGivenString = this.moneyGivenString + number
      } else if (this.moneyGivenString.length > 0) {
        let removeCount = 1

        if (this.moneyGivenString[this.moneyGivenString.length - 1] === '.')
          removeCount = 2

        this.moneyGivenString = this.moneyGivenString.substr(0, this.moneyGivenString.length - removeCount)
      }
    },
    async selectMethod(paymentMethod: any) {
      if (paymentMethod.tipo_meio_pagamento === 'NU') {
        this.showChangeScreen = true
        this.paymentMethodSelected = paymentMethod
      } else {
        await this.savePaymentMethod(paymentMethod)
      }
    },
    async savePaymentMethod(paymentMethod: any) {
      this.showStatusMessage('A terminar documento...')

      // store selected payment method
      this.invoicePaymentMethodId(paymentMethod.meio_pagamento);

      // set invoice payment method
      try {
        if (paymentMethod.tipo_meio_pagamento === 'NU') {
          await this.setInvoiceDocumentPaymentMethod(parseFloat(this.moneyGivenString).toFixed(2))
        } else {
          await this.setInvoiceDocumentPaymentMethod()
        }
      } catch (err) {
        console.error(`cant_set_payment_method_${err}`)
      }

      // IMPORTANT
      try {
        // terminate and close invoice
        await this.terminateInvoice()

        this.setInvoiceViewState(false)

        // close status message
        await this.closeStatusMessage()

        // open printer selection screen
        await this.fnOpenView('PrinterSelection')

      } catch (err) {
        console.error(`cant_terminate_invoice_${err}`)
      }
    },
    icon(paymentMethod: any) {
      let type = paymentMethod.tipo_meio_pagamento

      if (type === 'MB') {
        return 'CreditCardIcon'
      } else if (type === 'CH') {
        return 'LibraryIcon'
      } else {
        return 'CashIcon'
      }
    }
  },
  mounted() {
    this.clearValues()
  },
  unmounted() {
    this.setInvoiceViewState(false)
  }
})
