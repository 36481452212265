import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-raisinBlack-200 text-white h-screen p-8" }
const _hoisted_2 = { class: "p-7 flex flex-row justify-center items-center gap-1" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "bg-gray-600 text-white rounded-md text-right px-2 flex flex-wrap justify-between"
}
const _hoisted_6 = { class: "flex items-center gap-1 text-xs" }
const _hoisted_7 = { class: "text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.getMoveTable
          ? 'font-semibold text-base sm:text-4xl text-center text-orange-600'
          : 'font-semibold text-base sm:text-2xl text-center')
    }, _toDisplayString(_ctx.getMoveTable ? "Transferir Mesa" : "Seleção de Mesa"), 3),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tablesZones, (tablesZone) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass([_ctx.activeTableZoneID===tablesZone.id_zona ? 'bg-blue-600 hover:bg-blue-700' : 'bg-raisinBlack-100 hover:bg-raisinBlack-300', "py-4 px-6 rounded-xl shadow cursor-pointer uppercase"]),
          onClick: ($event: any) => (_ctx.setTablesZone(tablesZone.id_zona))
        }, _toDisplayString(tablesZone.descricao), 11, _hoisted_3))
      }), 256))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["grid grid-cols-3 sm:grid-cols-6 max-h-80 gap-3 sm:gap-2 lg:h-5/6", {'hidden': _ctx.status}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tables, (table) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass([[{'bg-orange-500 hover:bg-orange-600':_ctx.busyTable(table),'bg-raisinBlack-600 hover:bg-gray-700':!_ctx.busyTable(table)}], "p-3 text-sm rounded-xl shadow cursor-pointer uppercase font-semibold selection-none flex flex-col justify-between h-16 sm:h-24"]),
          onClick: ($event: any) => (_ctx.selectTable(table))
        }, [
          _createTextVNode(_toDisplayString(table.descricao) + " ", 1),
          (table.totals.value > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-2 h-2 rounded-full bg-green-500" }, null, -1)),
                  _createTextVNode(_toDisplayString(table.initialRequestTime), 1)
                ]),
                _createElementVNode("div", _hoisted_7, "€ " + _toDisplayString(table.totals.value), 1)
              ]))
            : _createCommentVNode("", true)
        ], 10, _hoisted_4))
      }), 256))
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["bg-white h-4/6 lg:h-5/6", {'hidden': !_ctx.status}])
    }, null, 2)
  ]))
}