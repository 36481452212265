import POSnotyf from "@/libs/POSnotyf";
import {IItemsFeatures} from "@/interfaces/IItemsFeatures";
import {error} from "@capacitor/assets/dist/util/log";

const Items = {
    state: {
        items: [],
        itemPrices: [],
        priceLines: [],
        activePriceLine: null,
        activePriceLineDescription: null,
        itemsSearchString: '' as string,
        // Used on button "comentarios" and modalFeatures
        typeFeatures: [],
    },
    getters: {
        items(state: any): any {
            return state.items
        },
        hasItems(state: any): boolean {
            return state.items.length > 0
        },
        itemsCount(state: any, getters: any): number {
            return getters.items.length
        },
        itemPrices(state: any): any {
            return state.itemPrices
        },
        activePriceLine(state: any): any {
            return state.activePriceLine
        },
        isDefaultPriceLine(state: any): boolean {
            return (state.activePriceLine === null)
        },
        priceLines(state: any): any {
            return state.priceLines
        },
        activePriceLineDescription(state: any): any {
            return state.activePriceLineDescription
        },
        getItemSearchString(state: any): string {
            return state.itemsSearchString
        },
        getSearchItems(state: any): any {
            return state.items
                .filter(item => item.resumo.toLowerCase().includes(state.itemsSearchString.toLowerCase()))
                .sort((a, b) => a.nome.localeCompare(b.nome));
        },
        getFeaturesType(state: any): any {
            return state.typeFeatures
        },
    },
    mutations: {
        items(state: any, items: any) {
            state.items = items
        },
        itemPrices(state: any, itemPrices: any) {
            state.itemPrices = itemPrices
        },
        activePriceLine(state: any, activePriceLine: any) {
            state.activePriceLine = (activePriceLine === 0) ? null : activePriceLine

            if (activePriceLine > 0) {
                // search for price line description
                for (let priceLine of state.priceLines) {
                    if (priceLine['linha'] == activePriceLine) {
                        state.activePriceLineDescription = priceLine['descricao']
                    }
                }
            } else {
                // enable default price line
                state.activePriceLine = null
                state.activePriceLineDescription = null
            }
        },
        activateDefaultPriceLine(state: any) {
            state.activePriceLine = null
            state.activePriceLineDescription = null
        },
        priceLines(state: any, priceLines: any) {
            state.priceLines = priceLines
        },
        setItemsSearchString(state: any, itemsSearchString: string) {
            state.itemsSearchString = itemsSearchString
        },
        setFeaturesType(state: any, data: IItemsFeatures[]) {
            state.typeFeatures = data
        },
        clearFeaturesType(state: any) {
            state.typeFeatures = []
        },
    },
    actions: {
        fetchItems(state: any): any {
            return new Promise<any>(async (resolve, reject) => {
            try{
                // get items 1000 each time
                let lastFetchedItems = []
                //all items
                let items = []
                /*get items 1000 a time because of php memory*/
                do {
                    let response = await fetch(`${state.getters.apiURL}artigos/?temimagem=1&activos=1&l=1000&offset=${items.length}`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`
                        }
                    })
                    lastFetchedItems = await response.json()

                    // Check if response is not ok (status outside 200-299 range)
                    if (!response.ok) {
                        throw new Error(`Erro ao carregar artigos!`)
                    }

                    // merge items
                    items = [...items, ...lastFetchedItems]
                } while (lastFetchedItems.length > 0)


                // parse items additional barcodes
                items = items.map((item: any) => {

                    // split each barcode
                    if (item.codigos_barras_adicionais) {
                        const itemAdditionalBarcodesSplited = item.codigos_barras_adicionais.split('|')

                        item.codigos_barras_adicionais = itemAdditionalBarcodesSplited.map((rawAdditionalBarcode: any) => {

                            // split barcode data as code and quantity on an associative array
                            const barcodeDetailsSplited = rawAdditionalBarcode.split(';')
                            if (parseInt(barcodeDetailsSplited[1]) !== 0) {

                                // return the JSON object to the item properties
                                return {
                                    'barcode': barcodeDetailsSplited[0],
                                    'quantity': barcodeDetailsSplited[1]
                                }
                            }
                        })
                    }

                    return item
                })

                // store fetched items
                state.commit('items', items)
                resolve(items)

            }catch (error) {
                console.error('Erro ao carregar artigos!:', error)
                reject(error)
            }

            })
        },
        getFamilyItems(state: any, familyId: number): any {
            return new Promise<any>((resolve) => {
                resolve(state.getters.items.filter((item: any) => parseInt(item.id_familia) === familyId))
            })
        },
        getItemById(state: any, itemId: string) {
            return new Promise<any>((resolve) => {
                resolve(state.getters.items.find((item: any) => {

                    // search fist by the item main id
                    if (item.cod_barras === itemId) {
                        return true
                    } else {

                        if (item.codigos_barras_adicionais === null)
                            return false

                        let barcodeFound = false

                        for (let itemExtraBarcode of item.codigos_barras_adicionais) {
                            if (itemExtraBarcode.barcode === itemId) {

                                // check if the barcode has quantity set
                                if (parseFloat(itemExtraBarcode.quantity) != 1)
                                    state.commit('setAddItemQuantity', parseFloat(itemExtraBarcode.quantity))

                                // found the barcode
                                barcodeFound = true
                            }
                        }

                        return barcodeFound
                    }

                }))
            })
        },
        fetchAssociatedItems: async (state: any, parentItemID: string) => {
            try {
                /* fetch associated items from the API */
                const response = await fetch(`${state.getters.apiURL}artigosassociados/?id_artigo=${parentItemID}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })

                /* check response */
                if (response.ok) {
                    /* return JSON associated items */
                    return await response.json()
                } else {
                    return false
                }
            } catch (e) {
                console.error(e.message)
            }
        },
        fetchItemPrices: async (state: any, itemId: string) => {
            try {
                const response = await fetch(`${state.getters.apiURL}precos/?p=${itemId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })

                if (response.ok) {
                    // save item prices to state
                    state.commit('itemPrices', await response.json())
                } else {
                    return false
                }
            } catch (e) {
                console.error(e.message)
            }
        },
        fetchPriceLines: async (state: any) => {
            try {
                const response = await fetch(`${state.getters.apiURL}linhaspreco`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })

                if (response.ok) {
                    state.commit('priceLines', await response.json())
                }
            } catch (e) {
                POSnotyf.error(`Não foi possivel obter as linhas de preço!<br>Contate o nosso suporte técnico.`)
                console.error(e.message)
            }
        }
    }
}

export {Items}