import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "flex flex-col items-start justify-center px-4 sm:py-8"
}
const _hoisted_4 = { class: "grid grid-rows-2 w-2/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-row select-none h-full max-h-full", _ctx.listClass])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["grid grid-cols-1 flex-1", _ctx.compressedList.length ? 'grid-rows-' + _ctx.maximumGridRows() : 'grid-rows-1'])
    }, [
      (_ctx.compressedList.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.compressedList, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["bg-white text-black px-4 sm:py-8 hover:bg-gray-50 cursor-pointer flex items-center font-thin", [{'active' : _ctx.selectedIndex === index}, _ctx.itemClass]]),
              key: index,
              onClick: ($event: any) => (_ctx.clicked(item, index))
            }, [
              _createElementVNode("div", {
                innerHTML: item[_ctx.label]
              }, null, 8, _hoisted_2)
            ], 10, _hoisted_1))
          }), 128))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
            _createElementVNode("p", null, "Sem produtos", -1)
          ])))
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previous && _ctx.previous(...args))),
        class: _normalizeClass(["flex justify-center items-center bg-gray-400 text-white", [_ctx.previousButtonState ? 'hover:bg-gray-500 ' : 'opacity-60 cursor-not-allowed']])
      }, [
        _createVNode(_component_ChevronUpIcon, { class: "w-full" })
      ], 2),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
        class: _normalizeClass(["flex justify-center items-center bg-gray-400 text-white", [_ctx.nextButtonState ? 'hover:bg-gray-500 ' : 'opacity-60 cursor-not-allowed']])
      }, [
        _createVNode(_component_ChevronDownIcon, { class: "w-full" })
      ], 2)
    ])
  ], 2))
}