const fnCustomers = {
    state: {},
    actions: {
        fnSelectCustomer(state: any) {
            state.dispatch('fnOpenModal', 'ModalClientSearch')
        },
        fnSetFinalCustomer(state: any) {
            state.commit('clearSelectedCustomerData')
        },
        fnSelectCustomerVAT: function(state: any){
            state.dispatch('fnOpenModal', 'ModalVATSelection')
        }
    },
}

export {
    fnCustomers
}
