import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-500 w-96 rounded shadow p-8" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_NumericKeyboard = _resolveComponent("NumericKeyboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-white font-semibold text-xl" }, " NIF ", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
        class: "bg-red-400 hover:bg-red-500 rounded text-white h-10 w-10"
      }, [
        _createVNode(_component_XIcon)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeActiveModal && _ctx.closeActiveModal(...args)), ["enter"])),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
        type: "text",
        class: "appearance-none w-full text-center text-lg p-2"
      }, null, 544), [
        [_vModelText, _ctx.value]
      ]),
      _createVNode(_component_NumericKeyboard, {
        class: "mt-1",
        onClicked: _ctx.numpadClicked
      }, null, 8, ["onClicked"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
        class: "w-full h-16 bg-green-400 hover:bg-green-500 rounded text-white"
      }, "OK")
    ])
  ]))
}