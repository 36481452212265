
import {defineComponent, ref, onMounted, Ref} from "vue";
import {mapActions, mapGetters} from "vuex";
import POSnotyf from "@/libs/POSnotyf";

export default defineComponent({
  name: "ModalItemObservations",
  setup() {
    const textarea: any = ref(null)

    // set focus on mounted
    onMounted(() => {
      textarea.value?.focus()
    })

    return {textarea}
  },
  data() {
    return {
      itemObservations: '',
    }
  },
  components: {},
  props: [],
  computed: {
    ...mapGetters(['getWorkDocumentSelectedItem']),
  },
  methods: {
    ...mapActions(['closeActiveModal','changeSelectedRowObservations']),
    save(){
      this.changeSelectedRowObservations(this.itemObservations)
      this.close()
      POSnotyf.success(`Linha alterada com sucesso!`)
    },
    close() {
      this.itemObservations = ''
      this.closeActiveModal()
    }
  },
  watch: {},
  mounted() {
    this.itemObservations = this.getWorkDocumentSelectedItem.observation
  }
})
