import {WorkDocumentItem} from "@/interfaces/WorkDocumentItem"
import POSnotyf from "@/libs/POSnotyf";
import {WorkDocument} from "@/store/modules/WorkDocument";

const MoveTable = {
    state:{
        // state to change title and now what to do on TableSelection view
        moveTable: false as boolean,
        secondTable: [],
        SecondTableData: [],
        originalItemsTable: [],

    },
    mutations:{

        setMoveTable(state: any, value: boolean) {
            state.moveTable = value
        },
        setSecondTable(state: any, newArray: any) {
            state.secondTable = newArray
        },
        setSecondTableData(state: any, newArray: any) {
            state.SecondTableData = newArray
        },
        clearSecondTable(state: any) {
            state.secondTable = []
        },
        clearSecondTableData(state: any) {
            state.SecondTableData = []
        },
        setOriginalItemsTable(state: any, originalItems: WorkDocumentItem[]){
            state.originalItemsTable =  JSON.parse(JSON.stringify(originalItems))

        },

        // functions to increment or decremnt items to second table transfer between tables
        removeLineFromSecondTableItems(state: any, line: WorkDocumentItem) {

            let arr = [];
            for (let item of state.secondTable ) {
                if (item !== line) {
                    arr.push(item)
                }
            }

            state.secondTable = arr
        },
        decrementSecondTableItemQuantity(state: any, attr: { line: WorkDocumentItem, nItems: number }) {
            let arr = []

            for (let item of state.secondTable) {
                if (item === attr.line) {
                    item.quantity = item.quantity - attr.nItems ?? 1
                }

                arr.push(item)
            }

            state.secondTable = arr
        },
        incrementSecondTableItemQuantity(state: any, attr: { line: WorkDocumentItem, nItems: number }) {
            let arr = []
            let itemFound = false

            // check the array for the item
            for (let item of state.secondTable) {
                if (item.id === attr.line.id && item.description === attr.line.description && attr.line.price === item.price) {
                    // if the item exists increments the quantity by 1
                    item.quantity = item.quantity + (attr.nItems ?? 1)
                    itemFound = true
                }

                // add the item to the array to be stored
                arr.push(item)
            }

            // if the item doesn't exist on the split list its created with 1 quantity
            if (!itemFound) {
                let copy = {...attr.line}
                copy.quantity = (attr.nItems ?? 1)
                arr.push(copy)
            }

            state.secondTable = arr
        },
        addLineToSecondTableItems(state: any, line: WorkDocumentItem) {
            if (line) {
                let arr = state.secondTable
                arr.push(line)
                state.secondTable = arr
            }
        },
        clearOriginalItemsTable(state: any) {
            state.originalItemsTable = []
        }

    },
    getters:{

        getMoveTable(state: any) {
            return state.moveTable
        },
        getSecondTable(state: any) {
            return state.secondTable
        },
        getSecondTableData(state: any) {
            return state.SecondTableData
        },
        getOriginalItemsTable(state: any){
            return state.originalItemsTable
        }
    },

    actions:{
        copySecondTableItemsToWorkDocument(state: any) {
            if (state.getters.getSecondTable.length > 0) {
                state.commit('workDocumentItems', state.getters.getSecondTable)
                state.commit('clearSecondTable')
            }
        },
        copyOriginalItemsTableToWorkDocument(state: any){
            if (state.getters.getOriginalItemsTable.length > 0) {
                state.commit('workDocumentItems', state.getters.getOriginalItemsTable)
                state.commit('clearOriginalItemsTable')
            }
        },

    },
}

export {MoveTable}