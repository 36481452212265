const Families = {
    state: {
        families: []
    },
    mutations: {
        families(state: any, families: any) {
            state.families = families
        }
    },
    getters: {
        hasFamilies(state: any): boolean {
            return state.families.length > 0
        },
        families(state: any): any {
            return state.families
        },
    },
    actions: {
        fetchFamilies(state: any) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}/familias`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(families => {
                        state.commit('families', families)
                        resolve(families)
                    })
                    .catch(err => reject(err))
            })
        },
        getChildFamilies(state: any, familyId: number) {
            return new Promise<any>((resolve, reject) => {
                const families = state.getters.families
                let subfamilies = families.filter((family: any) => {
                    if (parseInt(family.familia_pai) === familyId) {
                        return true
                    } else if (family.familia_pai === null && familyId === 0) {
                        return true
                    } else {
                        return false
                    }
                })
                resolve(subfamilies)
            })
        }
    }
}

export {Families}
