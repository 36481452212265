<template>
  <div>
    <div class="absolute h-screen w-screen top-0 left-0 z-40 bg-gray-700 opacity-80"></div>
    <div class="absolute h-screen w-screen top-0 left-0 z-50 grid grid-rows-2">
      <div class="h-full flex flex-col justify-end">
        <div class="flex flex-col items-center justify-center">
          <label for="keyboardInputContent" class="font-semibold text-2xl text-white">{{
              getKeyboardInputLabel
            }}</label>
          <input id="keyboardInputContent" @click="getCursorPos('keyboardInputContent')" type="text" class="p-4 w-80"
                 v-model="keyboardInputContent">
          <button @click="close" class="mt-8 bg-green-400 text-white font-semibold p-4 w-72">OK</button>
        </div>
      </div>
      <div class="flex flex-col justify-end">
        <div :class="keyboardClass"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
  name: "Keyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    }
  },
  data: () => ({
    keyboard: null,
    cursorPos: 0
  }),
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      display: {
        '{bksp}': '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />\n' +
            '</svg>',
        '{tab}': '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />\n' +
            '</svg>',
        '{lock}': 'Maius.',
        '{shift}': 'Simbolos',
        '{space}': 'Espaço',
        '{enter}': '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">\n' +
            '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />\n' +
            '</svg>',
      },
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    });
  },
  computed: {
    ...mapGetters(['isKeyboardOpen', 'getKeyboardInputContent', 'getKeyboardInputLabel']),
    keyboardInputContent: {
      get() {
        return this.getKeyboardInputContent
      },
      set(value) {
        this.setKeyboardInputContent(value)
      }
    }
  },
  methods: {
    ...mapMutations(['setLastKeypress', 'setKeyboardInputContent']),
    ...mapActions(['closeKeyboard']),
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();

      // register last keypress to state
      this.setLastKeypress(button)

      let splitText = [this.getKeyboardInputContent.substring(0,this.cursorPos), this.getKeyboardInputContent.substring(this.cursorPos)]

      let text;

      if (button[0] !== '{') {
        text = splitText[0] + button + splitText[1];
        this.setKeyboardInputContent(text)
        this.cursorPos++;
      } else if (button === '{bksp}') {
        if (this.getKeyboardInputContent) {
          text = splitText[0].slice(0, -1) + splitText[1];
          this.setKeyboardInputContent(text)
          this.cursorPos--;
        }
      } else if (button === '{space}') {
        if (this.getKeyboardInputContent) {
          text = splitText[0] + ' ' + splitText[1];
          this.setKeyboardInputContent(text)
          this.cursorPos++;
        }
      } else if (button === '{enter}') {
        this.closeKeyboard()
      }
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    },
    close() {
      this.setLastKeypress('{enter}')
      this.closeKeyboard()
    },
    getCursorPos(element) {
      element = document.getElementById(element);
      this.cursorPos = element.selectionStart;
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
