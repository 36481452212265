import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-screen overflow-y-scroll w-full bg-raisinBlack-600 flex flex-col gap-6 items-start px-4 py-8" }
const _hoisted_2 = { class: "flex flex-col items-center sm:justify-center sm:flex-row gap-3 w-full" }
const _hoisted_3 = {
  key: 0,
  class: "text-orange-400 font-medium"
}
const _hoisted_4 = { class: "w-full flex flex-col gap-2 sm:gap-4" }
const _hoisted_5 = { class: "flex flex-col items-center sm:flex-row sm:justify-evenly" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "text-gray-300 font-thin uppercase text-xl" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "text-gray-300 font-thin uppercase text-xl" }
const _hoisted_10 = { class: "flex flex-col items-center sm:flex-row sm:justify-evenly" }
const _hoisted_11 = { class: "flex flex-col" }
const _hoisted_12 = { class: "text-gray-300 font-thin uppercase text-4xl" }
const _hoisted_13 = { class: "flex flex-col" }
const _hoisted_14 = { class: "text-gray-300 font-thin uppercase text-4xl" }
const _hoisted_15 = { class: "flex flex-col" }
const _hoisted_16 = { class: "text-green-600 font-medium uppercase text-4xl" }
const _hoisted_17 = { class: "flex flex-col items-center pt-8 sm:pt-16" }
const _hoisted_18 = { class: "flex justify-between w-72 items-end sm:pt-3" }
const _hoisted_19 = { class: "font-thin text-white text-xl uppercase" }
const _hoisted_20 = { class: "text-green-500 text-2xl" }

import {computed, onMounted, ref} from "vue";
import { useStore} from "vuex";


import {defineComponent} from "vue";
import {mapGetters} from "vuex";

const __default__ = defineComponent({
  name: 'CurrentSessionTotalsView',


})

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const store = useStore()
const isFetchingStatus = ref(false)

/* getter for session status data */
const sessionStatus = computed(() => store.getters.getSessionStatus)
const userData = computed(() => store.getters.userData)

/**
 * refresh session status data
 */
const refreshSessionStatus = async () => {
  isFetchingStatus.value = true
  await store.dispatch('fetchCurrentSessionStatus')
  isFetchingStatus.value = false
}


/* start by refresh session status data */
onMounted(async () => {
  await refreshSessionStatus()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-white font-semibold text-2xl" }, "Consulta Sessão", -1)),
      (isFetchingStatus.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " A atualizar informação... "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-sm text-white font-semibold" }, "Utilizador", -1)),
          _createElementVNode("div", _hoisted_7, _toDisplayString(sessionStatus.value.vendedor ? sessionStatus.value.vendedor : userData.value.username), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-sm text-white font-semibold" }, "Abertura", -1)),
          _createElementVNode("div", _hoisted_9, _toDisplayString(sessionStatus.value.datahora), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-sm text-white font-semibold" }, "Total Caixa", -1)),
          _createElementVNode("div", _hoisted_12, _toDisplayString(sessionStatus.value.total_caixa) + " €", 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-sm text-white font-semibold" }, "Total Impostos", -1)),
          _createElementVNode("div", _hoisted_14, _toDisplayString(sessionStatus.value.total_impostos) + " €", 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-sm text-white font-semibold" }, "Total Faturado", -1)),
          _createElementVNode("div", _hoisted_16, _toDisplayString(sessionStatus.value.total_faturado) + " €", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-gray-200 uppercase font-semibold" }, " Métodos de pagamento ", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sessionStatus.value.metodos_pagamento, (method) => {
          return (_openBlock(), _createElementBlock("div", {
            key: method.meio_pagamento
          }, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(method.meio_pagamento), 1),
              _createElementVNode("div", _hoisted_20, _toDisplayString(method.total) + " €", 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})