import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:grid sm:grid-cols-8 h-screen w-screen select-none" }
const _hoisted_2 = {
  key: 0,
  class: "col-span-8 sm:hidden"
}
const _hoisted_3 = { class: "flex-1 col-span-8 sm:col-span-5 md:col-span-6 w-full" }
const _hoisted_4 = { class: "transition ease-in-out duration-500 hidden sm:block sm:col-span-3 md:col-span-2" }
const _hoisted_5 = {
  key: 2,
  class: "col-span-8 sm:hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_ContentView = _resolveComponent("ContentView")!
  const _component_OperationsSection = _resolveComponent("OperationsSection")!
  const _component_MobileOperations = _resolveComponent("MobileOperations")!
  const _component_MobileFooter = _resolveComponent("MobileFooter")!
  const _component_Keyboard = _resolveComponent("Keyboard")!
  const _component_Modals = _resolveComponent("Modals")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isPortrait)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_MobileHeader)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ContentView)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass([[{'sm:hidden':!_ctx.isStatusOpen && !_ctx.lockedOperationsSection}], "hidden sm:flex items-center justify-center secondary-bg-color w-full h-screen z-50"])
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: "images/logo_horizontal.webp",
            alt: "logo",
            class: "h-10 xl:h-16"
          }, null, -1)
        ]), 2),
        (!_ctx.isPortrait)
          ? (_openBlock(), _createBlock(_component_OperationsSection, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isPortrait)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["col-span-8 w-full sm:hidden fixed bottom-0 transition-all transform duration-500 ease-in-out", [_ctx.isOperationsMobileOpen ? 'right-0' : '-right-full']])
          }, [
            _cache[1] || (_cache[1] = _createTextVNode(" teste ")),
            _createVNode(_component_MobileOperations, { onOpenMobileOperations: _ctx.toggleOperationsMobileStatus }, null, 8, ["onOpenMobileOperations"])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.isPortrait)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_MobileFooter, { onOpenMobileOperations: _ctx.toggleOperationsMobileStatus }, null, 8, ["onOpenMobileOperations"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isKeyboardOpen)
      ? (_openBlock(), _createBlock(_component_Keyboard, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isModalVisible)
      ? (_openBlock(), _createBlock(_component_Modals, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}