
import {defineComponent} from "vue";
import {mapActions, mapMutations} from "vuex";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";
import Keyboard from "@/components/Keyboards/Keyboard.vue";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import DetailedItemList from "@/components/Lists/Style/DetailedItemList.vue";

export default defineComponent({
  name: 'ModalItemPrice',
  components: {DetailedItemList, KeyboardInput, Keyboard, NumericKeyboard},
  props: [],
  data() {
    return {
      inputPrice: '',
    }
  },
  methods: {
    ...mapActions(['closeActiveModal', 'setAddItemPrice']),
    numpadClicked(value: string) {
      if (value === '<') {
        // delete last number
        if (this.inputPrice) {
          this.inputPrice = this.inputPrice.slice(0, -1);
        }
      } else {
        this.inputPrice += value
      }
    },
    save() {
      this.setAddItemPrice(parseFloat(this.inputPrice))
      this.closeActiveModal()
    },
    close() {
      this.closeActiveModal()
    }
  },
  mounted() {
  }
})
