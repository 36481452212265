const fnInvoiceCopy = {
    state: {},
    actions: {
        fnInvoiceCopy(state: any) {
            state.dispatch('fnOpenView', 'PrintInvoiceCopy')
        },
    },
}

export {
    fnInvoiceCopy,
}
