import {WorkDocumentItem} from "@/interfaces/WorkDocumentItem";
import {v4} from "uuid";
import {watch} from "vue";
import {swal} from "@/libs/POSSwal";
import {IInsertAssociatedItemsOptions} from "@/interfaces/IInsertAssociatedItemsOptions";
import POSnotyf from "@/libs/POSnotyf";

const fnItems = {
    state: {
        waitingForPriceEvent: false,
        waitingForPriceFn: null,
        currentParentItemUUID: null,
        fnAddItemQuantity: 1,
        fnAddItemPrice: 0,
        fnAddItemDiscount: 0,
        itemWaitingForQuantity: null,
    },
    getters: {
        fnAddItemQuantity: (state: any) => state.fnAddItemQuantity,
        fnAddItemPrice: (state: any) => state.fnAddItemPrice,
        fnAddItemDiscount: (state: any) => state.fnAddItemDiscount,
        isWaitingForPriceEvent: (state: any): boolean => state.waitingForPriceEvent,
        waitingForPriceFn: (state: any): any => state.waitingForPriceFn,
        itemWaitingForQuantity: (state: any): string => state.itemWaitingForQuantity,
        currentParentItemUUID: (state: any): string => state.currentParentItemUUID,
    },
    mutations: {
        setAddItemQuantity(state: any, newItemQuantity: number) {
            state.fnAddItemQuantity = newItemQuantity ? newItemQuantity : 1;
        },
        setAddItemPrice(state: any, newItemPrice: number) {
            state.fnAddItemPrice = newItemPrice
        },
        setAddItemDiscount(state: any, newItemDiscount: number) {
            state.fnAddItemDiscount = newItemDiscount
        },
        resetAddItemValues(state: any) {
            state.fnAddItemQuantity = 1;
            state.fnAddItemPrice = 0;
            state.fnAddItemDiscount = 0;
        },
        setWaitingForPriceEvent(state: any, newWaitingState: boolean) {
            state.waitingForPriceEvent = newWaitingState
        },
        setWaitingForPriceFn(state: any, fn: any) {
            state.waitingForPriceFn = fn
        },
        clearWaitingForPriceFn(state: any) {
            state.waitingForPriceFn = null
        },
        setItemWaitingForQuantity(state: any, itemID: string): void {
            state.itemWaitingForQuantity = itemID
        },
        clearItemWaitingForQuantity(state: any): void {
            state.itemWaitingForQuantity = null
        },
        setCurrentParentItemUUID(state: any, parentItemUUID: string): void {
            state.currentParentItemUUID = parentItemUUID
        },
        clearCurrentParentItemUUID(state: any): void {
            state.currentParentItemUUID = null
        },
    },
    actions: {
        async fnAddItem(state: any, itemID: string) {

            let isAdded = true

            // search item in item database
            await state.dispatch('getItemById', itemID)
                .then(async (itemData: any) => {

                    // item does not exists returns false
                    if (typeof itemData === 'undefined') {
                        isAdded = false
                        return false
                    }


                    /* check if the item need quantity model to show */
                    if (itemData.pede_quantidade == 1 && state.getters.itemWaitingForQuantity === null) {
                        state.commit('setItemWaitingForQuantity', itemID)
                        state.dispatch('fnChangeItemQuantity')

                        /* ends the function since now the quantity modal must continue inserting the item */
                        return
                    } else {
                        /* no need for quantity modal */
                        state.commit('clearItemWaitingForQuantity')
                    }

                    /* stop watcher calling itself if its defined as its no longer necessary */
                    if (state.getters.waitingForPriceFn !== null) {
                        state.dispatch('fnStopQuantityWatcherFn')
                        state.commit('setWaitingForPriceEvent', false)
                    }

                    let linePrice: number
                    let linePriceWithoutTax: number

                    // check if the user has input custom price for the item
                    if ((state.getters.fnAddItemPrice > 0)) {
                        // user defined price
                        if (state.getters.workDocumentTypeData['imposto_incluido'] === "0") {
                            // no tax included
                            linePrice = state.getters.fnAddItemPrice * (1 + (itemData.taxa / 100))
                            linePriceWithoutTax = state.getters.fnAddItemPrice
                        } else {
                            // tax included
                            linePrice = state.getters.fnAddItemPrice
                            linePriceWithoutTax = state.getters.fnAddItemPrice / (1 + (itemData.taxa / 100))
                        }
                    } else if (!state.getters.isDefaultPriceLine) {
                        // non default price line
                        await state.dispatch('fetchItemPrices', itemID)

                        if (state.getters.itemPrices.length > 0) {
                            // search for the current active price line of the item
                            for (let itemPriceLine of state.getters.itemPrices) {
                                if (itemPriceLine['linha'] === state.getters.activePriceLine) {
                                    linePrice = (!isNaN(itemPriceLine['pvpimposto'])) ? ((itemPriceLine['pvpimposto'] * 100) / 100) : 0
                                    linePriceWithoutTax = (!isNaN(itemPriceLine['pvp'])) ? ((itemPriceLine['pvp'] * 100) / 100) : 0
                                }
                            }
                        } else {
                            // item price with the default price line
                            linePrice = (!isNaN(itemData.pvpimposto)) ? ((itemData.pvpimposto * 100) / 100) : 0
                            linePriceWithoutTax = (!isNaN(itemData.pvp)) ? ((itemData.pvp * 100) / 100) : 0
                        }
                    } else {
                        // item price with the default price line
                        linePrice = (!isNaN(itemData.pvpimposto)) ? ((itemData.pvpimposto * 100) / 100) : 0
                        linePriceWithoutTax = (!isNaN(itemData.pvp)) ? ((itemData.pvp * 100) / 100) : 0
                    }

                    // create item structure
                    let itemToAdd = {
                        id: itemData.cod_barras,
                        quantity: state.getters.fnAddItemQuantity,
                        description: itemData.nome,
                        observation: itemData.observacao_documentos,
                        tax: itemData.imposto,
                        tax_rate: itemData.taxa,
                        price: linePrice,
                        price_without_tax: linePriceWithoutTax,
                        discount: state.getters.fnAddItemDiscount,
                        uuid: v4(),
                        hasAssociatedItems: (itemData.tipo_artigo_composto === 'associados'),
                        parentUUID: state.getters.currentParentItemUUID,
                    } as WorkDocumentItem


                    // open price modal if price is 0
                    if (!(state.getters.fnAddItemPrice > 0) && (itemData.pvpimposto <= 0 && itemData.tipo_artigo_composto !== 'associados') && !state.getters.waitingForPriceEvent && state.getters.waitingForPriceFn === null) {

                        /* open price modal */
                        state.dispatch('fnOpenModal', 'ModalItemPrice')

                        /* set price to negative in order to capture changes */
                        state.commit('setAddItemPrice', -1)

                        /* start waiting for price input */
                        state.commit('setWaitingForPriceEvent', true)

                        /* watch for price modification */
                        const watchFn = watch(() => state.getters.fnAddItemPrice, (actual: number, start: number) => {


                            /* check if inserted price is above -1 */
                            if (actual > start) {

                                // calculate user inserted prices taxes
                                if (state.getters.workDocumentTypeData['imposto_incluido'] === "0") {
                                    // no tax included
                                    itemToAdd.price = actual * (1 + (itemData.taxa / 100))
                                    itemToAdd.price_without_tax = actual
                                } else {
                                    // tax included
                                    itemToAdd.price = actual
                                    itemToAdd.price_without_tax = actual / (1 + (itemData.taxa / 100))
                                }

                                // add item to work document
                                state.commit('addItem', itemToAdd)

                                // reset item values to be used
                                state.commit('resetAddItemValues')

                                /* stop waiting for price input */
                                state.commit('setWaitingForPriceEvent', false)

                                /* stop watcher calling itself */
                                state.dispatch('fnStopQuantityWatcherFn')

                                /* add associated items */
                                if (itemData.tipo_artigo_composto === 'associados') {

                                    /* insert into the list associated items */
                                    state.dispatch('insertAssociatedItems', {
                                        parentItemID: itemToAdd.id,
                                        parentQuantity: itemToAdd.quantity,
                                        parentItemUUID: state.currentParentItemUUID,
                                    } as IInsertAssociatedItemsOptions)

                                    /* remove current selected parent UUID*/
                                    state.commit('clearCurrentParentItemUUID')
                                }


                            }
                        })

                        /* store current watch function */
                        state.commit('setWaitingForPriceFn', watchFn)
                    } else {

                        // add item to work document
                        state.commit('addItem', itemToAdd)

                        // reset item values to be used
                        state.commit('resetAddItemValues')

                        /* add associated items */
                        if (itemData.tipo_artigo_composto === 'associados') {
                            state.dispatch('insertAssociatedItems', {
                                parentItemID: itemToAdd.id,
                                parentQuantity: itemToAdd.quantity,
                                parentItemUUID: itemToAdd.uuid,
                            } as IInsertAssociatedItemsOptions)

                            /* remove current selected parent UUID*/
                            state.commit('clearCurrentParentItemUUID')
                        }
                    }
                })

            return isAdded
        },
        fnStopQuantityWatcherFn(state: any) {
            if (state.getters.waitingForPriceFn !== null) {
                state.getters.waitingForPriceFn()
                state.commit('clearWaitingForPriceFn')
            }
        },
        fnChangeItemQuantity(state: any) {
            state.dispatch('openModal', 'ModalItemQuantity')
        },
        fnChangeItemPrice(state: any) {
            state.dispatch('openModal', 'ModalItemPrice')
        },
        fnChangeItemObservations(state: any) {
            if (Object.keys(state.getters.getWorkDocumentSelectedItem).length > 0) {
                state.dispatch('openModal', 'ModalItemObservations')
            } else {
                POSnotyf.warning(`Tem de selecionar um artigo!`)
            }
        },
        fnSearchItems(state: any) {
            state.dispatch('openModal', 'ModalItemSearch')
        },
        async fnClearItems(state: any) {
            if (await swal.confirm('Deseja limpar todos os artigos?')) {
                state.commit('clearItems')
                state.commit('clearWorkDocumentSelectedRow')
            }
        },
        setAddItemQuantity(state: any, newItemQuantity: number) {
            // change existing row quantity
            if (state.getters.getWorkDocumentSelectedItem.id) {
                state.dispatch('changeSelectedRowQuantity', newItemQuantity)
                state.commit('clearWorkDocumentSelectedRow')
            } else {
                state.commit('setAddItemQuantity', newItemQuantity)
            }

        },
        setAddItemPrice(state: any, newItemPrice: number) {
            if (!isNaN(newItemPrice)) {
                // change existing row price
                if (state.getters.getWorkDocumentSelectedItem.id) {
                    state.dispatch('changeSelectedRowPrice', newItemPrice)
                    state.commit('clearWorkDocumentSelectedRow')
                } else {
                    state.commit('setAddItemPrice', newItemPrice)
                }
            } else {
                state.commit('clearWorkDocumentSelectedRow')
            }
        },
        async insertAssociatedItems(state: any, options: IInsertAssociatedItemsOptions): Promise<void> {

            /* fetch and store associated items */
            const associatedItems = await state.dispatch('fetchAssociatedItems', options.parentItemID)

            if (associatedItems) {
                let previousParentItemUUID = state.getters.currentParentItemUUID

                for (let associatedItem of associatedItems) {
                    /* set associated item quantity */
                    state.commit('setAddItemQuantity', associatedItem.quantidade_artigo_associado * options.parentQuantity)

                    /* set current parent UUID */
                    state.commit('setCurrentParentItemUUID', options.parentItemUUID)

                    /* add item */
                    await state.dispatch('fnAddItem', associatedItem.cod_barras)

                }

                /* restore previous UUID */
                state.commit('setCurrentParentItemUUID', previousParentItemUUID)
            }
        }
    }
}

export {
    fnItems
}