import POSnotyf from "@/libs/POSnotyf";

const System = {
    state: {
        mobileResolutionBreakpoint: 650,
        window: {
            height: window.innerHeight,
            width: window.innerWidth,
        },
    },
    getters: {
        isOnline() {
            return navigator.onLine
        },
        windowHeight(state: any) {
            return state.window.height
        },
        windowWidth(state: any) {
            return state.window.width
        },
        mobileResolutionBreakpoint(state: any) {
            return state.mobileResolutionBreakpoint
        },
        isMobileResolution(state: any, getters: any) {
            return getters.windowWidth <= getters.mobileResolutionBreakpoint
        }
    },
    mutations: {
        refreshWindowSize(state: any) {
            state.window.height = window.innerHeight
            state.window.width = window.innerWidth
        }
    },
    actions: {
        clearLocalStorage(): void {
            console.info('clearing_local_storage')
            localStorage.removeItem('userData')
            localStorage.removeItem('goldylocks')
            localStorage.removeItem('paymentMethods')
            localStorage.removeItem('series')
            localStorage.removeItem('type_id')
            localStorage.removeItem('workDocumentItems')
            localStorage.removeItem('documentTypes')
        },
        //send bill by email call on ModalSendEmail
      async sendEmail(state:any, params:any = {}) {




          const formData = new FormData();


          formData.append('assunto', `Envio de documento`);
          formData.append('enderecos[]', params.email);
          formData.append('tipo_documento', params.docType);
          formData.append('id_documento', params.invoiceId);
          formData.append('id_modelo_email', '0');




          try {
              const response = await fetch(`${state.getters.apiURL}/email`, {

                  method: 'POST',
                  headers: {
                      Authorization: `Bearer ${state.getters.token}`,
                  },
                  body: formData,
              })

              if(response.ok){
                  const textResponse  = await response.text()
                  console.log('response_text',textResponse)
                  return (textResponse === 'ok')
              }else{
                  return false
              }
          }catch (e) {
              console.error(e)
          }

        },
    },
}

export {
    System
}
