
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";

export default defineComponent({
  name: 'EditModeDetailsSection',
  components: { KeyboardInput },
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['currentEditModeButtonData']),
    buttonData: {
      set(value: any) {
        this.editModeButtonData(value)
      },
      get(): any {
        return this.currentEditModeButtonData
      }
    }
  },
  methods: {
    ...mapMutations(["editModeButtonData"]),
    ...mapActions(['fnOpenModal']),
  },
  mounted() {
  }
})
