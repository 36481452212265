const OperationsItems = {
    state: {
        currentItemsListIndex: 0,
        maximumVisibleListRows: 0,
    },
    mutations: {
        setMaximumVisibleListRows(state: any, maximumVisibleListRows: number) {
            state.maximumVisibleListRows = maximumVisibleListRows;
        },
        incrementCurrentItemsListIndex(state: any) {
            state.currentItemsListIndex++;
        },
        decrementCurrentItemsListIndex(state: any) {
            state.currentItemsListIndex--;
        },
    },
    getters: {
        getCurrentItemsListIndex(state: any) {
            return state.currentItemsListIndex;
        },
        getMaximumVisibleListRows(state: any) {
            return state.maximumVisibleListRows;
        },
        itemsList: function (state: any, getters: any) {
            let newList = [];
            let i: any;
            for (i = getters.getCurrentItemsListIndex; i < (getters.getCurrentItemsListIndex + getters.getMaximumVisibleListRows); i++) {
                if (getters.workDocumentItems[i]) {
                    newList.push(getters.workDocumentItems[i])
                }
            }

            if (typeof getters.workDocumentItems[state.currentItemsListIndex] === "undefined") {
                state.currentItemsListIndex = 0
            }

            return newList
        },
        itemsListHasMoreItems(state: any, getters: any) {
            return state.currentItemsListIndex + state.maximumVisibleListRows < getters.workDocumentItems.length
        },
    },
    actions: {
        incrementCurrentItemsListIndex(state: any) {
            if ((state.getters.getCurrentItemsListIndex + state.getters.getMaximumVisibleListRows) < state.getters.workDocumentItemsCount) {
                state.commit('incrementCurrentItemsListIndex')
            }
        },
        decrementCurrentItemsListIndex(state: any) {
            if (state.getters.getCurrentItemsListIndex > 0)
                state.commit('decrementCurrentItemsListIndex')
        },
    },
}

export {OperationsItems}
