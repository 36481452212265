
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {SearchIcon, ChevronDownIcon, ChevronUpIcon, XIcon} from "@heroicons/vue/outline";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import ModalClientSearchResultItem from "@/components/Modals/ModalClientSearchResultItem.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import POSnotyf from "@/libs/POSnotyf";

export default defineComponent({
  name: "ModalSendEmail",
  data() {
    return {

      getEmail: '' as string,
      index: 0,
      end: 1,
    }
  },
  components: {
    LoadingBar,
    ModalClientSearchResultItem,
    KeyboardInput,
    XIcon
  },
  computed: {
    ...mapGetters(['getSelectedCustomerData', 'getCustomerDataEmail', 'invoiceDocTypeId', "createdInvoiceId"]),

  },
  methods: {

    ...mapMutations(['setCurrentPage']),
    ...mapActions(['verifySplitItems','closeActiveModal', 'sendEmail', 'fnOpenView', 'closeActiveModal']),

    //prepare the params to function emailSent for clean code
    async prepareParamsEmail() {


        const emailSent = await this.sendEmail({
          email: this.getEmail,
          docType: this.invoiceDocTypeId,
          invoiceId: this.createdInvoiceId
        })

      // emailSent (on system) get a return, then close modal and return to view items
      if (emailSent) {
        this.closeActiveModal()

        if( await this.verifySplitItems()) {
          this.fnOpenView('SplitByPeople')
        }
        else{
          // change to the items view
          this.fnOpenView('Items')
          //first view of items .
          this.setCurrentPage(0)
        }
        POSnotyf.success('Email enviado com sucesso!')
      } else {
        POSnotyf.error('Erro ao enviar email!')
      }
    },


  },
  mounted() {
    this.getEmail = this.getCustomerDataEmail
  },
})
