import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bg-gray-100 lg:w-1/3 rounded shadow p-8 max-h-screen" }
const _hoisted_2 = { class: "flex justify-between items-center text-black font-semibold text-xl select-none mb-5" }
const _hoisted_3 = { class: "flex justify-end" }
const _hoisted_4 = {
  ref: "searchFeatures",
  class: "flex flex-col justify-items-stretch mt-8 h-96 overflow-x-hidden overflow-y-scroll"
}
const _hoisted_5 = { class: "border py-3 px-4 bg-white transition duration-75 hover:bg-gray-200 shadow items-center" }
const _hoisted_6 = { class: "text-sm md:text-xl font-semibold" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = { class: "grid grid-cols-2 gap-2 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex gap-4 items-center" }, [
        _createElementVNode("p", null, "Comentários")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "bg-red-400 text-center hover:bg-red-500 rounded text-white h-10 w-10"
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            class: "h-full w-full",
            fill: "none",
            viewBox: "0 0 24 24",
            stroke: "currentColor",
            "stroke-width": "2"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M6 18L18 6M6 6l12 12"
            })
          ], -1)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFeaturesType, (feature) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "text-sm md:text-xl font-semibold",
          key: feature.id
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  id: "default-checkbox",
                  type: "checkbox",
                  value: feature,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFeatures) = $event)),
                  class: "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                }, null, 8, _hoisted_7), [
                  [_vModelCheckbox, _ctx.selectedFeatures]
                ]),
                _createTextVNode(" " + _toDisplayString(feature.featureValue), 1)
              ])
            ])
          ])
        ]))
      }), 128))
    ], 512),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addFeature(_ctx.selectedFeatures))),
        class: "hidden w-full rounded sm:flex justify-center items-center gap-2 uppercase bg-green-500 hover:bg-green-600 py-6"
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          "stroke-width": "1.5",
          stroke: "currentColor",
          class: "h-6 w-6"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            d: "m4.5 12.75 6 6 9-13.5"
          })
        ], -1),
        _createTextVNode(" Adicionar Selecionados ")
      ])),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.scrollUp && _ctx.scrollUp(...args))),
          class: "bg-white hover:bg-gray-50 border shadow flex justify-center"
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 24 24",
            "stroke-width": "1.5",
            stroke: "currentColor",
            class: "h-12"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "m4.5 18.75 7.5-7.5 7.5 7.5"
            }),
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "m4.5 12.75 7.5-7.5 7.5 7.5"
            })
          ], -1)
        ])),
        _createElementVNode("button", {
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.scrollDown && _ctx.scrollDown(...args))),
          class: "bg-white hover:bg-gray-50 border shadow flex justify-center"
        }, _cache[9] || (_cache[9] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 24 24",
            "stroke-width": "1.5",
            stroke: "currentColor",
            class: "h-12"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
            })
          ], -1)
        ]))
      ])
    ])
  ]))
}