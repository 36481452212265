import {CloseStatusMessageParams} from "@/interfaces/CloseStatusMessageParams";

const StatusView = {
    state: {
        statusMessage: '',
        statusLog: [],
        statusProgress: 0,
    },
    mutations: {
        addStatusLogMessage(state: any, statusLogMessage: string) {
            state.statusLog.push(statusLogMessage)
        },
        clearStatusMessage(state: any) {
            state.statusMessage = '';
        },
        clearStatusLogMessages(state: any) {
            state.statusLog = []
        },
        clearStatusLogProgress(state: any) {
            state.statusProgress = 0;
        },
        setStatusMessage(state: any, message: string) {
            state.statusMessage = message;
        },
        setStatusLogProgress(state: any, progress: number) {
            state.statusProgress = progress
        }

    },
    getters: {
        isStatusOpen(state: any) {
            return state.statusMessage.length > 0
        },
        getStatusMessage(state: any) {
            return state.statusMessage;
        },
        getStatusLogMessages(state: any) {
            return state.statusLog
        },
        getStatusLogProgress(state: any) {
            return state.statusProgress
        },
    },
    actions: {
        showStatusMessage(state: any, message: string) {
            state.commit('setStatusMessage', message)
            state.dispatch('fnOpenView', 'Status')
        },
        addStatusLogMessage(state: any, logMessage: string) {
            state.commit('addStatusLogMessage', logMessage)
        },
        closeStatusMessage(state: any, params: CloseStatusMessageParams = {
            nextView: 'Items'
        }) {
            state.commit('clearStatusMessage')
            state.commit('clearStatusLogMessages')
            state.commit('clearStatusLogProgress')

            // close status and open next view
            /*if (state.getters.activeView === 'Status') {
                // if the view doesnt change the user is logged out
                state.dispatch('fnLogout')
            } else */
            if (params.nextView !== '') {
                state.dispatch('fnOpenView', params.nextView)
            }
        },

    }
}

export {
    StatusView
}
