
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";

export default defineComponent({
  name: 'InsertObservationView',
  components: {KeyboardInput},

  computed: {
    ...mapGetters(['observation']),
    documentObservation: {
      get(): string {
        return this.observation
      },
      set(observation: string) {
        this.setObservation(observation)
      }
    }
  },
  methods: {
    ...mapMutations(['setObservation']),
    ...mapActions(['fnOpenView']),
    close() {
      this.fnOpenView('Items')
    },
    cancel() {
      this.clear()
      this.close()
    },
    clear() {
      this.setObservation('')
    }
  }
})
