


import {reactive, computed, onMounted, defineComponent} from "vue";
import {useStore} from "vuex";
import {LastInvoices} from "@/interfaces/lastInvoices";

export default defineComponent({
  name: "OnlineMovementsView",
  setup() {
    const store = useStore();


    const startDateInputs = reactive({
      startDate: "",
      lastDate: "",
      currentDate: new Date(),
    });

    const initializeDates = () => {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().substr(0, 10);
      startDateInputs.startDate = formattedDate;
      startDateInputs.lastDate = formattedDate;
    };

    initializeDates();

    const documents = reactive<LastInvoices[]>([]);

    const isFetching = computed(() => store.getters.fetchingDayOnlineMovements);
    const goldylocksOptions = computed(() => store.getters.goldylocksConfig);

    const getDocumentRows = async (id: number) => {
      if (id) {
        const rows = await store.dispatch("getDocumentRows", id);
        return rows || [];
      }
      return [];
    };

    const fetchDocuments = async () => {
      const lastInvoices = store.getters.lastInvoices || [];
      const mappedDocuments = await Promise.all(
          lastInvoices.map(async (document: LastInvoices): Promise<LastInvoices> => {
            const rows = await getDocumentRows(document.numero_documento);
            return {
              ...document,
              items: rows,
            };
          })
      );


      documents.push(...(mappedDocuments as LastInvoices[]));
    };

    const fetchInvoices = async () => {
      const body = {
        workDocument: store.getters.workDocumentTypeId,
        startDate: startDateInputs.startDate,
        endDate: startDateInputs.lastDate,
      };
      await store.dispatch("setFetchValues", body);
      await store.dispatch("fetchLastInvoices");
    };

    onMounted(async () => {
      store.commit("clearOnlineDayMovements");
      await store.dispatch("fetchDayMovements");
      await fetchInvoices();
      await fetchDocuments();
    });

    return {
      documents,
      isFetching,
      goldylocksOptions,
      startDateInputs,
    };
  },
});


