const DefaultSettingsData = {
    page: 0,
    pages: {
        reservedPageButtonSlots: 1,
        columns: 5,
        rows: 5,
        columns_mobile: 2,
        rows_mobile: 7,
        settingsPageNumber: 9999999,
        optionsPageNumber: 5555555,
    },
    buttons: {
        defaultFamiliesBackground: `#1F1D2B`,
        defaultFamiliesTextColor: `#ffffff`,
        defaultItemsBackground: `#232432`,
        defaultItemsTextColor: `#ffffff`,
    }
}

export {
    DefaultSettingsData
}
