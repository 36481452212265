import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "h-screen overflow-y-scroll w-full bg-raisinBlack-600 flex flex-col gap-6 items-start px-4 py-8" }
const _hoisted_2 = { class: "flex flex-col sm:flex-row gap-3" }
const _hoisted_3 = {
  key: 0,
  class: "text-orange-400 font-medium"
}
const _hoisted_4 = { class: "grid grid-rows-2 gap-3 w-full" }
const _hoisted_5 = { class: "grid grid-flow-row text-white bg-gray-700" }
const _hoisted_6 = {
  class: "w-full grid grid-cols-6 gap-2 p-2 justify-evenly items-center",
  style: {"grid-template-columns":"auto 1fr 1fr 1fr 1fr 1fr"}
}
const _hoisted_7 = { class: "w-auto justify-center items-center" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "font-semibold" }
const _hoisted_11 = { class: "text-right" }
const _hoisted_12 = { class: "text-right" }
const _hoisted_13 = { class: "text-right" }
const _hoisted_14 = {
  key: 0,
  class: "p-4 w-full grid gap-3 text-black bg-gray-300"
}
const _hoisted_15 = { class: "grid grid-cols-3" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "text-right" }
const _hoisted_18 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-white font-semibold text-2xl" }, "Movimentos Dia", -1)),
      (_ctx.isFetching)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " A obter dados... "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"grid grid-cols-6 gap-2 font-semibold items-center justify-evenly p-4 border-b-4\" style=\"grid-template-columns:20px 1fr 1fr 1fr 1fr 1fr;\"><div></div><div class=\"\">Documento</div><div class=\"text-right\">Data</div><div class=\"text-right\">Mesa</div><div class=\"text-right\">Funcionário</div><div class=\"text-right\">Total</div></div>", 1)),
        _createElementVNode("div", {
          class: _normalizeClass([{'bg-gray-500': (_ctx.index % 2 === 0), 'bg-gray-600': (_ctx.index % 2 === 1)}, "w-full grid-flow-row text-white"])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documents, (document, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "w-full grid border-b-2",
              key: index
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(), _createElementBlock("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                    onClick: ($event: any) => (document.showDetails = !document.showDetails),
                    class: _normalizeClass(["cursor-pointer w-6 h-6 text-red-500", {'text-color-red-500': (document.showDetails), 'text-green-500': (!document.showDetails)}])
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      d: "M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    }, null, -1)
                  ]), 10, _hoisted_8))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(document.descricao), 1)
                ]),
                _createElementVNode("div", _hoisted_11, _toDisplayString(document.data_criacao), 1),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-right" }, "Mesa", -1)),
                _createElementVNode("div", _hoisted_12, _toDisplayString(document.username), 1),
                _createElementVNode("div", _hoisted_13, _toDisplayString(document.total_documento) + "€", 1)
              ]),
              (document.showDetails)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "grid grid-cols-3 font-semibold justify-evenly items-center border-b border-black" }, [
                      _createElementVNode("div", { class: "" }, "Descrição"),
                      _createElementVNode("div", { class: "text-right" }, "Quantidade"),
                      _createElementVNode("div", { class: "text-right" }, "Preço un.")
                    ], -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.items, (row, lineIndex) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "grid",
                        key: lineIndex
                      }, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, _toDisplayString(row.descricao), 1),
                          _createElementVNode("div", _hoisted_17, _toDisplayString(row.quantidade), 1),
                          _createElementVNode("div", _hoisted_18, _toDisplayString(row.preco_imposto) + "€", 1)
                        ])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ], 2)
      ])
    ])
  ]))
}