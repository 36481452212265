import {POSButton} from "@/interfaces/POSButton";
import {POSFunctionObject} from "@/interfaces/POSFunctionObject";
import {StyleClipboard} from "@/interfaces/StyleClipboard";

const EditMode = {
    state: {
        activeButtonUUID: '' as string,
        currentEditModeButtonData: {} as POSButton,
        styleClipboard: {},
        buttonClipboard: {} as POSButton,
    },
    mutations: {
        clearStyleClipboard(state: any) {
            state.styleClipboard = {}
        },
        styleClipboard(state: any, style: StyleClipboard) {
            state.styleClipboard = style
        },
        buttonClipboard(state: any, button: POSButton) {
            state.buttonClipboard = button
        },
        editModeButtonData(state: any, data: POSButton) {
            state.currentEditModeButtonData = data;
        },
        clearEditModeButtonData(state: any) {
            state.activeButtonUUID = ''
            state.currentEditModeButtonData = {}
        },
        setActiveButtonUUID(state: any, activeButtonUUID: string) {
            state.activeButtonUUID = activeButtonUUID
        },
        setSelectedNewFunction(state: any, newFunction: POSFunctionObject) {
            state.currentEditModeButtonData.fn = newFunction.fn
            state.currentEditModeButtonData.fnDescription = newFunction.description
        },
        setSelectedStyle(state: any, newStyle: StyleClipboard) {
            state.currentEditModeButtonData.backgroundColor = newStyle.backgroundColor
            state.currentEditModeButtonData.textColor = newStyle.textColor
        },
        setSelectedNewFunctionParam(state: any, newFunctionParam: string) {
            state.currentEditModeButtonData.params = newFunctionParam
        },
    },
    getters: {
        currentEditModeButtonData(state: any): POSButton {
            return state.currentEditModeButtonData;
        },
        activeButtonUUID(state: any) {
            return state.activeButtonUUID
        },
        getCopiedStyle(state: any): StyleClipboard {
            return state.styleClipboard
        },
        getCopiedButton(state: any): POSButton {
            return state.buttonClipboard
        }
    },
    actions: {
        savePageButtons(state: any) {
            state.dispatch('changePageButton', {
                    pageNumber: state.getters.currentPage,
                    buttonData: state.getters.currentEditModeButtonData,
                }
            )
        },
        toggleEditMode(state: any, savePageButtons:boolean = true) {

            // save buttons if edit mode is true
            if (state.getters.editMode && savePageButtons) {
                state.dispatch('savePageButtons')
                state.dispatch('savePagesToAPI')
            }

            // change edit mode status
            state.commit('toggleEditMode')

            // clear temporary button data in edit mode
            state.commit('clearEditModeButtonData')
        },
        applyCopiedStyleToCurrentButton(state: any) {
            if (Object.keys(state.getters.getCopiedStyle ?? {}).length > 0) {
                state.commit('setSelectedStyle', state.getters.getCopiedStyle)
            }
        },
    },
}

export {
    EditMode
}
