
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import ItemsView from "@/components/ItemsView.vue";
import DebugView from "@/components/DebugView.vue";
import SettingsView from "@/components/SettingsView.vue";
import StatusView from "@/components/StatusView.vue";
import PrinterSelectionView from "@/components/PrinterSelectionView.vue";
import PaymentMethodSelectionView from "@/components/PaymentMethodSelectionView.vue";
import TableSelectionView from "@/components/TableSelectionView.vue";
import SessionStartView from "@/components/SessionStartView.vue";
import SplitBillView from "@/components/SplitBillView.vue";
import PrintInvoiceCopyView from "@/components/PrintInvoiceCopyView.vue";
import DeliveryView from "@/components/DeliveryView.vue";
import OfflineView from "@/components/OfflineView.vue";
import InsertObservationView from "@/components/Views/InsertObservationView.vue";
import UserClosedSessionsView from "@/components/Views/UserClosedSessionsView.vue";
import OptionsView from "@/components/OptionsView.vue";
import OnlineMovementsView from "@/components/OnlineMovementsView.vue";
import CurrentSessionTotalsView from "@/components/CurrentSessionTotalsView.vue";
import SplitByPeopleView from "@/components/SplitByPeopleView.vue";
import MoveTableView from "@/components/MoveTableView.vue";

export default defineComponent({
  name: 'ContentView',
  components: {
    PrinterSelectionView,
    DebugView,
    SettingsView,
    ItemsView,
    TableSelectionView,
    StatusView,
    PaymentMethodSelectionView,
    SessionStartView,
    SplitBillView,
    PrintInvoiceCopyView,
    DeliveryView,
    OfflineView,
    InsertObservationView,
    UserClosedSessionsView,
    OptionsView,
    OnlineMovementsView,
    CurrentSessionTotalsView,
    SplitByPeopleView,
    MoveTableView,
  },
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['activeView', 'isOnline']),
    getCurrentViewComponent() {
      if (navigator.onLine) {
        return `${this.activeView}View`
      } else {
        // no internet access
        return `OfflineView`
      }
    }
  },
  methods: {},
  mounted() {
  }
})
