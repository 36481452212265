import {WorkDocumentItem} from "@/interfaces/WorkDocumentItem";
import {IItemsFeatures} from "@/interfaces/IItemsFeatures";
import {toRaw} from "vue";

const WorkDocument = {
    state: {
        type_id: localStorage.getItem('type_id') || 0,
        workDocumentSerie: localStorage.getItem('workDocumentSerie') || '',
        customer: localStorage.getItem('customer') || '{}',
        workDocumentItems: localStorage.getItem('workDocumentItems') || '[]',
        selectedWorkDocumentItem: {} as WorkDocumentItem,
        pickupDate: '' as String,
        observation: '' as String,
        previousTotal: 0 as Number,

        //booleans use from wrapper addItemOption
        isQuantityPerPerson: false,
        isQuantityPerPersonTotal: false,
    },
    mutations: {
        customer(state: any, customerData: any) {
            state.customer = JSON.stringify(customerData)
        },
        workDocumentItems(state: any, itemsData: WorkDocumentItem[]) {
            state.workDocumentItems = JSON.stringify(itemsData)
        },
        workDocumentItemRaw(state: any, workDocumentItemRaw: string) {
            state.workDocumentItems = workDocumentItemRaw
        },
        clearCustomer(state: any) {
            state.customer = '{}'
        },
        clearItems(state: any) {
            state.workDocumentItems = '[]'
            localStorage.setItem('workDocumentItems', state.workDocumentItems)
        },
        setTypeId(state: any, type_id: number) {
            state.type_id = parseInt(type_id.toString())
            localStorage.setItem('type_id', type_id.toString())
        },
        setSeries(state: any, series: string) {
            state.workDocumentSerie = series
            localStorage.setItem('series', series)
        },
        addItem(state: any, item: WorkDocumentItem) {
            // add new item to items array
            let items = JSON.parse(state.workDocumentItems)
            let updatedItems = []
            let itemExists = false
            /* add items to updatedItems array */
            for (let existingItem of items) {

                /* check if the item already exists on the list */
                /* items with associated items and associated items are ignored for duplicate verification */
                if ((!item.hasAssociatedItems && item.parentUUID === null) && (existingItem.id === item.id) && (existingItem.price === item.price) && (existingItem.description === item.description) && (existingItem.pickupDate === state.pickupDate)) {

                    /* if exists increases quantity */
                    existingItem.quantity = (item.quantity) ? existingItem.quantity + item.quantity : existingItem.quantity + 1
                    existingItem.quantity = Number(existingItem.quantity.toFixed(2))

                    //existingItem.quantity = parseFloat(existingItem.quantity).toFixed(state.getters.goldylocksConfig.casas_decimais_quantidades)

                    itemExists = true
                }

                updatedItems.push(existingItem)
            }

            // item doesn't  exits on the current document
            if (!itemExists) {


                // check if pickup date is set
                if (state.pickupDate !== '') {
                    item.pickupDate = state.pickupDate
                } else {
                    item.pickupDate = ''
                }

                // set item added timestamp
                item.requestTimestamp = Math.floor(Date.now() / 1000);

                // add item to work document
                updatedItems.push(item)
            }

            // reset previous total
            state.previousTotal = 0

            // save state and localStorage
            state.workDocumentItems = JSON.stringify(updatedItems)
            localStorage.setItem('workDocumentItems', state.workDocumentItems)
        },

        removeItem(state: any, itemIndex: number) {
            // remove item from items array
            let items = JSON.parse(state.workDocumentItems)
            items.splice(itemIndex, 1)

            // save state and localStorage
            state.workDocumentItems = JSON.stringify(items)
            localStorage.setItem('workDocumentItems', state.workDocumentItems)
        },
        removeItemByUUID(state: any, itemUUID: string) {
            let items = JSON.parse(state.workDocumentItems)
            let indexToRemove = null

            for (let index in items) {
                if (items[index].uuid === itemUUID) {
                    indexToRemove = index
                }
            }

            /* remove found index from the array */
            items.splice(indexToRemove, 1)

            // save state and localStorage
            state.workDocumentItems = JSON.stringify(items)
            localStorage.setItem('workDocumentItems', state.workDocumentItems)
        },
        setWorkdocumentSelectedRow(state: any, workDocumentSelectedItem: WorkDocumentItem) {
            state.selectedWorkDocumentItem = workDocumentSelectedItem
        },
        clearWorkDocumentSelectedRow(state: any) {
            state.selectedWorkDocumentItem = {}
        },
        pickupDate(state: any, pickupDate: string) {
            state.pickupDate = pickupDate
        },
        clearPickupDate(state: any) {
            state.pickupDate = ''
        },
        setObservation(state: any, observation: string) {
            state.observation = observation
        },
        previousTotal(state: any, previousTotalValue: number) {
            state.previousTotal = previousTotalValue
        },
        addFeaturesToItems(state: any, feature: IItemsFeatures) {
            let items = JSON.parse(state.workDocumentItems);
            let updatedItems = [];
            let itemSelected = state.selectedWorkDocumentItem;

            // Converter para raw usando toRaw
            const rawFeatures = Array.isArray(feature) ? feature.map(toRaw) : [toRaw(feature)];

            for (let item of items) {
                if (
                    itemSelected.id == item.id &&
                    itemSelected.price == item.price &&
                    itemSelected.description == item.description
                ) {

                    let itemFeatures = rawFeatures.map(({featureValue, idFeatureType, featureType, idFeatureItem}) => ({
                        featureValue,
                        idFeatureType,
                        featureType,
                        idFeatureItem,
                    }));

                    // Adicionar as features ao item
                    item.features = itemFeatures;
                }
                updatedItems.push(item);
            }

            state.workDocumentItems = JSON.stringify(updatedItems);
            localStorage.setItem("workDocumentItems", state.workDocumentItems);
        }


    },
    getters: {
        workDocumentCustomer(state: any) {
            return JSON.parse(state.customer)
        }
        ,
        workDocumentItems(state
                          :
                          any, getters
                          :
                          any
        ):
            any {
            let processedWorkDocumentItems = [] as any;
            let currentWorkDocumentItems = JSON.parse(state.workDocumentItems)

            // process each item
            for (let item of currentWorkDocumentItems) {
                let processedItem = item as any;

                processedItem.price = parseFloat(item.price).toFixed(getters.goldylocksConfig.casas_decimais)

                processedItem.total = processedItem.price * processedItem.quantity
                processedItem.total_without_tax = processedItem.price_without_tax * processedItem.quantity

                processedItem.total = parseFloat(processedItem.total).toFixed(2)
                processedItem.total_without_tax = parseFloat(processedItem.total_without_tax).toFixed(2)

                processedWorkDocumentItems.push(processedItem)
            }


            return processedWorkDocumentItems
        }
        ,
        workDocumentItemRaw(state
                            :
                            any
        ) {
            return state.workDocumentItems
        }
        ,
        workDocumentItemsCount(state
                               :
                               any, getters
                               :
                               any
        ):
            number {
            return getters.workDocumentItems.length
        }
        ,
        workDocumentHasItems(state
                             :
                             any, getters
                             :
                             any
        ):
            boolean {
            return getters.workDocumentItems.length > 0
        }
        ,
        workDocumentTypeId(state
                           :
                           any
        ) {
            return state.type_id
        }
        ,
        workDocumentTypeData(state
                             :
                             any, getters
                             :
                             any
        ) {
            let documentTypes = getters.documentTypes

            if (documentTypes.length > 0) {
                return documentTypes.find((type: any) => parseInt(type.id_tipo_documento) === state.type_id)
            }
        }
        ,
        workDocumentTypeName(state
                             :
                             any, getters
                             :
                             any
        ) {
            if (getters.workDocumentTypeData) {
                return getters.workDocumentTypeData.tipo_documento
            }
        }
        ,
        workDocumentSeries(state
                           :
                           any
        ) {
            return state.workDocumentSerie
        }
        ,
        workDocumentTotal(state
                          :
                          any, getters
                          :
                          any
        ) {
            let items = getters.workDocumentItems

            let total = 0

            items.forEach((item: WorkDocumentItem) => {

                total += (item.quantity * item.price)

                // Verify if after 2 decimal is >0 if its true increment 0.01 because of the tax
                if ((total * 100) % 1 > 0) {
                    total = Math.floor(total * 100) / 100 + 0.01;
                }
            })

            return total
        }
        ,
        getWorkDocumentSelectedItem(state
                                    :
                                    any
        ):
            WorkDocumentItem {
            return state.selectedWorkDocumentItem
        }
        ,
        pickupDate(state
                   :
                   any
        ):
            string {
            return state.pickupDate
        }
        ,
        hasPickupDate(state
                      :
                      any
        ):
            boolean {
            return state.pickupDate !== ''
        }
        ,
        observation(state
                    :
                    any
        ):
            string {
            return state.observation
        }
        ,
        hasObservation(state
                       :
                       any
        ):
            boolean {
            return (state.observation.length > 0)
        }
        ,
        previousTotal(state
                      :
                      any
        ):
            number {
            return state.previousTotal
        }
        ,

    },
    actions: {
        newWorkDocument(state
                        :
                        any
        ) {
            // clear customer and lines
            state.commit('clearCustomer')
            state.commit('clearItems')
            state.commit('clearSelectedCustomerData')

            // set user document type
            state.dispatch('setUserDefaultDocumentType')

            // set document series
            state.dispatch('setUserDefaultDocumentSeries')

            // check for remaining splitted items and save them to the table
            if (state.getters.getRemainingSplittedItems.length > 0) {

                // copy remaining splitted items to current document and save table
                state.dispatch('copyRemainingSplittedItemsToWorkDocument')
                state.dispatch('saveTableItems', true)
                state.commit('clearSplittedItems')
            }
        }
        ,
        saveWorkDocument(state: any) {
            localStorage.setItem('type_id', state.type_id)
            localStorage.setItem('series', state.series)
            localStorage.setItem('customer', JSON.stringify(state.customer))
            localStorage.setItem('workDocumentItems', JSON.stringify(state.workDocumentItems))
        }
        ,
        changeSelectedRowQuantity(state
                                  :
                                  any, newQuantity
                                  :
                                  number
        ) {
            let newItemsArray = [];
            for (let item of state.getters.workDocumentItems) {
                if (item.id === state.getters.getWorkDocumentSelectedItem.id && item.quantity === state.getters.getWorkDocumentSelectedItem.quantity && item.price === state.getters.getWorkDocumentSelectedItem.price) {

                    // toFixed to dividebyPeople get the decimal correctly
                    newQuantity = Number(newQuantity.toFixed(2))

                    item.quantity = newQuantity
                    item.total = (item.quantity * item.price).toFixed(2)
                }

                // only adds the item if the quantity is bigger than 0
                if (item.quantity > 0) {
                    newItemsArray.push(item)
                }
            }

            // set work document items
            state.commit('workDocumentItems', newItemsArray)
        }
        ,

        // set item row observations
        changeSelectedRowObservations(state
                                      :
                                      any, newObservations
                                      :
                                      string
        ) {
            let newItemsArray = [];
            for (let item of state.getters.workDocumentItems) {
                if (item.id === state.getters.getWorkDocumentSelectedItem.id && item.quantity === state.getters.getWorkDocumentSelectedItem.quantity && item.price === state.getters.getWorkDocumentSelectedItem.price) {
                    item.observation = newObservations
                }

                // add changed item to the array
                newItemsArray.push(item)
            }

            // set work document items
            state.commit('workDocumentItems', newItemsArray)
        }
        ,
        changeSelectedRowPrice(state
                               :
                               any, newPrice
                               :
                               number
        ) {
            let newItemsArray = [];
            for (let item of state.getters.workDocumentItems) {
                if (item.id === state.getters.getWorkDocumentSelectedItem.id && item.quantity === state.getters.getWorkDocumentSelectedItem.quantity && parseFloat(item.price) === parseFloat(state.getters.getWorkDocumentSelectedItem.price)) {

                    if (state.getters.workDocumentTypeData['imposto_incluido'] === "0") {
                        /* without tax*/
                        item.price_without_tax = parseFloat(newPrice.toString()).toFixed(state.getters.goldylocksConfig.casas_decimais)
                        item.price = item.price_without_tax * (1 + (item.tax_rate / 100))
                    } else {
                        /* with tax */
                        item.price = parseFloat(newPrice.toString()).toFixed(state.getters.goldylocksConfig.casas_decimais)
                        item.price_without_total = item.price / (1 + (item.tax_rate / 100))
                    }

                    item.total = (item.quantity * item.price).toFixed(2)
                }

                newItemsArray.push(item)
            }

            // set work document items
            state.commit('workDocumentItems', newItemsArray)
        }
        ,
        removeItemByData(state
                         :
                         any, itemToRemove
                         :
                         WorkDocumentItem
        ) {
            let items = state.getters.workDocumentItems
            items.forEach((item: WorkDocumentItem) => {

                /* check UUID on items and associated items */
                if (item.uuid === itemToRemove.uuid) {

                    /* remove associated items if any */
                    state.dispatch('removeAssociatedItems', item.uuid)

                    /* remove item */
                    state.commit('removeItemByUUID', item.uuid)
                    state.commit('clearWorkDocumentSelectedRow')
                }
            })
        }
        ,
        removeAssociatedItems(state
                              :
                              any, parentItemUUID
                              :
                              string
        ) {
            let items = state.getters.workDocumentItems
            items.forEach((item: WorkDocumentItem, index: number) => {

                /* check UUID on items and associated items */
                if (item.parentUUID === parentItemUUID) {

                    /* remove associated items */
                    state.dispatch('removeAssociatedItems', item.uuid)

                    /* remove item */
                    state.dispatch('removeItemByData', item)
                }
            })
        }
        ,
        addItemOption(state
                      :
                      any, params
                      :
                      {
                          item: WorkDocumentItem,
                          isQuantityPerPerson
                              :
                              boolean,
                          isQuantityPerPersonTotal
                              :
                              boolean,
                      }
        ) {

            // this function is the wrapper before the AddItem where we change the quantity entire line or divide by person
            // The use of `toFixed(2)` is necessary to ensure the value is correctly rounded to 2
            if (params.isQuantityPerPerson) {

                // call to move item from split side to Work Document
                params.item.quantity = Number(params.item.quantity.toFixed(2))
                params.item.quantityPerPerson = Number(params.item.quantityPerPerson.toFixed(2))
                params.item.difference = Number(params.item.difference.toFixed(2))

                state.commit('addItem', params.item)
                state.isQuantityPerPerson = false
            }
            if (params.isQuantityPerPersonTotal) {

                // call to move entire bill from split side to Work Document
                params.item.quantity = params.item.quantityPerPersonTotal

                params.item.quantity = Number(params.item.quantity.toFixed(2))
                params.item.quantityPerPerson = Number(params.item.quantityPerPerson.toFixed(2))
                params.item.difference = Number(params.item.difference.toFixed(2))

                state.commit('addItem', params.item)
                state.isQuantityPerPersonTotal = false
            }
        }
        ,
    }
    ,
}

export {WorkDocument}