const Reports = {
    state: {
        dayOnlineMovements: [],
        fetchingDayOnlineMovements: true as boolean,
    },
    mutations: {
        clearOnlineDayMovements(state: any) {
            state.dayOnlineMovements = []
        },
        setDayOnlineMovements(state: any, dayOnlineMovements: any) {
            state.dayOnlineMovements = dayOnlineMovements
        },
        fetchingDayOnlineMovements(state: any, fetchingDayOnlineMovements: boolean) {
            state.fetchingDayOnlineMovements = fetchingDayOnlineMovements
        }
    },
    getters: {
        dayOnlineMovements(state: any): any {
            return state.dayOnlineMovements
        },
        fetchingDayOnlineMovements(state: any): boolean {
            return state.fetchingDayOnlineMovements
        }
    },
    actions: {
        async fetchDayMovements(state: any) {

            /* generate today date string */
            const date = new Date()
            const today = {
                day: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            }
            const todayString = `${today.year}-${today.month}-${today.day}`

            /* set fetch flag as true */
            state.commit('fetchingDayOnlineMovements', true)


            /* fetch movements */
            const response = await fetch(`${state.getters.apiURL}linhas/?di=${todayString}&df=${todayString}&desactivados=0&artigo=&familia=null&order=desc`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`
                }
            })

            /* set fetch flag status as false */
            state.commit('fetchingDayOnlineMovements', false)

            /* check fetch response */
            if (response.ok) {
                state.commit('setDayOnlineMovements', await response.json())
            } else {
                throw new Error('error_fetching_online_movements')
            }
        },
    },
}

export {
    Reports,
}
