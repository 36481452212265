const Modals = {
    state: {
        activeModal: '',
    },
    getters: {
        getActiveModal(state: any) {
            return state.activeModal
        },
        isModalVisible(state: any) {
            return state.activeModal.length
        }
    },
    mutations: {
        setActiveModal(state: any, activeModal: string) {
            state.activeModal = activeModal
        }
    },
    actions: {
        openModal(state: any, modalComponentName: string) {
            state.commit('setActiveModal', modalComponentName)
        },
        closeActiveModal(state: any) {

            /* close active modal*/
            state.commit('setActiveModal', '')
        }
    }
}

export {
    Modals
}
