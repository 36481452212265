
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import {XIcon} from "@heroicons/vue/solid";

export default defineComponent({
  name: 'ModalNewCustomer',
  components: {KeyboardInput, XIcon,},
  props: [],
  data() {
    return {
      customer: {
        id: 0,
        name: '',
        address: '',
        zipcode: '',
        city: '',
        vat: '',
        phone: '',
        mobile: '',
        email: '',
      }
    }
  },
  computed: {
    ...mapGetters(['customersSearchResults', 'customerBeingEditedID']),
  },
  methods: {
    ...mapActions(['closeActiveModal', 'createCustomer', 'fetchCustomerByID', 'searchCustomers']),
    ...mapMutations(['clearCustomerBeingEditedID', 'setSelectedCustomerData', 'setCustomerSearchString']),
    closeModal() {
      this.closeActiveModal()
    },
    async createAndSetCustomer() {
      try {
        // create customer
        if (this.customer.id > 0) {
          await this.createCustomer(this.customer)
        } else {
          this.customer.id = await this.createCustomer(this.customer)
        }

        // get new customer data from id
        let customerData = await this.fetchCustomerByID(this.customer.id)

        // set selected customer data
        if (customerData)
          this.setSelectedCustomerData(customerData[0])

        // close modal
        this.closeActiveModal()
      } catch (e) {
        // vat number already exists
        if (e === 'erro_nif') {
          // get existing vat number data
          this.setCustomerSearchString(this.customer.vat)
          await this.searchCustomers()

          // set selected customer data
          if (this.customersSearchResults) {
            this.setSelectedCustomerData(this.customersSearchResults[0])
          }

          // close modal
          this.closeActiveModal()
        }
      }
    },
    async submitForm() {
      // check if any of an object properties have been filled
      if (Object.values(this.customer).some(value => value)) {
        if (this.customer.vat) {
          await this.createAndSetCustomer()
        } else {
          this.closeActiveModal()
        }
      } else {
        // close modal
        this.closeActiveModal()
      }
    },
  },
  async mounted() {
    if (this.customerBeingEditedID > 0) {
      /* get customer data */
      let fetchedCustomerData = await this.fetchCustomerByID(this.customerBeingEditedID)
      const customerData = fetchedCustomerData[0]

      this.customer = {
        id: this.customerBeingEditedID,
        name: customerData.nome,
        address: customerData.morada,
        zipcode: customerData.cp,
        city: '',
        vat: customerData.nif,
        phone: customerData.telefone,
        mobile: customerData.telemovel,
        email: customerData.email,
      }

      /* clear edit id */
      this.clearCustomerBeingEditedID()
    }
  }
})
