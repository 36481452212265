import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args))),
    class: "bg-white hover:bg-gray-300 hover:text-white border h-16 text-2xl font-bold cursor-pointer flex items-center justify-center"
  }, [
    _createElementVNode("div", { innerHTML: _ctx.text }, null, 8, _hoisted_1)
  ]))
}