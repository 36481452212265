import {DefaultSettingsData} from "@/data/DefaultSettingsData";

const Settings = {
    state: {
        defaults: DefaultSettingsData,
        appName: 'Goldylocks POS',
        appVersion: '1.3.5',
        baseURL: localStorage.getItem('base_url') || `https://app.goldylocks.pt/`,
        alias: localStorage.getItem('alias') || '',
        username: localStorage.getItem('username') || '',
        goldylocks: localStorage.getItem('goldylocks') || '',
        editMode: false,
    },
    mutations: {
        toggleEditMode(state: any) {
            state.editMode = !state.editMode
        },
        setAlias(state: any, alias: string) {
            state.alias = alias.trim().toLowerCase()
            localStorage.setItem('alias', state.alias)
        },
        setUsername(state: any, username: string) {
            state.username = username
            localStorage.setItem('username', username)
        },
        setBaseURL(state: any, baseURL: string) {
            state.baseURL = baseURL
            localStorage.setItem('base_url', baseURL)
        },
        setGoldylocksConfig(state: any, goldylocksConfig: any) {
            state.goldylocks = JSON.stringify(goldylocksConfig)
            localStorage.setItem('goldylocks', state.goldylocks)
        },
        setDefaultColumns(state: any, defaultColumns: number) {
            state.defaults.pages.columns = defaultColumns ?? 5
        },
        setDefaultRows(state: any, defaultRows: number) {
            state.defaults.pages.rows = defaultRows ?? 5
        },
    },
    getters: {
        editMode(state: any) {
            return state.editMode
        },
        alias(state: any): string {
            return state.alias
        },
        username(state: any) {
            return state.username
        },
        apiURL(state: any): string {
            // DEV API server
            if (state.alias === 'gl') {
                return `https://dev.goldylocks.pt/${state.alias}/api/`
            }

            // PROD API server
            return `${state.baseURL}${state.alias}/api/`

        },
        itemsThumbnailBaseURL(state: any): string {
            if (state.alias === 'gl') {
                return `https://dev.goldylocks.pt/${state.alias}/data/images/`
            }

            return `${state.baseURL}${state.alias}/data/images/`
        },
        appName(state: any): string {
            return state.appName
        },
        appVersion(state: any): string {
            return state.appVersion
        },
        api2URL(state: any): string {
            // DEV API server
            if (state.alias === 'gl') {
                return `https://dev.goldylocks.pt/${state.alias}/api2/`
            }

            // PRODUCTION API server
            return `${state.baseURL}${state.alias}/api2/`
        },
        defaultPageColumns(state: any): number {
            return state.defaults.pages.columns
        },
        defaultPageRows(state: any): number {
            return state.defaults.pages.rows
        },
        defaultPageColumnsMobile(state: any): number {
            return state.defaults.pages.columns_mobile
        },
        defaultPageRowsMobile(state: any): number {
            return state.defaults.pages.rows_mobile
        },
        defaultSettingsPageNumber: function (state: any) {
            return state.defaults.pages.settingsPageNumber
        },
        defaultOptionsPageNumber: function (state: any) {
            return state.defaults.pages.optionsPageNumber
        },
        buttonDefaultFamiliesBackground(state: any): string {
            return state.defaults.buttons.defaultFamiliesBackground
        },
        buttonDefaultFamiliesTextColor(state: any): string {
            return state.defaults.buttons.defaultFamiliesTextColor
        },
        buttonDefaultItemsBackground(state: any): string {
            return state.defaults.buttons.defaultItemsBackground
        },
        buttonDefaultItemsTextColor(state: any): string {
            return state.defaults.buttons.defaultItemsTextColor
        },
        defaultPage(state: any): number {
            return state.defaults.page
        },
        hasConfiguration(state: any): boolean {
            return state.goldylocks.length > 0
        },
        goldylocksConfig(state: any) {
            return JSON.parse(state.goldylocks).goldylocks.opcoes
        },
        reservedPageButtonSlots(state: any) {
            return state.defaults.pages.reservedPageButtonSlots
        }
    },
    actions: {
        fetchGoldylocksSettings(state: any): any {
            return new Promise<void>((resolve, reject) => {
                fetch(`${state.getters.apiURL}status`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(config => {
                        state.commit('setGoldylocksConfig', config)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        fetchGoldylocksDateTime(state: any) {
            return new Promise<string>((resolve, reject) => {
                fetch(`${state.getters.apiURL}datahora`, {
                    method: 'GET',
                })
                    .then(res => res.text())
                    .then(dateTime => resolve(dateTime))
                    .catch(err => reject(err))
            })
        },
    },
}

export {
    Settings
}