import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full" }
const _hoisted_2 = { class: "h-16 grid grid-cols-2 bg-raisinBlack-400 text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageButton = _resolveComponent("PageButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      tabindex: "0",
      onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.moveButton && _ctx.moveButton(...args))),
      class: _normalizeClass(['bg-raisinBlack-300 flex-grow p-1 w-full grid gap-2',_ctx.gridRowsClass,_ctx.gridColumnsClass])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentPageButtons, (button, index) => {
        return (_openBlock(), _createBlock(_component_PageButton, {
          key: index,
          button: button
        }, null, 8, ["button"]))
      }), 128))
    ], 34),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["grid place-items-center", [{'cursor-pointer hover:bg-gray-700':_ctx.canGoBackPagination},{'bg-gray-600 cursor-not-allowed text-gray-600':!_ctx.canGoBackPagination},{'opacity-40 pointer-events-none':_ctx.currentEditModeButtonData.position >= 0 && _ctx.canGoBackPagination}]]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fnMoveToPreviousPagination && _ctx.fnMoveToPreviousPagination(...args)))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          class: "h-6 w-6",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor",
          "stroke-width": "2"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            d: "M15 19l-7-7 7-7"
          })
        ], -1)
      ]), 2),
      _createElementVNode("div", {
        class: _normalizeClass(["grid place-items-center", [{'cursor-pointer hover:bg-gray-700':_ctx.canGoForwardPagination},{'bg-gray-600 cursor-not-allowed text-gray-600':!_ctx.canGoForwardPagination},{'opacity-40 pointer-events-none':_ctx.currentEditModeButtonData.position >= 0 && _ctx.canGoForwardPagination}]]),
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.fnMoveToNextPagination && _ctx.fnMoveToNextPagination(...args)))
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          class: "h-6 w-6",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor",
          "stroke-width": "2"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            d: "M9 5l7 7-7 7"
          })
        ], -1)
      ]), 2)
    ])
  ]))
}