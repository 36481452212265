import {POSFunctionCall} from "@/interfaces/POSFunctionCall";
import {POSFunctionObject} from "@/interfaces/POSFunctionObject";
import {POSFunctionsData} from "@/data/POSFunctionsData";

const POSFunctions = {
    state: {
        POSFunctions: POSFunctionsData as POSFunctionObject[]
    },
    mutations: {
        addPOSFunction(state: any, POSFunction: POSFunctionObject) {
            state.POSFunctions.push(POSFunction);
        },
    },
    getters: {
        getPOSFunctions(state: any): POSFunctionObject[] {
            return state.POSFunctions.sort(function (a: any, b: any) {
                if (a.description > b.description) {
                    return 1;
                }
                if (a.description < b.description) {
                    return -1;
                }
                return 0;
            });
        }
    },
    actions: {
        exec(state: any, fnParams: POSFunctionCall) {
            if (fnParams.fn) {
                state.dispatch(fnParams.fn, fnParams.params);
            }
        }
    }
}

export {
    POSFunctions
}
