
import {defineComponent} from "vue";
import OperationsSectionDetailsRow from "@/components/OperationsSectionDetailsRow.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ChevronLeftIcon, XIcon} from "@heroicons/vue/outline";
import DocumentTypeLabel from "@/components/DocumentTypeLabel.vue";
import DocumentSerialLabel from "@/components/DocumentSerialLabel.vue";

export default defineComponent({
  name: "MobileOperations",
  data() {
    return {
      componentName: "MobileOperations"
    }
  },
  components: {
    DocumentSerialLabel, DocumentTypeLabel,
    OperationsSectionDetailsRow,
    ChevronLeftIcon,
    XIcon,
  },
  props: [
    ''
  ],
  emits: [
    'openMobileOperations'
  ],
  computed: {
    ...mapGetters(['workDocumentTypeName','workDocumentSeries','workDocumentTotal', 'workDocumentItems', 'currentTable', 'defaultSettingsPageNumber'])
  },
  methods: {
    ...mapMutations(['setCurrentPage', 'activeView']),
    ...mapActions(['saveTableItems', 'fnOpenView', 'generatePages', 'exec', 'fnPage', 'fnCreateInvoiceFromWorkDocument', 'incrementCurrentItemsListIndex', 'decrementCurrentItemsListIndex', 'fnClearItems', 'fnRemoveSelectedItem']),
    goToInitialPage() {
      this.fnOpenView('Items')
      this.setCurrentPage(0)
    },
    saveTableItemsMobile() {
      this.saveTableItems()
      this.openMobileOperations()
    },
    async createInvoice() {
      try {
        this.openMobileOperations();
        await this.fnCreateInvoiceFromWorkDocument()
      } catch (err) {
        console.error(err)
      }
    },
    openView(viewName: string) {
      this.fnOpenView(viewName)
      this.openMobileOperations();
    },
    openPage(pageNumber: number) {
      this.fnPage(pageNumber)
      this.openMobileOperations();
    },
    openSettings() {
      this.fnPage(this.defaultSettingsPageNumber)
      this.openMobileOperations();
    },
    openMobileOperations() {
      this.$emit('openMobileOperations')
    }
  },
  watch: {},
  mounted() {
  }
})
