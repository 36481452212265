import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col h-screen px-2 main-bgcolor" }
const _hoisted_2 = { class: "bg-white flex-auto" }
const _hoisted_3 = { class: "bg-gray-100 border-t h-52" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OperationsSectionHeader = _resolveComponent("OperationsSectionHeader")!
  const _component_EditModeDetailsSection = _resolveComponent("EditModeDetailsSection")!
  const _component_OperationsSectionDetails = _resolveComponent("OperationsSectionDetails")!
  const _component_EditModeFooterSection = _resolveComponent("EditModeFooterSection")!
  const _component_OperationsSectionFooter = _resolveComponent("OperationsSectionFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_OperationsSectionHeader)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_EditModeDetailsSection, { key: 0 }))
        : (_openBlock(), _createBlock(_component_OperationsSectionDetails, { key: 1 }))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_EditModeFooterSection, { key: 0 }))
        : (_openBlock(), _createBlock(_component_OperationsSectionFooter, { key: 1 }))
    ])
  ]))
}