import {POSPage} from "@/interfaces/POSPage";
import {POSNewPageButtonParams} from "@/interfaces/POSNewPageButtonParams";
import {POSChangeButtonParams} from "@/interfaces/POSChangeButtonParams";
import {POSRemoveButtonParams} from "@/interfaces/POSRemoveButtonParams";
import {POSButton} from "@/interfaces/POSButton";
import {v4} from "uuid";
import {SetButtonPositionParams} from "@/interfaces/SetButtonPositionParams";
import {DefaultOperationsButtons} from "@/data/DefaultOperationsButtons";
import {fnSystem} from "@/store/modules/POSFunctions/fnSystem"
import POSnotyf from "@/libs/POSnotyf";
import {IFetchPagesFromAPIParams} from "@/interfaces/IFetchPagesFromAPIParams";

const Pages = {
    state: {
        generationHelpers: {
            currentPage: 0,
            mainPageBackup: {},
        },
        generationOptions: {
            keepMainPage: false,
        },
        pages: [],
    },
    getters: {
        pages(state: any): any {
            return state.pages
        },
        page(state: any, pageNumber: number): POSPage | boolean {
            state.pages.forEach((page: POSPage) => {
                if (page.id === pageNumber) {
                    return page
                }
            })
            return false
        },
        currentNewPage(state: any) {
            return state.generationHelpers.currentPage
        },
        mainPage(state: any): POSPage | boolean {
            for (let page of state.pages) {
                if (page.id === 0) {
                    return page
                }
            }

            return false
        },
        keepMainPage(state: any): boolean {
            return state.generationOptions.keepMainPage
        },
        mainPageBackup(state: any): any {
            return state.generationHelpers.mainPageBackup
        },
        getTotalPages(state: any): any {
            return state.pages.length
        }
    },
    mutations: {
        pages(state: any, pages: POSPage[]) {
            state.pages = pages
        },
        resetNewPageCounter(state: any) {
            state.generationHelpers.currentPage = 0
        },
        incrementNewPageCounter(state: any) {
            state.generationHelpers.currentPage++
        },
        keepMainPage(state: any) {
            state.generationOptions.keepMainPage = true
        },
        dontKeepMainPage(state: any) {
            state.generationOptions.keepMainPage = false
        },
        mainPageBackup(state: any, mainPageContent: any) {
            state.generationHelpers.mainPageBackup = mainPageContent
        },
        removePageByID(state: any, pageID: number) {
            state.pages = state.pages.filter((page: POSPage) => page.id !== pageID)
        },
        setGenerationHelpersCurrentPage(state: any){
          state.generationHelpers.currentPage = state.pages.length-1
        },
    },
    actions: {
        async createPage(state: any, page: POSPage) {
            let pages = state.getters.pages
            pages.push(page)
            state.commit('pages', pages)
        },
        async createNewPage(state: any, page: POSPage) {
            const currentPage = state.getters.currentNewPage
            let currentButtonsPage = state.getters.currentButtonsPagination
            let maximumPageButtons = state.getters.maximumPageButtons ?? 0

            await state.dispatch('addButtonToPage', {
                pageNumber: currentPage,
                button: {
                    uuid: v4(),
                    position: ((currentButtonsPage + 1) * maximumPageButtons) + 1,
                    backgroundColor: '#464957',
                    textColor: '#FFFFFF',
                    fn: '',
                    params: '',
                    system: false,
                    custom: false,
                }
            })
        },
        clearPages(state: any) {
            state.commit('pages', [])
        },
        async addButtonToPage(state: any, newButtonParams: POSNewPageButtonParams) {
            return new Promise<void>(async (resolve) => {
                let pages = state.getters.pages

                let pagesWithNewButton: any = []
                pages.forEach((page: any) => {
                    if (page.id === newButtonParams.pageNumber) {



                        /* adds if positon is free */
                        if (typeof page.buttons[newButtonParams.button.position] === "undefined") {
                            page.buttons[newButtonParams.button.position] = newButtonParams.button

                        } else {
                            let isInserted = false


                            /* search for the first empty position*/
                            for (let i in page.buttons) {
                                if (page.buttons[i] === "undefined" && !isInserted) {

                                    /* sets the button position as the current array free position */
                                    newButtonParams.button.position = parseInt(i)

                                    /* insert button at the current free position */
                                    page.buttons[i] = newButtonParams.button
                                    isInserted = true
                                }
                            }

                            /* inserts button in the last position */
                            if (!isInserted) {

                                /* set the buttons position as the last one */
                                newButtonParams.button.position = (page.buttons.length)

                                /* add button to the buttons array*/
                                page.buttons.push(newButtonParams.button)
                            }
                        }
                    }
                    pagesWithNewButton.push(page)
                })

                await state.commit('pages', pagesWithNewButton)

                resolve()
            })
        },
        async generatePages(state: any) {

            state.dispatch('showStatusMessage', `<div class="text-xl font-light">A gerar páginas...</div>`)

            /* if keep main page option is set to true saves a backup of the page */
            if (state.getters.keepMainPage)
                state.commit('mainPageBackup', state.getters.mainPage)

            // remove all items
            state.commit('clearItems')

            // remove generated pages
            state.dispatch('clearPages')

            // reset new page counter
            state.commit('resetNewPageCounter')

            // fetch price lines
            await state.dispatch('fetchPriceLines')

            // get all families
            await state.dispatch('fetchFamilies')

            // get all items
            await state.dispatch('fetchItems')

            /* generate all pages */
            await state.dispatch('generatePagesButtonsFromFamily', {familyId: 0, params: null} )

            // restore first page if keep main page is true
            if (state.getters.keepMainPage) {
                state.commit('removePageByID', 0) // remove the generated first page
                await state.dispatch('createPage', state.getters.mainPageBackup) // place the existing page backup
            }

            // generate setting page
            await state.dispatch('generateSettingsPage')


            // set the initial page
            if (state.getters.currentPage === state.getters.defaultSettingsPageNumber) {
                state.commit('setCurrentPage', state.getters.defaultSettingsPageNumber)
            } else if (state.getters.currentPage === state.getters.defaultOptionsPageNumber) {
                state.commit('setCurrentPage', state.getters.defaultOptionsPageNumber)
            } else {
                state.commit('setCurrentPage', 0)
            }

            // save generated pages to Goldylocks API
            state.dispatch('savePagesToAPI')

            // close status view
            state.dispatch('closeStatusMessage')
        },



        async generatePagesButtonsFromFamily( state: any,  { familyId, params }: { familyId: number; params?: { pageNumber: number; position: number, family} } ): Promise<number> {
            return new Promise<number>(async (resolve) => {
                // get new page id to be used
                let currentPage = 0

                if (params && params.pageNumber !== null) {
                    // used when add families without generate pages

                    // increment new page id
                    state.commit('incrementNewPageCounter')
                    currentPage = state.getters.currentNewPage
                } else {
                    // use on generate pages id 0 is saved futher increment
                    currentPage = state.getters.currentNewPage
                    // increment new page id
                    state.commit('incrementNewPageCounter')
                }


                // get families and items
                const pageFamilies = await state.dispatch('getChildFamilies', familyId)
                const pageItems = await state.dispatch('getFamilyItems', familyId)

                // create family page
                await state.dispatch('createPage', {
                    id: currentPage,
                    columns: state.getters.defaultPageColumns,
                    rows: state.getters.defaultPageRows,
                    columns_mobile: state.getters.defaultPageColumnsMobile,
                    rows_mobile: state.getters.defaultPageRowsMobile,
                    buttons: [],
                    meta: {
                        family_id: Number(familyId),
                    }
                })

                if (params && params.pageNumber !== null) {

                    // do it when add pages without generate pages
                    let imageURL = (params.family.tem_imagem === "true") ? `${state.getters.apiURL}?t=imagemfamilia&p=${params.family.id_familia}&thumbnail=1` : null


                    await state.dispatch('addButtonToPage', {
                        pageNumber: params.pageNumber,
                        button: {
                            uuid: v4(),
                            position: params.position,
                            label: `${params.family.descricao}`,
                            backgroundColor: state.getters.buttonDefaultFamiliesBackground,
                            textColor: state.getters.buttonDefaultFamiliesTextColor,
                            fn: 'fnPage',
                            imageURL,
                            params: currentPage,
                            custom: false,
                            system: false,
                        }
                    })

                }

                    // generate main page system buttons
                    if (familyId === 0) {
                        let maximumPageButtons = state.getters.defaultPageColumns * state.getters.defaultPageRows
                        let subtractValue = DefaultOperationsButtons.length

                        for (const defaultButton of DefaultOperationsButtons) {

                            defaultButton.position = (maximumPageButtons - subtractValue)

                            await state.dispatch('addButtonToPage', {
                                pageNumber: currentPage,
                                button: defaultButton,
                            })

                            subtractValue--
                        }
                    }

                    // initialize page buttons counter
                    let currentButtonPosition = 0


                    // iterate each family button and page to be created
                    for (const family of pageFamilies) {


                        let newFamilyPageNumber = await state.dispatch('generatePagesButtonsFromFamily', {
                            familyId: parseInt(family.id_familia),
                            params: null
                        })

                        let imageURL = (family.tem_imagem === "true") ? `${state.getters.apiURL}?t=imagemfamilia&p=${family.id_familia}&thumbnail=1` : null


                        await state.dispatch('addButtonToPage', {
                            pageNumber: currentPage,
                            button: {
                                uuid: v4(),
                                position: currentButtonPosition,
                                label: `${family.descricao}`,
                                backgroundColor: state.getters.buttonDefaultFamiliesBackground,
                                textColor: state.getters.buttonDefaultFamiliesTextColor,
                                fn: 'fnPage',
                                imageURL,
                                params: newFamilyPageNumber,
                                custom: false,
                                system: false,
                            }
                        })

                        currentButtonPosition++
                    }


                    // insert items buttons
                    for (const item of pageItems) {
                        await state.dispatch('addButtonToPage', {
                            pageNumber: currentPage,
                            button: {
                                uuid: v4(),
                                position: currentButtonPosition,
                                label: `${item.nome}`,
                                backgroundColor: state.getters.buttonDefaultItemsBackground,
                                textColor: state.getters.buttonDefaultItemsTextColor,
                                imagem: item.hash_id_imagem,
                                imageURL: null,
                                fn: 'fnAddItem',
                                params: item.cod_barras,
                                system: false,
                                custom: false,
                            }
                        })

                        currentButtonPosition++
                    }



                    // resolve with current page number
                    resolve(currentPage)

            })
        },
        changePageButton(state: any, params: POSChangeButtonParams) {

            // get all pages
            let pages = state.getters.pages

            // iterate each page until finding the page id
            pages.forEach((page: any, pageIndex: number) => {

                if (page && page.id === params.pageNumber) {
                    pages[pageIndex].buttons[params.buttonData.position] = params.buttonData
                }
            })

            // save modified pages
            state.commit('pages', pages)
        },
        removePageButton(state: any, params: POSRemoveButtonParams) {
            let pages = state.getters.pages

            pages.forEach((page: POSPage, index: number) => {
                if (page.id === params.pageNumber) {

                    /* delete button from the page buttons array */
                    delete page.buttons[params.buttonPosition]

                    /* save page modifications */
                    pages[index] = page

                    // save pages
                    state.commit('pages', pages)
                }
            })
        },
        setButtonPosition(state: any, params: SetButtonPositionParams) {
            return new Promise<void>((resolve, reject) => {
                let pages = state.getters.pages
                let oldPosition = 0
                let overlap = false
                let samePositionButtonIndex = 0

                let buttonMoving = {} as POSButton
                let overlappedButton = {} as POSButton

                pages.forEach((page: POSPage, index: number) => {
                    if (page.id === params.pageNumber) {
                        page.buttons = page.buttons.map((button: POSButton, buttonIndex: number) => {
                            if (button && button.uuid === params.buttonUUID) {

                                /* button start position */
                                oldPosition = button.position

                                /* set new position*/
                                button.position = params.newPosition

                                /* store it on temp var */
                                buttonMoving = button
                            }

                            // check to see if already exists some button on the same position
                            if (button && button.uuid !== params.buttonUUID && button.position === params.newPosition) {
                                overlap = true
                                samePositionButtonIndex = buttonIndex

                                overlappedButton = button
                            }

                            return button
                        })

                        // update button on the same old position
                        if (overlap) {
                            overlappedButton.position = oldPosition
                            page.buttons[oldPosition] = overlappedButton
                        } else {
                            /* no overlapped button */
                            delete page.buttons[oldPosition]
                        }

                        /* insert moving button on the new position */
                        page.buttons[params.newPosition] = buttonMoving

                        // save page
                        pages[index] = page

                        // save pages
                        state.commit('pages', pages)

                        resolve()
                    }
                })

                reject()
            })

        },
        savePagesToAPI(state: any) {
            return new Promise<void>((resolve, reject) => {

                let formData = new FormData()
                formData.append('paginas', JSON.stringify(state.getters.pages))
                //check if post POS exists
                if(state.getters.getSessionData.id_caixa > 0 ){
                    formData.append('id_caixa', JSON.stringify(state.getters.getSessionData.id_caixa))
                }


                fetch(`${state.getters.apiURL}guardarpaginaspos`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then(res => {
                        if (res === 'ok') {
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        fetchPagesFromAPI(state: any, params: IFetchPagesFromAPIParams) {
            return new Promise<void>((resolve, reject) => {
                // Inicialize URL condition for get id_caixa
                let condSession = ""
                // check if post POS exists
                if(state.getters.getSessionData.id_caixa > 0 ){
                    condSession=`&id_caixa=${state.getters.getSessionData.id_caixa}`
                }

                fetch(`${state.getters.apiURL}pos_paginas${condSession}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`

                    },
                })
                    .then(res => res.json())
                    .then(pages => {
                        if (pages.erro) {
                            if (pages.erro === 'sem_subscricao_ativa') {
                                /* no subscription error */
                                POSnotyf.error(`<strong>Subscrição inativa!</strong><br>Saiba mais informação em <strong>areacliente.goldylocks.pt</strong>`, 9999999, true)
                            }
                        } else {

                            if (pages.length == 0 || (params?.forcePageGeneration === true && pages.length == 0)) {
                                state.dispatch("generatePages")
                            }
                            else {

                                let settingsPageIndex = pages.findIndex((page: any) => (page && page.id == state.getters.defaultSettingsPageNumber))

                                if (!state.isElectronApp) {

                                    pages[settingsPageIndex].buttons = pages[settingsPageIndex].buttons.filter((button: any) => {
                                        /* check if the button is defined */
                                        if (button !== null) {

                                            if (button.fn != "fnQuit") {
                                                return true
                                            }
                                        }

                                        /* wont return the button */
                                        return false
                                    })
                                }
                            }

                            // mutation to change the pages rendered on the screen
                            state.commit('pages', pages)

                            // returns the current pages
                            resolve(pages)
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        generateSettingsPage: async function (state: any) {

            await state.dispatch('createPage', {
                id: state.getters.defaultSettingsPageNumber,
                columns: state.getters.defaultPageColumns,
                rows: state.getters.defaultPageRows,
                columns_mobile:  state.getters.defaultPageColumnsMobile,
                rows_mobile: state.getters.defaultPageRowsMobile,
                buttons: [{
                    "uuid": v4(),
                    "position": 0,
                    "label": "Gerar Páginas",
                    "backgroundColor": "#0854a1",
                    "textColor": "#FFFFFF",
                    "fn": "fnGeneratePages",
                    "params": ""
                }, {
                    "uuid": v4(),
                    "position": 1,
                    "label": "Abrir Debug",
                    "backgroundColor": "#0854a1",
                    "textColor": "#FFFFFF",
                    "fn": "fnOpenView",
                    "params": "Debug"
                }, {
                    "uuid": v4(),
                    "position": 24,
                    "label": "Sair",
                    "backgroundColor": "#e77e49",
                    "textColor": "#FFFFFF",
                    "fn": "fnQuit",
                    "params": "0"
                }, {
                    "uuid": v4(),
                    "position": 4,
                    "label": "Logout",
                    "backgroundColor": "#a10008",
                    "textColor": "#FFFFFF",
                    "fn": "fnLogout"
                },
                    {
                        "uuid": v4(),
                        "position": 5,
                        "label": "Reimprimir Talão",
                        "backgroundColor": "#0854a1",
                        "textColor": "#FFFFFF",
                        "fn": "fnInvoiceCopy",
                    },
                    {
                        "uuid": v4(),
                        "position": 6,
                        "label": "Fechar Caixa",
                        "backgroundColor": "#0854a1",
                        "textColor": "#FFFFFF",
                        "fn": "fnCloseSession",
                    },
                    {
                        "uuid": v4(),
                        "position": 7,
                        "label": "Dividir Conta",
                        "backgroundColor": "#0854a1",
                        "textColor": "#FFFFFF",
                        "fn": "fnOpenSplitBillView",
                    },
                    {
                        "uuid": v4(),
                        "position": 8,
                        "label": "Consulta dia",
                        "backgroundColor": "#0854a1",
                        "textColor": "#FFFFFF",
                        "fn": "fnShowCurrentSessionTotals",
                    },

                    {
                        "uuid": v4(),
                        "position": 9,
                        "label": "Movimentos Dia",
                        "backgroundColor": "#0854a1",
                        "textColor": "#FFFFFF",
                        "fn": "fnShowOnlineMovements",
                    },
                    {
                        "uuid": v4(),
                        "position": 15,
                        "label": "Reset",
                        "backgroundColor": "#ff9214",
                        "textColor": "#FFFFFF"
                    }
                ],
                meta: {}
            })
        },

        moveButtonFromPage(state: any, params: SetButtonPositionParams){

                let pages = state.getters.pages
                let oldPosition = 0
                let buttonMoving = {} as POSButton


                pages.forEach((page: POSPage, index: number) => {
                    if (page.id === params.pageNumber) {
                        page.buttons = page.buttons.map((button: POSButton, buttonIndex: number) => {
                            if (button && button.uuid === params.buttonUUID) {

                                /* button start position */
                                oldPosition = button.position

                                /* set new position*/
                                button.position = params.newPosition

                                /* store it on temp var */
                                buttonMoving = button

                                for(let page of pages){
                                    if (params.newPage !== null && params.newPage  === page.id) {

                                        /* insert moving button on the new position */
                                        page.buttons[params.newPosition] = buttonMoving

                                        // save pages
                                      state.commit('pages', pages)

                                    }
                                }
                            }

                            return button
                        })

                            delete page.buttons[oldPosition]

                    }
                })

        },

       async moveChildFromFamily(state:any, params:{oldFamilies, newFamilies, newFamilyId, newFather, oldFatherPage}){
            //get data to move child
            let maxPosition=0
            let pageNumber = 0
            pageNumber = params.oldFatherPage.id

            //search the page off father family
               for (let findPage of params.oldFamilies){
                if(findPage.meta.family_id == params.newFather){
                    //count buttons
                    for (let button of findPage.buttons){

                        // Determine the max position
                        maxPosition = findPage.buttons.reduce((max, button) => {
                            return button && button.position > max ? button.position : max;
                        }, 0)
                        if(maxPosition > 0)
                            maxPosition = maxPosition + 1
                        else
                            maxPosition = 0
                    }

                    for (let page of params.oldFamilies){
                        if(page.meta.family_id == params.newFamilyId){

                        }
                    }

                        //Move button
                    await state.dispatch('moveButtonFromPage', {
                        pageNumber: pageNumber,
                        buttonUUID: params.newFamilyId.uuid,
                        newPosition: maxPosition,
                        newPage: findPage.id,
                    })

                }
               }


        },

        checkMovingFamilies (state: any, newFamilies){
            // see if any family move to another family
            let oldFamilies = JSON.parse(JSON.stringify(state.getters.pages))

            for (let newFamily of newFamilies){

                // run through pages
                for (let i=0; i< oldFamilies.length; i++) {

                    //get same family in new families and the families in pos pages
                    if( Number(newFamily.id_familia) === oldFamilies[i].meta.family_id){

                        let childFamiliesPages = oldFamilies[i].buttons

                        let oldChild = []
                        let newChild = []
                        let oldChildId = []
                        let newFather = 0
                        let oldFatherPage = oldFamilies[i]

                        //get child families
                        for (let j=0; j<childFamiliesPages.length; j++){

                            //buttons can be items or families, get child families pages
                            if(childFamiliesPages[j] && childFamiliesPages[j].fn === "fnPage"){
                                oldChild.push({
                                    page: childFamiliesPages[j].params,
                                    uuid: childFamiliesPages[j].uuid
                                })
                            }
                        }

                        for (let k=0; k<newFamilies.length; k++){
                            //get child from family of new families
                            if(newFamilies[k].familia_pai === newFamily.id_familia){
                                newChild.push(Number(newFamilies[k].id_familia))
                            }
                        }
                        //run child families pages
                        for (let m=0; m < oldChild.length; m++){

                            //run through pages on POS
                            for (let page of oldFamilies){
                                // see the ID child families from child families pages
                                if(page.id === oldChild[m].page){
                                    oldChildId.push({
                                        page: oldChild[m].page,
                                        id: page.meta.family_id,
                                        uuid: oldChild[m].uuid,
                                    })
                                }
                            }

                        }

                        // compare the array newChild families and the oldChild families
                        //retire the child who not belong to the family
                        const toTransfer = oldChildId
                            .filter(child => !newChild.includes(child.id))
                            .map(child => ({
                                id: child.id,
                                page: child.page,
                                uuid: child.uuid,
                            }));


                        toTransfer.forEach(family => {
                                //Get father familY from child who was move from family
                                for (newFamily of newFamilies){

                                    if(newFamily.id_familia == family.id) {
                                        newFather = newFamily.familia_pai
                                    }
                                }
                                //move child
                                state.dispatch('moveChildFromFamily', {
                                    oldFamilies,
                                    newFamilies,
                                    newFamilyId: family,
                                    newFather,
                                    oldFatherPage,
                                });
                            });

                    }

                }

            }


        },

        async addNewItemsToPos (state: any) {

            // get all items
            await state.dispatch('fetchItems')

            //families when add get the child families and there items automaticly, verify if have new items from families in POS
            let items = JSON.parse(JSON.stringify(state.getters.items))
            let oldItems = JSON.parse(JSON.stringify(state.getters.pages))
            let diffItems = []

            // take out the null buttons
            for (let family of oldItems) {

                for (let page of family.buttons){
                    if(page !== null)
                        diffItems.push(JSON.parse(JSON.stringify(page)))

                }

            }

            //filter new items
            let newItems = items.filter(item =>
                !diffItems.some(diffItem => diffItem.params === item.cod_barras)
            )

            //for each newItem get the family page
            for (let i = 0; i < newItems.length; i++) {


                for (let item of oldItems){

                    //when page of family is found add item
                    if(newItems[i] && newItems[i].id_familia !== undefined && item.meta.family_id === Number(newItems[i].id_familia) ){

                        // Page of family
                        let pageItem = Number(item.id)

                        let maxPosition = 0;

                        if(item.buttons.length>0){
                            //get the max position

                            for (let button of item.buttons) {
                                // verify if the button is null, happens when deleted!!
                                if (!button) {
                                    continue;
                                }
                                if (button.position > maxPosition) {
                                    maxPosition = button.position;
                                }

                            }
                            //Get max position of button on page, allways add button in last
                            maxPosition++

                        }
                        else{
                            maxPosition = 0
                        }


                        // add item button
                         state.dispatch('addButtonToPage', {
                            pageNumber: pageItem,
                            button: {
                                uuid: v4(),
                                position: maxPosition,
                                label: `${newItems[i].nome}`,
                                backgroundColor: state.getters.buttonDefaultItemsBackground,
                                textColor: state.getters.buttonDefaultItemsTextColor,
                                imagem: newItems[i].hash_id_imagem,
                                imageURL: null,
                                fn: 'fnAddItem',
                                params: newItems[i].cod_barras,
                                system: false,
                                custom: false,
                            }
                        })



                    }
                }

            }





        },

        async  addNewFamiliesToPos(state: any) {


          state.dispatch('showStatusMessage', `<div class="text-xl font-light">Atualizar artigos...</div>`)

            let newFamilies = []
            do {

            // get all families
            await state.dispatch('fetchFamilies')


            // attribute the families and the items to variables
            let families = JSON.parse(JSON.stringify(state.getters.families))
            let oldItems = JSON.parse(JSON.stringify(state.getters.pages))
            let diffItems = []

            // verify if any child family move between families
            await state.dispatch('checkMovingFamilies', families )

            // take out the null buttons
            for (let family of oldItems) {

                for (let page of family.buttons){
                    if(page !== null)
                    diffItems.push(JSON.parse(JSON.stringify(page)))
                }

            }

            // filter new families
                newFamilies = families.filter(family =>
                !oldItems.some(oldItems => oldItems.meta.family_id == family.id_familia )
            )


                // Process each family in newFamilies
                for (let i = 0; i < newFamilies.length; i++) {


                    // Match the family with pages
                    for (let page of oldItems) {

                        if (Number(newFamilies[i].familia_pai) === page.meta.family_id) {
                            let pageFamily = page.id;

                            // Determine the max position
                            let maxPosition = page.buttons.reduce((max, button) => {
                                return button && button.position > max ? button.position : max;
                            }, 0)

                            if(maxPosition >0 ){
                                maxPosition ++
                            }
                            else{
                                maxPosition = 0
                            }

                            // without generate pages currentPage gets the 0 value
                            state.commit("setGenerationHelpersCurrentPage");

                            await state.dispatch("generatePagesButtonsFromFamily", {
                                familyId: newFamilies[i].id_familia,
                                params: { pageNumber: pageFamily, position: maxPosition, family: newFamilies[i] },
                            });


                        }
                    }

                }
            } while (newFamilies.length > 0)


            // add items
            await state.dispatch('addNewItemsToPos')


            state.dispatch('savePageButtons')
            state.dispatch('savePagesToAPI')

            // close status view
            state.dispatch('closeStatusMessage')


        },


    }
}

export {
    Pages
}