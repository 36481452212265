import { createElementVNode as _createElementVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-gray-500 w-96 rounded shadow p-8" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "flex flex-col h-full w-full p-4 sm:h-screen bg-raisinBlack-200" }
const _hoisted_4 = { class: "flex flex-col sm:flex-row gap-2 h-full sm:h-4/5" }
const _hoisted_5 = { class: "bg-white flex flex-col h-1/3 sm:h-auto sm:w-1/2" }
const _hoisted_6 = { class: "w-full bg-green-500 text-white text-center text-xl h-1/5 sm:h-auto sm:p-4" }
const _hoisted_7 = { class: "flex flex-col-reverse sm:flex-col gap-2 sm:gap-4 mt-2 justify-center text-white h-1/3 sm:h-auto sm:w-1/2" }
const _hoisted_8 = { class: "flex sm:flex-col w-full gap-2" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "pt-2 pb-2 sm:pt-6 sm:pb-0 flex flex-col justify-center gap-2" }
const _hoisted_12 = ["max", "disabled"]
const _hoisted_13 = { class: "flex flex-row justify-evenly gap-2" }
const _hoisted_14 = ["onClick", "disabled"]
const _hoisted_15 = { class: "flex flex-row-reverse sm:flex-col w-full gap-2" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 0,
  class: "mt-2 sm:hidden relative h-1/3 bg-white"
}
const _hoisted_18 = { class: "flex h-5/6" }
const _hoisted_19 = { class: "grid grid-rows-2 w-2/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumericKeyboard = _resolveComponent("NumericKeyboard")!
  const _component_SelectList = _resolveComponent("SelectList")!
  const _component_OperationsSectionDetails = _resolveComponent("OperationsSectionDetails")!
  const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.showModal ? '' : 'hidden', "absolute w-screen h-screen flex justify-center items-center bg-black bg-opacity-30 z-10"])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-white font-semibold text-xl" }, " QUANTIDADE ", -1)),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("input", {
            onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args)), ["enter"])),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nItems) = $event)),
            type: "number",
            class: "appearance-none w-full text-center text-lg p-2"
          }, null, 544), [
            [_vModelText, _ctx.nItems]
          ]),
          _createVNode(_component_NumericKeyboard, {
            class: "mt-1",
            onClicked: _ctx.numpadClicked
          }, null, 8, ["onClicked"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args))),
            class: "w-full h-16 bg-green-400 hover:bg-green-500 rounded text-white"
          }, "OK ")
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "text-white text-center m-4 hidden sm:flex" }, [
        _createElementVNode("p", { class: "text-4xl uppercase" }, "Dividir conta")
      ], -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "bg-raisinBlack-700 text-white text-center text-xl h-1/5 sm:h-auto sm:p-2" }, "Conta atual", -1)),
          _createVNode(_component_SelectList, {
            onClicked: _ctx.selectItem,
            List: _ctx.formattedItemsList,
            maxListSize: _ctx.isPortrait ? 2 : 0,
            label: 'formatted',
            selectedItem: _ctx.selectedItem,
            class: "h-3/5 sm:h-full"
          }, null, 8, ["onClicked", "List", "maxListSize", "selectedItem"]),
          _createElementVNode("div", _hoisted_6, "Total: " + _toDisplayString(_ctx.totalPrice) + "€ ", 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.splitByPeople && _ctx.splitByPeople(...args))),
              class: "transition-colors rounded p-2 sm:p-4 flex-grow bg-gray-600 cursor-default"
            }, " Nº de pessoas "),
            _createElementVNode("button", {
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.restoreSplittedLineFromWorkDocument && _ctx.restoreSplittedLineFromWorkDocument(...args))),
              disabled: !_ctx.isAWorkDocumentItemSelected,
              class: _normalizeClass(["p-2 sm:p-4 flex justify-center items-center rounded w-full h-full", _ctx.isAWorkDocumentItemSelected ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-600 cursor-default'])
            }, _cache[14] || (_cache[14] = [
              _createElementVNode("div", { class: "transform rotate-90 sm:rotate-0" }, [
                _createElementVNode("i", { class: "gg-chevron-double-left" })
              ], -1)
            ]), 10, _hoisted_9),
            _createElementVNode("button", {
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.restoreNItemsFromWorkDocument && _ctx.restoreNItemsFromWorkDocument(...args))),
              disabled: !_ctx.isAWorkDocumentItemSelected,
              class: _normalizeClass(["p-2 sm:p-4 flex justify-center items-center rounded w-full h-full", _ctx.isAWorkDocumentItemSelected ? 'bg-red-400 hover:bg-red-500' : 'bg-gray-400 cursor-default'])
            }, _cache[15] || (_cache[15] = [
              _createElementVNode("div", { class: "transform rotate-90 sm:rotate-0" }, [
                _createElementVNode("i", { class: "gg-chevron-left" })
              ], -1)
            ]), 10, _hoisted_10)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              type: "number",
              min: "1",
              max: _ctx.selectedItem.quantity === 0 ? 1 : _ctx.selectedItem.quantity,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.nItems) = $event)),
              placeholder: "Nº de items",
              disabled: !_ctx.isAnItemSelected && !_ctx.isAWorkDocumentItemSelected,
              class: _normalizeClass(["rounded p-2 text-black text-center text-lg sm:text-3xl font-semibold", _ctx.isAnItemSelected || _ctx.isAWorkDocumentItemSelected ? '' : 'bg-gray-300 cursor-default']),
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isAnItemSelected || _ctx.isAWorkDocumentItemSelected ? _ctx.toggleModal() : ''))
            }, null, 10, _hoisted_12), [
              [_vModelText, _ctx.nItems]
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberSelection, (number) => {
                return (_openBlock(), _createElementBlock("button", {
                  onClick: ($event: any) => (_ctx.setNItems(number)),
                  disabled: !_ctx.isAnItemSelected && !_ctx.isAWorkDocumentItemSelected,
                  class: _normalizeClass(["transition-colors rounded p-2 sm:p-4 flex-grow", _ctx.isAnItemSelected || _ctx.isAWorkDocumentItemSelected ? 'bg-cyan-600 hover:bg-cyan-700' : 'bg-gray-600 cursor-default'])
                }, _toDisplayString(number), 11, _hoisted_14))
              }), 256))
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("button", {
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.splitEntireLine && _ctx.splitEntireLine(...args))),
              disabled: !_ctx.isAnItemSelected,
              class: _normalizeClass(["p-2 sm:p-4 transition-colors flex justify-center items-center rounded w-full h-full", _ctx.isAnItemSelected ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600 cursor-default'])
            }, _cache[16] || (_cache[16] = [
              _createElementVNode("div", { class: "transform rotate-90 sm:rotate-0" }, [
                _createElementVNode("i", { class: "gg-chevron-double-right" })
              ], -1)
            ]), 10, _hoisted_16)
          ]),
          _createElementVNode("button", {
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.cancelSplit && _ctx.cancelSplit(...args))),
            class: "hidden rounded sm:flex justify-center items-center gap-2 uppercase bg-orange-500 hover:bg-orange-600 py-6"
          }, _cache[17] || (_cache[17] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              class: "h-6 w-6",
              fill: "none",
              viewBox: "0 0 24 24",
              stroke: "currentColor",
              "stroke-width": "2"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                d: "M6 18L18 6M6 6l12 12"
              })
            ], -1),
            _createTextVNode(" Cancelar ")
          ]))
        ]),
        (_ctx.isPortrait)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "bg-raisinBlack-700 text-white text-center text-xl h-1/6 flex justify-center items-center" }, " Conta Final ", -1)),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_OperationsSectionDetails, { maxListSize: 2 }),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("button", {
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.decrementCurrentItemsListIndex && _ctx.decrementCurrentItemsListIndex(...args))),
                    class: _normalizeClass(["flex justify-center items-center bg-gray-400 text-white", [_ctx.showPreviousItemButton ? 'hover:bg-gray-500 ' : 'opacity-60 cursor-not-allowed']])
                  }, [
                    _createVNode(_component_ChevronUpIcon, { class: "w-full" })
                  ], 2),
                  _createElementVNode("button", {
                    onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.incrementCurrentItemsListIndex && _ctx.incrementCurrentItemsListIndex(...args))),
                    class: _normalizeClass(["flex justify-center items-center bg-gray-400 text-white", [_ctx.showNextItemButton ? 'hover:bg-gray-500 ' : 'opacity-60 cursor-not-allowed']])
                  }, [
                    _createVNode(_component_ChevronDownIcon, { class: "w-full" })
                  ], 2)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}