import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full w-full p-4 sm:h-screen bg-raisinBlack-200" }
const _hoisted_2 = { class: "text-white text-center m-4 hidden sm:flex" }
const _hoisted_3 = { class: "text-4xl uppercase" }
const _hoisted_4 = { class: "flex flex-col sm:flex-row gap-2 h-full sm:h-4/5" }
const _hoisted_5 = { class: "bg-white flex flex-col h-1/3 sm:h-full sm:w-1/2" }
const _hoisted_6 = { class: "flex flex-row w-full h-3/5 flex-1 relative" }
const _hoisted_7 = { class: "flex flex-col w-1/6 overflow-y-scroll overflow-hidden relative" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex flex-row w-full" }
const _hoisted_10 = { class: "flex-1" }
const _hoisted_11 = { class: "h-full" }
const _hoisted_12 = {
  key: 0,
  class: "bg-green-500 text-white text-center text-xl h-1/5 sm:h-auto sm:p-4"
}
const _hoisted_13 = { class: "flex flex-col-reverse sm:flex-col gap-2 sm:gap-4 mt-2 justify-center text-white h-1/3 sm:h-auto sm:w-1/2" }
const _hoisted_14 = { class: "flex sm:flex-col w-full gap-2" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "flex flex-row-reverse sm:flex-col w-full gap-2" }
const _hoisted_18 = {
  key: 0,
  class: "mt-2 sm:hidden relative h-1/3 bg-white"
}
const _hoisted_19 = { class: "flex h-5/6" }
const _hoisted_20 = { class: "grid grid-rows-2 w-2/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectList = _resolveComponent("SelectList")!
  const _component_OperationsSectionDetails = _resolveComponent("OperationsSectionDetails")!
  const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, "Dividir por " + _toDisplayString(_ctx.numDivided), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex flex-row bg-raisinBlack-700 text-white text-center text-xl h-1/5 sm:h-auto sm:p-2" }, " Conta Dividida ", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numDivided, (index) => {
              return (_openBlock(), _createElementBlock("button", {
                key: index,
                class: _normalizeClass(["p-2 border-2 h-full mr-1 relative origin-right", [
                _ctx.activeList === index ? 'bg-gray-100 transform scale-110 z-10 ' :
                _ctx.getListDividedPerson[index - 1].items.length === 0 ? 'bg-green-500' : 'bg-red-600'
                ]]),
                onClick: ($event: any) => (_ctx.toggleList(index))
              }, [
                _createElementVNode("h2", null, _toDisplayString(index), 1)
              ], 10, _hoisted_8))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_SelectList, {
                  onClicked: _ctx.selectItem,
                  List: _ctx.formattedItemsList,
                  maxListSize: _ctx.isPortrait ? 2 : 0,
                  label: 'formatted',
                  selectedItem: _ctx.selectedItem,
                  class: "sm:h-full"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.activeList), 1)
                  ]),
                  _: 1
                }, 8, ["onClicked", "List", "maxListSize", "selectedItem"])
              ])
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getListDividedPerson, (index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index.person,
            class: "w-full"
          }, [
            (_ctx.activeList === index.person + 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, " Total: " + _toDisplayString(_ctx.totalPrice) + "€ ", 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.personRestoreSplittedLineFromWorkDocument && _ctx.personRestoreSplittedLineFromWorkDocument(...args))),
            disabled: !_ctx.isAWorkDocumentItemSelected,
            class: _normalizeClass(["p-2 sm:p-4 flex justify-center items-center rounded w-full h-full", _ctx.isAWorkDocumentItemSelected ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-600 cursor-default'])
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("div", { class: "transform rotate-90 sm:rotate-0" }, [
              _createElementVNode("i", { class: "gg-chevron-double-left" })
            ], -1)
          ]), 10, _hoisted_15),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.personRestoreItemsFromWorkDocument && _ctx.personRestoreItemsFromWorkDocument(...args))),
            disabled: !_ctx.isAWorkDocumentItemSelected,
            class: _normalizeClass(["p-2 sm:p-4 flex justify-center items-center rounded w-full h-full", _ctx.isAWorkDocumentItemSelected ? 'bg-red-400 hover:bg-red-500' : 'bg-gray-400 cursor-default'])
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("div", { class: "transform rotate-90 sm:rotate-0" }, [
              _createElementVNode("i", { class: "gg-chevron-left" })
            ], -1)
          ]), 10, _hoisted_16)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.transfer())),
            class: "p-2 sm:p-4 flex justify-center items-center rounded w-full h-full bg-gray-600"
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("div", { class: "transform rotate-90 sm:rotate-0" }, [
              _createElementVNode("i", { class: "gg-chevron-double-right" })
            ], -1)
          ]))
        ]),
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelSplit && _ctx.cancelSplit(...args))),
          class: "hidden rounded sm:flex justify-center items-center gap-2 uppercase bg-orange-500 hover:bg-orange-600 py-6"
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            class: "h-6 w-6",
            fill: "none",
            viewBox: "0 0 24 24",
            stroke: "currentColor",
            "stroke-width": "2"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M6 18L18 6M6 6l12 12"
            })
          ], -1),
          _createTextVNode(" Cancelar ")
        ])),
        _createElementVNode("button", {
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args))),
          class: "hidden rounded sm:flex justify-center items-center gap-2 uppercase bg-orange-500 hover:bg-orange-600 py-6"
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            height: "20",
            width: "20",
            viewBox: "0 0 512 512"
          }, [
            _createElementVNode("path", {
              fill: "#ffffff",
              d: "M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"
            })
          ], -1),
          _createTextVNode(" Reiniciar ")
        ]))
      ]),
      (_ctx.isPortrait)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "bg-raisinBlack-700 text-white text-center text-xl h-1/6 flex justify-center items-center" }, " Conta Final ", -1)),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_OperationsSectionDetails, { maxListSize: 2 }),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("button", {
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.decrementCurrentItemsListIndex && _ctx.decrementCurrentItemsListIndex(...args))),
                  class: _normalizeClass(["flex justify-center items-center bg-gray-400 text-white", [_ctx.showPreviousItemButton ? 'hover:bg-gray-500 ' : 'opacity-60 cursor-not-allowed']])
                }, [
                  _createVNode(_component_ChevronUpIcon, { class: "w-full" })
                ], 2),
                _createElementVNode("button", {
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.incrementCurrentItemsListIndex && _ctx.incrementCurrentItemsListIndex(...args))),
                  class: _normalizeClass(["flex justify-center items-center bg-gray-400 text-white", [_ctx.showNextItemButton ? 'hover:bg-gray-500 ' : 'opacity-60 cursor-not-allowed']])
                }, [
                  _createVNode(_component_ChevronDownIcon, { class: "w-full" })
                ], 2)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}