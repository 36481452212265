
import {defineComponent} from "vue";
import {mapGetters, mapMutations, mapActions} from "vuex";

export default defineComponent({
  name: 'OperationsSectionDetailsRow',
  components: {},
  props: ['rowData'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getWorkDocumentSelectedItem', 'workDocumentTypeData', 'isPortrait', 'goldylocksConfig']),
    isAssociatedItem() {
      return this.rowData.parentUUID
    },
    hasAssociatedItems() {
      return this.rowData.hasAssociatedItems
    },
    isSelected() {
      if (this.getWorkDocumentSelectedItem) {
        return this.getWorkDocumentSelectedItem.uuid === this.rowData.uuid
      }

      return false
    },
    hasFeatures() {
      return (this.rowData && this.rowData.features?.length > 0)
    },
    hasPickup() {
      return this.rowData.pickupDate && this.rowData.pickupDate !== ''
    },
    lineTotal(): string {
      return (this.workDocumentTypeData && this.workDocumentTypeData['imposto_incluido'] === "0") ? this.rowData.total_without_tax : this.rowData.total
    },
    unitPrice(): string {
      let unitPrice = (this.workDocumentTypeData && this.workDocumentTypeData['imposto_incluido'] === "0") ? this.rowData.price_without_tax : this.rowData.price

      return parseFloat(unitPrice).toFixed(parseInt(this.goldylocksConfig.casas_decimais))
    }
  },
  methods: {
    ...mapMutations(['setWorkdocumentSelectedRow']),
    ...mapActions(['fnRemoveSelectedItem']),
    setAsSelectedRow() {
      this.setWorkdocumentSelectedRow(this.rowData)
    }
  },
  mounted() {

  }
})
