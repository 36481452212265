const Electron = {
    state: {},
    actions: {
        electronAppQuit() {
            window.close()
        }
    },
}

export {
    Electron,
}
