import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "h-screen w-full grid grid-rows-2 bg-white" }
const _hoisted_2 = { class: "flex flex-col justify-end items-center" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex flex-col w-full items-center" }
const _hoisted_5 = {
  key: 0,
  class: "mt-2 mb-2"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { innerHTML: _ctx.statusMessage }, null, 8, _hoisted_3),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "py-2" }, [
        _createElementVNode("i", { class: "gg-loadbar-alt text-blue-600" })
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.statusTimeout)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeStatusMessage && _ctx.closeStatusMessage(...args))),
              class: "bg-yellow-500 hover:bg-yellow-600 text-xs px-4 py-2 text-white"
            }, "Fechar esta janela")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStatusLogMessages, (message, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "text-xs",
              key: index,
              innerHTML: message
            }, null, 8, _hoisted_6))
          }), 128))
        ])
      ])
    ])
  ]))
}