import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen w-full bg-raisinBlack-600 flex items-center justify-center" }
const _hoisted_2 = { class: "flex flex-col items-center justify-center" }
const _hoisted_3 = { class: "py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"flex justify-center text-orange-400\"><svg xmlns=\"http://www.w3.org/2000/svg\" class=\"h-24 w-24\" fill=\"none\" viewBox=\"0 0 24 24\" stroke=\"currentColor\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z\"></path></svg></div><div class=\"text-white font-semibold text-2xl\">Sem acesso à internet!</div><div class=\"text-white font-thin\">Verifique a sua ligação!</div>", 3)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args))),
          class: "px-2 py-2 bg-indigo-500 hover:bg-indigo-600 text-white flex items-center gap-1 text-xs"
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("span", null, [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              class: "h-4 w-4",
              fill: "none",
              viewBox: "0 0 24 24",
              stroke: "currentColor"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
                d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              })
            ])
          ], -1),
          _createElementVNode("span", null, "Verificar novamente", -1)
        ]))
      ])
    ])
  ]))
}