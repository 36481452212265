const fnHardware = {
    actions: {
        fnOpenDrawer(state: any) {
            if (state.getters.getDefaultSessionAgent) {
                state.dispatch('openDrawer', state.getters.getDefaultSessionAgent)
            }
        }
    }
}

export {
    fnHardware
}
