import {swal} from "@/libs/POSSwal";
import POSnotyf from "@/libs/POSnotyf";

const fnWorkingDocument = {
    state: {},
    actions: {
        createInvoiceFromWorkDocument(state: any) {
            return new Promise<void>(async (resolve, reject) => {

                let validSession = true

                // check if user has active session
                if (state.getters.userUsesSession) {
                    try {
                        let sessionData = await state.dispatch('fetchSessionData')

                        // if no session is active redirects the user to the open session view
                        if (sessionData.length === 0) {
                            validSession = false
                            state.dispatch('openUserSessionStartView')
                            resolve()
                        }
                    } catch (err) {
                        console.error(err)
                        reject(err)
                    }
                }


                if (validSession) {
                    state.commit('setInvoiceViewState', true)

                    // open status message
                    state.dispatch('showStatusMessage', 'A preparar documento...')

                    // set invoice document type
                    state.commit('invoiceDocTypeId', state.getters.workDocumentTypeId)

                    // set invoice series
                    state.commit('invoiceDocSeries', state.getters.workDocumentSeries)

                    // set invoice customer data
                    state.dispatch('setInvoiceCustomerFromSelectedCustomer')

                    // set invoice items as the working document items
                    state.commit('invoiceItems', state.getters.workDocumentItems)

                    // trigger Goldylocks document creation
                    try {
                        await state.dispatch('createGoldylocksInvoice')

                        // close status message
                        state.dispatch('closeStatusMessage')

                        // open method selection view
                        state.dispatch('fnOpenView', 'PaymentMethodSelection')

                        // resolve promise
                        resolve()
                    } catch (e) {
                        /* show document creation error */
                        await swal.error(`<div class="text-2xl font-semibold">Atenção!</div><br>Não é possivel terminar o documento!<br><div class="text-sm">Verifique as definições do seu Goldylocks.</div>`)

                        // close status  and open items view
                        state.dispatch('closeStatusMessage')


                    }
                }

            })
        },
        fnSetDocumentType(state: any, documentTypeId: number) {
            if (documentTypeId) {
                state.commit('setTypeId', documentTypeId)
            } else {
                state.dispatch('fnOpenModal', 'ModalDocumentType')
            }
        },
        fnSelectDocumentSeries(state: any, serie: string) {
            if (serie) {
                state.commit('setSeries', serie)
            } else {
                state.dispatch('fnOpenModal', 'ModalDocumentSerie')
            }
        },
        fnSelectPriceLine(state: any, priceLine: number) {
            if (priceLine) {
                state.commit('activePriceLine', priceLine)
                POSnotyf.success(`Ativada linha de preço <strong>${priceLine}</strong>.`)
            } else {
                state.dispatch('fnOpenModal', 'ModalPriceLine')
            }
        },
        fnCreateInvoiceFromWorkDocument(state: any) {
            return new Promise<void>(async (resolve, reject) => {
                if (state.getters.workDocumentHasItems) {
                    try {
                        await state.dispatch('createInvoiceFromWorkDocument')
                        resolve()
                    } catch (err) {
                        reject(err)
                    }
                } else {
                    reject('no_work_document_items')
                }
            })
        },
        fnRemoveSelectedItem(state: any) {
            if (state.getters.getWorkDocumentSelectedItem) {
                state.dispatch('removeItemByData', state.getters.getWorkDocumentSelectedItem)

                state.commit('clearWorkDocumentSelectedRow')
            }
        },
        fnOpenSplitBillView(state: any) {
            if (state.getters.workDocumentHasItems) {
                state.dispatch('fnOpenView', 'SplitBill')
            }
        },
        fnOpenDocumentObservations(state: any) {
            state.dispatch('fnOpenView', 'InsertObservation')
        }
    },
}

export {
    fnWorkingDocument
}