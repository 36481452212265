
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectList from "@/components/Lists/SelectList.vue";

let currentDate = new Date()

export default defineComponent({
  name: 'UserClosedSessionsView',
  components: {
    SelectList,
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      closedUserSessions: [],
      selectedSession: [] as any,
    }
  },
  computed: {},
  methods: {
    ...mapMutations(['addToPrintQueue']),
    ...mapActions(['fetchUserClosedSessions', 'fnOpenView']),
    async searchUserClosedSessions() {
      this.closedUserSessions = await this.fetchUserClosedSessions({
        startDate: this.startDate,
        endDate: this.endDate,
      })
    },
    selected(selectedSession: any) {
      this.selectedSession = selectedSession
    },
    print() {
      if (this.selectedSession) {

        let sessionData = this.selectedSession

        // add session close to print queue
        this.addToPrintQueue({
          documentType: 'fecho',
          invoiceID: sessionData.id_sessao,
          logout: false,
        })

        // open printer selection view
        this.fnOpenView('PrinterSelection')
      }
    }
  },
})
