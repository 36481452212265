import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "h-full relative flex flex-col total-section",
  ref: "footer"
}
const _hoisted_2 = { class: "grid grid-cols-2 h-1/6" }
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(['flex-1 flex flex-col justify-center '])
}
const _hoisted_4 = {
  key: 0,
  class: "px-2 bg-green-500 text-xs text-white"
}
const _hoisted_5 = { class: "text-white text-xl" }
const _hoisted_6 = { class: "flex flex-row flex-1" }
const _hoisted_7 = { class: "text-center flex flex-row justify-center text-3xl lg:text-4xl items-center" }
const _hoisted_8 = { class: "h-1/5 grid grid-cols-2 w-full total-section z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentTypeLabel = _resolveComponent("DocumentTypeLabel")!
  const _component_DocumentSerialLabel = _resolveComponent("DocumentSerialLabel")!
  const _component_OperationsFastMenu = _resolveComponent("OperationsFastMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrementCurrentItemsListIndex && _ctx.decrementCurrentItemsListIndex(...args))),
        class: _normalizeClass([[_ctx.showPreviousItemButton ? 'hover:bg-gray-200 text-green-500' : 'opacity-60 cursor-not-allowed text-yellow-200'], "flex justify-center items-center"])
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("svg", {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            d: "M17.6569 16.2427L19.0711 14.8285L12.0001 7.75739L4.92896 14.8285L6.34317 16.2427L12.0001 10.5858L17.6569 16.2427Z",
            fill: "currentColor"
          })
        ], -1)
      ]), 2),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.incrementCurrentItemsListIndex && _ctx.incrementCurrentItemsListIndex(...args))),
        class: _normalizeClass([[_ctx.showNextItemButton ? 'hover:bg-gray-200 text-green-500' : 'opacity-60 cursor-not-allowed text-yellow-200'], "flex justify-center items-center"])
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("svg", {
          width: "24",
          height: "24",
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            d: "M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z",
            fill: "currentColor"
          })
        ], -1)
      ]), 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-row text-white font-medium px-2 h-auto transition-all duration-500 ease-in-out", [{ 'text-xs': _ctx.workDocumentTotal }, { 'text-xs xl:text-lg': !_ctx.workDocumentTotal }]])
      }, [
        (_ctx.workDocumentTypeName)
          ? (_openBlock(), _createBlock(_component_DocumentTypeLabel, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(" Sem fatura ")
            ], 64)),
        _cache[8] || (_cache[8] = _createElementVNode("span", null, " - ", -1)),
        (_ctx.workDocumentSeries)
          ? (_openBlock(), _createBlock(_component_DocumentSerialLabel, { key: 2 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createTextVNode(" Sem série ")
            ], 64))
      ], 2),
      (!_ctx.isDefaultPriceLine)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[9] || (_cache[9] = _createTextVNode(" Linha de preço ")),
            _createElementVNode("strong", _hoisted_5, _toDisplayString(_ctx.activePriceLineDescription), 1),
            _cache[10] || (_cache[10] = _createTextVNode(" ativa "))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["w-full flex justify-center font-semibold text-total text-white", [_ctx.workDocumentTotal ? `flex-col` : `flex-row items-center px-5`]])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.workDocumentTotal ? `text-lg px-5` : `text-md `, `transition-all duration-500 ease-in-out transform`])
          }, " Total: ", 2),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.totalShown.toFixed(2)) + " € ", 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["cursor-pointer flex flex-col overflow-hidden h-full items-center justify-center text-white transition-all duration-500 ease-in-out transform", [_ctx.isTableSelected && _ctx.activeView === 'Items' ? 'bg-green-600 hover:bg-green-500 w-1/5' : 'w-0']]),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.saveTable && _ctx.saveTable(...args)))
        }, _cache[11] || (_cache[11] = [
          _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"h-8\" data-v-7c62b595><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3\" data-v-7c62b595></path></svg><svg class=\"w-8\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\" fill=\"#ffffff\" data-v-7c62b595><g id=\"SVGRepo_bgCarrier\" stroke-width=\"0\" data-v-7c62b595></g><g id=\"SVGRepo_tracerCarrier\" stroke-linecap=\"round\" stroke-linejoin=\"round\" data-v-7c62b595></g><g id=\"SVGRepo_iconCarrier\" data-v-7c62b595><title data-v-7c62b595>table</title><path d=\"M18.76,6l2,4H3.24l2-4H18.76M20,4H4L1,10v2H3v7H5V16H19v3h2V12h2V10L20,4ZM5,14V12H19v2Z\" data-v-7c62b595></path><rect width=\"24\" height=\"24\" fill=\"none\" data-v-7c62b595></rect></g></svg>", 2)
        ]), 2),
        _createElementVNode("div", {
          class: _normalizeClass(["cursor-pointer flex flex-col overflow-hidden h-full items-center justify-center text-white transition-all duration-500 ease-in-out transform", [_ctx.workDocumentTotal && (!_ctx.isTableSelected || (_ctx.isTableSelected &&  _ctx.activeView === 'SplitByPeople'  || _ctx.activeView === 'SplitBill'  ) ) ? 'bg-green-600 hover:bg-green-500 md:w-1/4 lg:w-1/3 xl:w-1/3' : 'w-0']]),
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.createInvoice && _ctx.createInvoice(...args)))
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 24 24",
            "stroke-width": "1.5",
            stroke: "currentColor",
            class: "h-12"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            })
          ], -1)
        ]), 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleFastMenu && _ctx.toggleFastMenu(...args))),
        class: "flex flex-col justify-center items-center text-white hover:bg-gray-700"
      }, _cache[13] || (_cache[13] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          class: "h-6 w-6",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
          })
        ], -1)
      ])),
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.openSettings && _ctx.openSettings(...args))),
        class: "flex flex-col justify-center items-center text-white hover:bg-gray-700"
      }, _cache[14] || (_cache[14] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          class: "h-6 w-6",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          }),
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          })
        ], -1)
      ]))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute right-0 transition-all transform duration-200 ease-in-out z-0 w-full", [_ctx.fastMenuState ? 'bottom-10' : '-bottom-100']])
    }, [
      _createVNode(_component_OperationsFastMenu, { onClose: _ctx.toggleFastMenu }, null, 8, ["onClose"])
    ], 2)
  ], 512))
}