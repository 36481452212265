
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {SearchIcon, ChevronDownIcon, ChevronUpIcon, XIcon} from "@heroicons/vue/outline";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import ModalClientSearchResultItem from "@/components/Modals/ModalClientSearchResultItem.vue";
import LoadingBar from "@/components/LoadingBar.vue";

export default defineComponent({
  name: "ModalClientSearch",
  data() {
    return {
      index: 0,
      end: 1,
    }
  },
  components: {
    LoadingBar,
    ModalClientSearchResultItem,
    SearchIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    KeyboardInput,
    XIcon
  },
  computed: {
    ...mapGetters(['customerSearchString', 'customersSearchResults', 'getSearchingCustomerStatus']),
    searchString: {
      get(): string {
        return this.customerSearchString
      },
      set(value: string) {
        this.setCustomerSearchString(value)
      }
    },
  },
  methods: {
    ...mapMutations(['clearSelectedCustomerData']),
    searchInputEnterPressed() {
      this.search()
    },
    maximumGridRows() {
      let rowSize = (Math.floor(window.innerHeight / 150))
      return rowSize < 1 ? 1 : rowSize
    },
    reset() {
      this.index = 0
      this.end = this.maximumGridRows()
    },
    moveUp() {
      if (this.index > 0) {
        this.index--
        this.end--
      }
    },
    moveDown() {
      if (this.end < this.customersSearchResults.length) {
        this.index++
        this.end++
      }
    },
    ...mapMutations(['setCustomerSearchString']),
    ...mapActions(['closeActiveModal', 'searchCustomers','openModal']),
    search() {
      this.reset()
      this.searchCustomers()
    },
    setFinalConsumer() {
      this.clearSelectedCustomerData()
      this.closeActiveModal()
    },
    openCreateCustomerModal() {
      this.openModal('ModalNewCustomer')
    }
  },
  mounted(){
    this.search()

    this.$nextTick(() => {
        window.addEventListener('resize', this.reset);
    })
  },
  beforeDestroy() { 
      window.removeEventListener('resize', this.reset); 
  },
  watch: {
    searchString(newString, oldString){
      if(newString === "" && newString !== oldString) this.search()
    }
  },
})
