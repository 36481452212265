import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col h-full w-full gap-1 lg:gap-4" }
const _hoisted_2 = {
  key: 0,
  class: "grid grid-cols-2 gap-2 mt-1 lg:mt-4 h-1/2"
}
const _hoisted_3 = { class: "flex flex-col px-2 py-0 lg:py-1" }
const _hoisted_4 = { class: "grid grid-cols-2 h-full" }
const _hoisted_5 = { class: "flex flex-col px-2 py-0 lg:py-1" }
const _hoisted_6 = { class: "grid grid-cols-2 h-full" }
const _hoisted_7 = {
  key: 1,
  class: "grid grid-cols-2 h-1/2 mt-1 lg:mt-auto"
}
const _hoisted_8 = {
  key: 2,
  class: "flex flex-col h-full relative text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentEditModeButtonData.position >= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "font-semibold sm:text-xs lg:text-base" }, "Botão", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyButton && _ctx.copyButton(...args))),
                class: "flex items-center justify-center bg-blue-400 text-white hover:bg-blue-500 py-1 text-xs"
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  class: "h-5 w-5 lg:h-6 lg:w-6",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  stroke: "currentColor",
                  "stroke-width": "2"
                }, [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    d: "M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  })
                ], -1)
              ])),
              (Object.keys(_ctx.getCopiedButton ?? {}).length > 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.pasteButton && _ctx.pasteButton(...args))),
                    class: "flex items-center justify-center bg-indigo-400 text-white hover:bg-indigo-600 py-1 text-xs"
                  }, _cache[9] || (_cache[9] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      class: "h-5 w-5 lg:h-6 lg:w-6",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                      "stroke-width": "2"
                    }, [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "font-semibold sm:text-xs lg:text-base" }, "Estilo", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyStyle && _ctx.copyStyle(...args))),
                class: "flex items-center justify-center bg-blue-400 text-white hover:bg-blue-500 py-1 text-xs"
              }, _cache[11] || (_cache[11] = [
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  class: "h-5 w-5 lg:h-6 lg:w-6",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  stroke: "currentColor",
                  "stroke-width": "2"
                }, [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    d: "M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  })
                ], -1)
              ])),
              (Object.keys(_ctx.getCopiedStyle ?? {}).length > 0)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.pasteStyle && _ctx.pasteStyle(...args))),
                    class: "flex items-center justify-center bg-indigo-400 text-white hover:bg-indigo-600 py-1 text-xs"
                  }, _cache[12] || (_cache[12] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      class: "h-5 w-5 lg:h-6 lg:w-6",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                      "stroke-width": "2"
                    }, [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.currentEditModeButtonData.position >= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args))),
            class: "bg-green-500 text-white hover:bg-green-600 px-4 py-2"
          }, "Guardar"),
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args))),
            class: "bg-red-500 text-white hover:bg-red-600 px-4 py-2"
          }, "Eliminar")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "w-full h-full bg-white absolute -top-1" }, null, -1)),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.createNewPage && _ctx.createNewPage(...args))),
            class: _normalizeClass(["z-10 mt-auto overflow-hidden transition-all duration-500", [{'h-1/3':!_ctx.canGoForwardPagination},{'h-0':_ctx.canGoForwardPagination}]])
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("div", { class: "bg-green-500 hover:bg-green-600 flex justify-center items-center gap-2 px-4 py-2 h-full w-full" }, [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                class: "h-6 w-6",
                fill: "none",
                viewBox: "0 0 24 24",
                stroke: "currentColor",
                "stroke-width": "2"
              }, [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  d: "M12 4v16m8-8H4"
                })
              ]),
              _createTextVNode(" Adicionar Página ")
            ], -1)
          ]), 2),
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleEditMode && _ctx.toggleEditMode(...args))),
            class: "h-1/3 z-10 bg-yellow-500 hover:bg-yellow-600 px-4 py-2"
          }, "Fechar")
        ]))
  ]))
}