
import {defineComponent} from "vue";
import {ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon} from "@heroicons/vue/outline";
import SelectList from "@/components/Lists/SelectList.vue";
import OperationsSectionDetails from "@/components/OperationsSectionDetails.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {WorkDocumentItem} from "@/interfaces/WorkDocumentItem";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";
import POSnotyf from "@/libs/POSnotyf"

export default defineComponent({
  name: "MoveTableView",
  data() {
    return {
      componentName: "MoveTableView",
      selectedItem: {description: "", id: "", price: 0, quantity: 0} as WorkDocumentItem,
      isAnItemSelected: false,
      isAWorkDocumentItemSelected: false,
      nItems: "1",
      showModal: false,
      numberSelection: ['1', '5', '10', '50', '100']
    }
  },
  components: {
    SelectList,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    NumericKeyboard,
    OperationsSectionDetails
  },
  computed: {

    ...mapGetters(['getSecondTableData','workDocumentItems', 'getSecondTable', 'fnSave', 'getWorkDocumentSelectedItem', 'getInvoiceViewState', 'isPortrait', 'itemsListHasMoreItems', 'getCurrentItemsListIndex']),

    showNextItemButton(): any {
      return this.itemsListHasMoreItems
    },
    showPreviousItemButton(): any {
      return (this.getCurrentItemsListIndex > 0)
    },
    formattedItemsList(): any {
      return this.getSecondTable.map((item: any) => {

        item['formatted'] = `<span class="text-3xl">${item.quantity}</span> &times; ${item.description}`

        return item
      })
    },
    totalPrice(): string {
      let total = 0

      for (let i in this.formattedItemsList) {
        total += Number(this.formattedItemsList[i].price) * Number(this.formattedItemsList[i].quantity)
      }

      return total.toFixed(2)
    }
  },
  methods: {
    ...mapMutations(['clearItems','clearOriginalItemsTable','setOriginalItemsTable','clearSecondTable','clearSecondTableData','currentTable','clearItems', 'setWorkdocumentSelectedRow', 'clearWorkDocumentSelectedRow', 'setOriginalSplitItems', 'addItem', 'removeLineFromSecondTableItems', 'addLineToSecondTableItems', 'removeWorkDocumentSelectedItem', 'incrementSecondTableItemQuantity', 'decrementSecondTableItemQuantity']),
    ...mapActions(['copyOriginalItemsTableToWorkDocument','copySecondTableItemsToWorkDocument','saveTableItems','openModal','fnOpenView', 'fnRemoveSelectedItem', 'changeSelectedRowQuantity', 'incrementCurrentItemsListIndex', 'decrementCurrentItemsListIndex']),

    selectItem(item: WorkDocumentItem): void {
      this.selectedItem = item
      this.splitNItemsFromLine()
    },
    clearSelectedItem() {
      this.selectedItem = {
        description: "",
        id: "",
        price: 0,
        quantity: 0,
        price_without_tax: 0,
        tax: '',
        tax_rate: 0,
        uuid: "",
        hasAssociatedItems: false
      }
    },
    splitEntireLine() {
      if (this.selectedItem.quantity > 0) {
        this.removeLineFromSecondTableItems(this.selectedItem)
        this.addItem(this.selectedItem)

        this.nItems = "1"
      }
    },
    splitNItemsFromLine() {
      // if there is only 1 remaining copy entire line

      if ((this.selectedItem.quantity - Number(this.nItems)) < 1) {
        this.splitEntireLine()
      } else {
        // set quantity to 1
        let selItem = {...this.selectedItem}
        selItem.quantity = Number(this.nItems)
        this.addItem(selItem)

        this.decrementSecondTableItemQuantity({
          line: this.selectedItem,
          nItems: Number(this.nItems)
        })

      }

      this.nItems = "1"
    },
    restoreSplittedLineFromWorkDocument() {
      if (this.getWorkDocumentSelectedItem.quantity) {
        let _index = this.workDocumentItems.findIndex((item: any) => {
          return item.id == this.getWorkDocumentSelectedItem.id
        })
        let _workDocumentLength = this.workDocumentItems.length

        // Check if the item already exists, if not, create it
        if (this.formattedItemsList.some((item: any) => item.id == this.getWorkDocumentSelectedItem.id))
          this.incrementSecondTableItemQuantity({
            line: this.getWorkDocumentSelectedItem,
            nItems: this.getWorkDocumentSelectedItem.quantity
          })
        else
          this.addLineToSecondTableItems(this.getWorkDocumentSelectedItem)

        this.fnRemoveSelectedItem()
        if (this.workDocumentItems.length > 0) {
          if (_index + 2 > _workDocumentLength)
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index - 1])
          else
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index])
        }

        this.nItems = "1"
      }
    },
    restoreNItemsFromWorkDocument() {
      if (this.getWorkDocumentSelectedItem.quantity > 0) {
        let _index = this.workDocumentItems.findIndex((item: any) => {
          return item.id == this.getWorkDocumentSelectedItem.id
        })
        let _workDocumentLength = this.workDocumentItems.length

        let newQuantity = (this.getWorkDocumentSelectedItem.quantity - Number(this.nItems))

        let newNitems = this.getWorkDocumentSelectedItem.quantity

        if(newQuantity >= 0){
          // change selected work document item row quantity
          this.changeSelectedRowQuantity(this.getWorkDocumentSelectedItem.quantity - Number(this.nItems))
        }
        else{
          this.changeSelectedRowQuantity(this.getWorkDocumentSelectedItem.quantity - this.getWorkDocumentSelectedItem.quantity)
          this.nItems =  newNitems
        }

        // increase split bill list quantity for the item
        this.incrementSecondTableItemQuantity({
          line: this.getWorkDocumentSelectedItem,
          nItems: Number(this.nItems)
        })

        // if only 1 quantity remains remove the line from the work document
        if (this.getWorkDocumentSelectedItem.quantity === 0) {
          this.fnRemoveSelectedItem()
        }

        let _item = this.workDocumentItems.find((item: any) => item.id == this.getWorkDocumentSelectedItem.id)

        // clear selected workdocument item
        this.clearWorkDocumentSelectedRow()

        if (this.workDocumentItems.length > 0) {
          if (_item)
            this.setWorkdocumentSelectedRow(_item)
          else if (_index + 2 > _workDocumentLength)
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index - 1])
          else
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index])
        }

        this.nItems = "1"
      }
    },
    cancelSplit() {
      this.clearSecondTableData()
      this.clearSecondTable()
      this.copyOriginalItemsTableToWorkDocument()
      this.fnOpenView('TableSelection')
    },
    numpadClicked(value: string) {
      if (value === '.' || value == "0") {
        return
      }

      if (value === '<') {
        if (this.nItems.length > 0) {
          // delete last number
          this.nItems = this.nItems.slice(0, -1)
        } else {
          return
        }
      } else {
        this.nItems += value
      }
    },
    toggleModal() {
      this.showModal = !this.showModal

      if (!this.showModal)
        if (Number(this.nItems) < 1 || this.nItems.length == 0)
          this.nItems = "1"
    },
    setNItems(n: string) {
      this.nItems = n
    },

    async save(){
      // save the first table
      await this.saveTableItems()
      // pass the second table data to workDocument
      this.currentTable(this.getSecondTableData)
      // clear the old items
      this.clearItems()
      //copy the new items to workDocument
      await this.copySecondTableItemsToWorkDocument()
      // save table and status
      await this.saveTableItems()
      this.clearSecondTableData()
      this.clearOriginalItemsTable()
      this.fnOpenView('TableSelection')

    },
  },
  watch: {
    // quantity on input
    nItems(newValue) {
      let maxQuantity

      if (this.isAnItemSelected)
        maxQuantity = this.selectedItem.quantity
      else if (this.isAWorkDocumentItemSelected)
        maxQuantity = this.getWorkDocumentSelectedItem.quantity

      if (newValue > maxQuantity) {
        POSnotyf.error("Valor máximo atingido")

        if (maxQuantity > 0)
          this.nItems = String(maxQuantity)
        else
          this.nItems = "1"
      }
    },
    // work on SecondTable
    selectedItem(newItem) {
      this.isAnItemSelected = newItem.id.length != 0

      if (this.selectedItem.id != "") {
        this.clearWorkDocumentSelectedRow()
      }
    },
    getWorkDocumentSelectedItem(newItem) {
      this.isAWorkDocumentItemSelected = Object.keys(newItem).length !== 0

      if (Object.keys(this.getWorkDocumentSelectedItem).length != 0) {
        this.selectedItem = {description: "", id: "", price: 0, quantity: 0} as WorkDocumentItem
      }
    },
    //list from secondTable
    formattedItemsList(newList, oldList) {
      if (newList.length < oldList.length && this.selectedItem.id.length > 0)
        if (newList.length != 0) {
          let _index = oldList.findIndex((item: any) => item.id == this.selectedItem.id)
          if (_index + 1 > newList.length)
            this.selectedItem = newList[_index - 1]
          else
            this.selectedItem = newList[_index]
        } else {
          this.selectedItem = {description: "", id: "", price: 0, quantity: 0} as WorkDocumentItem
        }
    },

  },
  mounted() {
    this.setOriginalItemsTable(this.workDocumentItems)
  },
  async unmounted() {
    this.clearSecondTableData()
    this.clearSecondTable()
    await this.clearItems()
    await this.copyOriginalItemsTableToWorkDocument()
  }
})
