
import {defineComponent} from "vue";

export default defineComponent({
  name: 'LoadingBar',
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {
  }
})
