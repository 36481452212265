import Swal from 'sweetalert2'

const swal = {
    async error(error: string) {
        await Swal.fire({
            icon: `error`,
            html: error,
        })
    },
    async message(message: string, title: string = '') {
        const result = await Swal.fire({
            title,
            html: message,
            showCancelButton: false,
            confirmButtonText: `Fechar`,
            confirmButtonColor: `#1c6eca`,
        })

        return (result.isConfirmed)
    },
    async info(message: string, title: string = '') {
        const result = await Swal.fire({
            title,
            icon: 'info',
            html: message,
            showCancelButton: false,
            confirmButtonText: `Fechar`,
            confirmButtonColor: `#1c6eca`,
        })

        return (result.isConfirmed)
    },
    async confirm(message: string, options:any = {}) {
        const result = await Swal.fire({
            icon: options['icon'] ?? `question`,
            html: message,
            showCancelButton: options['showCancelButton'] ?? true,
            cancelButtonText: options['cancelButtonText'] ?? `Cancelar`,
            confirmButtonText: options['confirmButtonText'] ?? `Sim`,
            confirmButtonColor: options['confirmButtonColor'] ?? `#1c6eca`,
        })

        return (result.isConfirmed)
    },
    async range(label: string, minimum: number, maximum: number, value: number) {

        /* range options */
        const options = {
            min: minimum,
            max: maximum,
            step: 1,
        }

        /* fire swal */
        const result = await Swal.fire({
            input: `range`,
            inputLabel: label,
            inputAttributes: options as any,
            inputValue: value,
        })

        return result.value
    },
    async text(label: string, placeholder: string) {
        const result = await Swal.fire({
            input: 'textarea',
            inputLabel: label,
            inputPlaceholder: placeholder,
            inputAttributes: {
                'aria-label': placeholder
            },
            showCancelButton: true,
            cancelButtonText: `Cancelar`,
            confirmButtonText: `Sim`,
            confirmButtonColor: `#1c6eca`,
        })

        return result.value
    },
    async email(label: string, placeholder: string, errorMessage: string = `Email inválido!`) {
        const result = await Swal.fire({
            input: 'email',
            inputLabel: label,
            inputPlaceholder: placeholder,
            validationMessage: errorMessage,
        })

        return result.value
    },
    async url(label: string, placeholder: string, errorMessage: string = `URL inválido!`) {
        const result = await Swal.fire({
            input: 'url',
            inputLabel: label,
            inputPlaceholder: placeholder,
            validationMessage: errorMessage,
        })

        return result.value
    },
}

export {
    swal,
}