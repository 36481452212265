import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bg-gray-100 flex flex-col rounded shadow p-4 lg:p-8 w-4/5 xl:w-3/5 2xl:w-2/5 h-4/5 max-h-screen relative" }
const _hoisted_2 = { class: "flex flex-col h-1/4 w-full" }
const _hoisted_3 = { class: "flex justify-between items-center h-1/3 mb-1" }
const _hoisted_4 = { class: "flex flex-row h-1/3" }
const _hoisted_5 = { class: "flex flex-row mt-1 h-1/3" }
const _hoisted_6 = { class: "flex justify-center items-center h-3/4 w-full" }
const _hoisted_7 = { class: "flex max-h-full mt-3 oveflow-hidden h-full w-full" }
const _hoisted_8 = {
  key: 0,
  class: "flex items-center justify-center h-full flex-1"
}
const _hoisted_9 = { class: "flex flex-col gap-2 items-center" }
const _hoisted_10 = {
  class: "flex flex-col w-1/5",
  style: {"min-width":"20%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!
  const _component_KeyboardInput = _resolveComponent("KeyboardInput")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_LoadingBar = _resolveComponent("LoadingBar")!
  const _component_ModalClientSearchResultItem = _resolveComponent("ModalClientSearchResultItem")!
  const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-black font-semibold text-xl flex-1" }, " Procurar Cliente ", -1)),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeActiveModal && _ctx.closeActiveModal(...args))),
          class: "bg-red-400 hover:bg-red-500 rounded text-white h-full",
          style: {"aspect-ratio":"1 / 1"}
        }, [
          _createVNode(_component_XIcon)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openCreateCustomerModal && _ctx.openCreateCustomerModal(...args))),
          class: "bg-green-500 hover:bg-green-600 text-white px-2 lg:px-5 shadow flex justify-center items-center h-full"
        }, "Criar cliente"),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setFinalConsumer && _ctx.setFinalConsumer(...args))),
          class: "bg-blue-500 hover:bg-blue-600 text-white px-2 lg:px-5 shadow flex justify-center items-center h-full"
        }, "Consumidor Final")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_KeyboardInput, {
          onEnterPressed: _ctx.searchInputEnterPressed,
          class: "h-full",
          placeholder: 'Pesquisar cliente...',
          modelValue: _ctx.searchString,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchString) = $event))
        }, null, 8, ["onEnterPressed", "modelValue"]),
        _createElementVNode("button", {
          class: "text-white bg-green-400 px-5 hover:bg-green-500",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
        }, [
          _createVNode(_component_SearchIcon, { class: "h-4" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.getSearchingCustomerStatus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-gray-700 font-semibold" }, " A pesquisar clientes... ", -1)),
                _createElementVNode("div", null, [
                  _createVNode(_component_LoadingBar)
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["grid grid-cols-1", 'grid-rows-' + _ctx.maximumGridRows()]),
              style: {"max-width":"80%","min-width":"80%"}
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customersSearchResults.slice(_ctx.index,_ctx.end), (customer, index) => {
                return (_openBlock(), _createBlock(_component_ModalClientSearchResultItem, {
                  key: index,
                  customer: customer
                }, null, 8, ["customer"]))
              }), 128))
            ], 2)),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.moveUp && _ctx.moveUp(...args))),
            class: _normalizeClass(["flex h-1/2 justify-center items-center bg-gray-400 text-white", [_ctx.index === 0 ? 'opacity-60 cursor-not-allowed' : 'hover:bg-gray-500 ']])
          }, [
            _createVNode(_component_ChevronUpIcon, { class: "h-full" })
          ], 2),
          _createElementVNode("button", {
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.moveDown && _ctx.moveDown(...args))),
            class: _normalizeClass(["flex h-1/2 justify-center items-center bg-gray-400 text-white", [!(_ctx.end < _ctx.customersSearchResults.length) ? 'opacity-60 cursor-not-allowed' : 'hover:bg-gray-500 ']])
          }, [
            _createVNode(_component_ChevronDownIcon, { class: "h-full" })
          ], 2)
        ])
      ])
    ])
  ]))
}