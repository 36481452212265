
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "DocumentTypeLabel",
  data() {
    return {}
  },
  props: [],
  computed: {
    ...mapGetters(['workDocumentTypeName'])
  },
  methods: {},
  watch: {},
  mounted() {
  }
})
