import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-white font-medium text-xs md:text-base right-0 px-3 w-16 flex flex-col justify-between items-end" }
const _hoisted_2 = { class: "font-extralight text-xs mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "flex text-white py-1"
}
const _hoisted_4 = { class: "font-semibold text-2xl" }
const _hoisted_5 = { class: "text-sm font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "relative flex py-4 w-full overflow-x-hidden text-white cursor-pointer",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.headerButton && _ctx.headerButton(...args)))
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-1 flex flex-col items-center relative w-auto h-auto" }, [
        _createElementVNode("img", {
          src: "images/logo_horizontal.webp",
          alt: "logo",
          class: "h-8 lg:h-10 xl:h-16"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.time), 1),
        _createElementVNode("p", _hoisted_2, "v" + _toDisplayString(_ctx.appVersion), 1)
      ])
    ]),
    (!_ctx.editMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openTableSelectionView && _ctx.openTableSelectionView(...args))),
            class: _normalizeClass(["flex-1 flex gap-4 justify-around px-5 py-4 items-center select-none cursor-pointer", [_ctx.isTableSelected ? 'bg-green-600 hover:bg-green-500' : 'bg-orange-600 hover:bg-orange-500']])
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              class: "h-4 lg:h-6",
              src: "images/dinner-table.png",
              alt: "dinner_table"
            }, null, -1)),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.currentTable.descricao), 1)
          ], 2),
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fnOpenModal('ModalSwitchEmployee'))),
            class: "md:w-20 xl:w-36 flex py-2 justify-center items-center hover:bg-gray-700 select-none cursor-pointer"
          }, [
            _cache[5] || (_cache[5] = _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              class: "h-4 lg:h-6",
              fill: "none",
              viewBox: "0 0 24 24",
              stroke: "currentColor",
              "stroke-width": "2"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                d: "M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.userData.nome_utilizador), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}