
import {defineComponent} from "vue";
import SelectList from "@/components/Lists/SelectList.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import POSnotyf from "@/libs/POSnotyf";

export default defineComponent({
  name: 'PrintInvoiceCopyView',
  data() {
    return {
      selectedInvoice: null,
    }
  },
  components: {SelectList},
  computed: {
    ...mapGetters(['workDocumentTypeId', 'lastInvoices', 'getLastInvoicesStartDate', 'getLastInvoicesEndDate', 'getIsFetchingLastInvoices', 'documentTypes', 'getLastInvoiceDocumentType']),
    startDate: {
      set(value: string) {
        this.setLastInvoicesStartDate(value)
      },
      get(): string {
        return this.getLastInvoicesStartDate;
      }
    },
    endDate: {
      set(value: string) {
        this.setLastInvoicesEndDate(value)
      },
      get(): string {
        return this.getLastInvoicesEndDate;
      }
    },
    selectedDocumentTypeId: {
      set(value: string) {
        this.setLastInvoiceDocumentTypeId(value)
      },
      get(): string {
        return this.getLastInvoiceDocumentType
      }
    },
  },
  methods: {
    ...mapMutations(['addToPrintQueue', 'setLastInvoicesStartDate', 'setLastInvoicesEndDate', 'clearLastInvoices', 'setLastInvoiceDocumentTypeId',]),
    ...mapActions(['fetchLastInvoices', "fnOpenView"]),
    async startSearch() {
      POSnotyf.info(`A pesquisar documentos...`)

      // clear existing invoices
      this.clearLastInvoices()

      try {
        await this.fetchLastInvoices()
        POSnotyf.success(`Pesquisa terminada!`)
      } catch (e) {
        POSnotyf.error(`Não foi possivel obter os documentos!`)
      }
    },
    print(invoice: any) {
      this.selected(invoice)
      this.printSelected()
    },
    selected(invoice: any) {
      this.selectedInvoice = invoice
    },
    printSelected() {
      if (this.selectedInvoice) {

        let invoice: any = this.selectedInvoice

        this.addToPrintQueue({
          documentType: invoice.id_tipo_documento,
          invoiceID: invoice.id_encomenda,
        })

        this.fnOpenView('PrinterSelection')
      }
    },
    startDateInputs() {
      const currentDate = new Date();

      // first day of the month
      this.setLastInvoicesStartDate(currentDate.toISOString().substr(0, 10))

      // today date
      this.setLastInvoicesEndDate(currentDate.toISOString().substr(0, 10))
    },
  },
  mounted() {
    this.selectedDocumentTypeId = this.workDocumentTypeId
    this.startDateInputs()
    this.fetchLastInvoices()
  }
})
