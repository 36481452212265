
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import splitByPeopleView from "@/components/SplitByPeopleView.vue";

export default defineComponent({
  name: 'PrinterSelectionView',
  data() {
    return {
      selectedAgent: 0,
    }
  },
  computed: {
    ...mapGetters(['getSplitByPeople','getDefaultSessionAgent', 'nextPrintQueueJob', 'pendingPrintJobs', 'getAgents', 'getAgentSlipPrinters', 'onlyOneAgentAvailable', 'onlyOnePrinterAvailable', 'noPrinterAvailable']),
    agents(): any {
      return this.getAgents
    },
    printers(): any {
      return this.getAgentSlipPrinters
    },
    agentIsSelected(): boolean {
      return this.selectedAgent !== 0
    },
  },
  methods: {
    ...mapMutations(['clearPrintQueue', 'setCurrentPage']),
    ...mapActions(['verifySplitItems','fnOpenModal','fnLogout', 'fetchAgents', 'fetchAgentPrinters', 'processPrintQueue', 'fnOpenView', 'showStatusMessage', 'closeStatusMessage']),

    async selectAgent(agentID: number) {
      this.selectedAgent = agentID;
      await this.fetchAgentPrinters(agentID);

      // if only one printer is available triggers the printing
      if (this.onlyOnePrinterAvailable) {
        await this.printDocument(this.printers[0].impressora)
      }
    },
    async printDocument(printerName: string) {

      // show printing status message
      await this.showStatusMessage('A imprimir documento ...');

      // send invoice to printers
      await this.processPrintQueue({
        agentID: this.selectedAgent,
        printerName,
      })



      if( await this.verifySplitItems()) {

        // close printing message and open items view
        await this.closeStatusMessage({
          nextView: 'SplitByPeople',
        })
      }
      else{

        await this.closeStatusMessage()
      }

    },
    async cancelPrinting() {

      // check if its a sesssion close
      if (this.pendingPrintJobs) {
        if (this.nextPrintQueueJob.documentType === 'fecho')
          this.fnLogout() // logout user if the session closing printing is canceled

        // clear the print queue
        this.clearPrintQueue()
      }

      if( await this.verifySplitItems()) {
        this.fnOpenView('SplitByPeople')
      }
      else{
        // change to the items view
        this.fnOpenView('Items')
        //first view of items .
        this.setCurrentPage(0)
      }

    },
    refreshAgents() {
      this.fetchAgents()
    },
    refreshPrinters() {
      this.fetchAgentPrinters(this.selectedAgent);
    },
    sendBillEmail(state:any){

      this.fnOpenModal('ModalSendEmail')
      }

    // },
  },
  async mounted() {
    // if no agents are loaded, fetch them
    if (this.agents.length === 0) {
      await this.fetchAgents()
    }

    // if only one agent available, select it and load its printers
    if (this.getDefaultSessionAgent !== null) {
      await this.selectAgent(this.getDefaultSessionAgent)
    } else if (this.onlyOneAgentAvailable) {
      await this.selectAgent(this.agents[0].id)
    }
  },
})
