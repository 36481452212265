import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "primary-bg-color h-screen w-full p-8 flex flex-col gap-8 overflow-y-scroll" }
const _hoisted_2 = { class: "flex flex-col gap-2 md:gap-8 md:flex-row md:items-end" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "flex-1" }
const _hoisted_8 = { class: "table" }
const _hoisted_9 = { class: "border-b bg-white" }
const _hoisted_10 = { class: "text-center bg-white flex flex-col" }
const _hoisted_11 = { class: "font-semibold" }
const _hoisted_12 = { class: "text-sm" }
const _hoisted_13 = { class: "bg-white hidden md:table-cell" }
const _hoisted_14 = { class: "text-sm" }
const _hoisted_15 = { class: "text-sm font-semibold" }
const _hoisted_16 = { class: "text-center bg-white font-semibold text-base md:text-xl" }
const _hoisted_17 = { class: "text-center bg-white" }
const _hoisted_18 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "primary-text text-xl uppercase" }, " Reimprimir documentos ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { class: "text-white" }, " Data inicial ", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event))
        }, null, 512), [
          [_vModelText, _ctx.startDate]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("label", { class: "text-white" }, " Data final ", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "date",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event))
        }, null, 512), [
          [_vModelText, _ctx.endDate]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[6] || (_cache[6] = _createElementVNode("label", { class: "text-white" }, " Tipo Documento ", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedDocumentTypeId) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documentTypes, (documentType) => {
            return (_openBlock(), _createElementBlock("option", {
              value: documentType.id_tipo_documento
            }, _toDisplayString(documentType.tipo_documento), 9, _hoisted_6))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.selectedDocumentTypeId]
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.startSearch && _ctx.startSearch(...args))),
          class: _normalizeClass([{'cursor-not-allowed':_ctx.getIsFetchingLastInvoices}, "p-4 bg-blue-700 hover:bg-blue-600 hover text-white rounded w-full"])
        }, "Pesquisar ", 2)
      ])
    ]),
    _createElementVNode("table", _hoisted_8, [
      _cache[8] || (_cache[8] = _createElementVNode("thead", { class: "" }, [
        _createElementVNode("tr", { class: "bg-blue-700 text-white" }, [
          _createElementVNode("th", null, "Documento"),
          _createElementVNode("th", { class: "hidden md:table-cell" }, "Cliente"),
          _createElementVNode("th", null, "Total"),
          _createElementVNode("th")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastInvoices, (document) => {
          return (_openBlock(), _createElementBlock("tr", _hoisted_9, [
            _createElementVNode("td", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(document.descricao), 1),
              _createElementVNode("span", _hoisted_12, _toDisplayString(document.data_criacao), 1)
            ]),
            _createElementVNode("td", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(document.nome_cliente), 1),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", _hoisted_15, _toDisplayString(document.nif_cliente), 1)
            ]),
            _createElementVNode("td", _hoisted_16, "€ " + _toDisplayString(document.total_documento), 1),
            _createElementVNode("td", _hoisted_17, [
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.print(document)),
                class: "bg-green-600 hover:bg-green-500 text-white w-full h-full rounded shadow font-bold text-xs py-1 md:text-base"
              }, "Imprimir", 8, _hoisted_18)
            ])
          ]))
        }), 256))
      ])
    ])
  ]))
}