
import {defineComponent, ref, onMounted, Ref} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import POSnotyf from "@/libs/POSnotyf";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";

export default defineComponent({
  name: "ModalPlate",
  setup() {
    const textarea: any = ref(null)

    // set focus on mounted
    onMounted(() => {
      textarea.value?.focus()
    })

    return {textarea}
  },
  data() {
    return {
      inputPlate: "",
    }
  },
  components: {KeyboardInput},
  props: [],
  computed: {
    ...mapGetters(["getLicensePlate"]),
  },
  methods: {
    ...mapActions(['closeActiveModal', 'setInvoiceLicensePlate']),
    ...mapMutations(['insertLicensePlate']),

    async save() {
      this.insertLicensePlate(this.inputPlate)
      this.close()

      if (await this.setInvoiceLicensePlate()) {
        POSnotyf.success(`Matricula adicionada ao documento com sucesso!`)
      } else {
        POSnotyf.error(`Não foi possivel registar a matricula no documento!`)
      }
    },
    close() {
      this.closeActiveModal()
    }
  },
  watch: {},
  mounted() {
    if (this.getPlate !== "") {
      this.inputPlate = this.getLicensePlate
    }
  }
})
