const fnAuthentication = {
    actions: {
        fnLogout(state: any) {
            state.dispatch('logout')
            location.replace('/login')
            location.reload()
        }
    }
}

export {
    fnAuthentication
}
