
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";
import {SplitInvoice} from "@/store/modules/SplitInvoice";
import splitByPeopleView from "@/components/SplitByPeopleView.vue";
import POSnotyf from "@/libs/POSnotyf";

export default defineComponent({
    name: 'ModalPersonQuantity',
    components: {NumericKeyboard},
    props: [],
    data() {
        return {
            inputQuantity: '',
        }
    },
    computed: {
        ...mapGetters(['itemWaitingForQuantity']),
    },
    methods: {
        ...mapActions(['canDivideListByPeople','closeActiveModal', 'setAddItemQuantity', 'fnAddItem', 'fnOpenView']),
        ...mapMutations(['divideListByPeople','clearItemWaitingForQuantity','splitNumOfPeople']),
        numpadClicked(value: string) {
            if (value === '<') {
                // delete last number
                if (this.inputQuantity) {
                    this.inputQuantity = this.inputQuantity.slice(0, -1);
                }
            } else {
                this.inputQuantity += value
            }
        },
        close() {

            this.closeActiveModal()
        },
        async save() {

          const quantity = parseFloat(this.inputQuantity);
          await this.splitNumOfPeople(quantity)
          const isDividedByPeople: boolean = await this.canDivideListByPeople()

          // to split Per person need to be int and > 1
          if (!Number.isInteger(quantity) ) {
            POSnotyf.error("O número deve ser inteiro!");
            this.inputQuantity = ""
          }
          else if(quantity <= 1){
            POSnotyf.error("O número deve ser maior que 1!");
            this.inputQuantity = ""
          }
          else {



            if( isDividedByPeople){
              this.closeActiveModal();
              this.fnOpenView('SplitByPeople');
            }
            else{
              POSnotyf.error("Erro de divisão")
            }


          }




        }
    },
    mounted() {
    }
})
