import {LoginData} from "@/interfaces/LoginData";

const Authentication = {
    state: {
        token: localStorage.getItem('token') || '',
        userData: localStorage.getItem('userData') || '',
        userOptions: localStorage.getItem('userOptions') || '',
    },
    mutations: {
        setToken(state: any, token: string): void {
            state.token = token
            localStorage.setItem('token', token)
        },
        clearToken(state: any): void {
            state.token = ''
            localStorage.removeItem('token')
        },
        setUserData(state: any, userData: any) {
            state.userData = JSON.stringify(userData)
            localStorage.setItem('userData', JSON.stringify(userData))
        },
        setUserOptions(state: any, userOptions: any){
            state.userOptions = JSON.stringify(userOptions)
            localStorage.setItem('userOptions', JSON.stringify(userOptions))
        }
    },
    getters: {
        token(state: any): string {
            return state.token
        },
        hasToken(state: any): boolean {
            return state.token !== ''
        },
        userData(state: any): any {
            return JSON.parse(state.userData)
        },
        userOptions(state: any): any {
            return JSON.parse(state.userOptions)
        },
        userDefaultDocumentType(state:any, getters:any):any{
          return getters.userOptions.id_tipo_documento_defeito
        },
        userUsesSession(state: any, getters: any) {
            return (getters.userData.utiliza_caixa === '1')
        },
    },
    actions: {
        fetchToken(state: any, loginData: LoginData) {
            return new Promise<string>((resolve, reject) => {

                // create form data
                let formData = new FormData();
                formData.append('username', loginData.username);
                formData.append('password', loginData.password);

                // fetch token
                fetch(`${state.getters.apiURL}obtertoken`, {
                    method: 'POST',
                    body: formData
                })
                    .then(res => res.json())
                    .then((tokenData: any) => {
                        state.commit('setUserData', tokenData)
                        state.commit('setToken', tokenData.token)

                        // fetch user options
                        state.dispatch('fetchUserOptions')

                        resolve(tokenData.token)
                    })
                    .catch(err => reject(err))
            })
        },
        fetchTokenUsingPIN(state: any, pin: string) {
            return new Promise<void>((resolve, reject) => {

                // create form data
                let formData = new FormData();
                formData.append('pin', pin);

                // fetch token
                fetch(`${state.getters.apiURL}obtertokenpin`, {
                    method: 'POST',
                    body: formData
                })
                    .then(res => res.json())
                    .then(async (tokenData: any) => {
                        state.commit('setUserData', tokenData)
                        state.commit('setToken', tokenData.token)

                        // fetch user options
                        await state.dispatch('fetchUserOptions')

                        resolve(tokenData.token)
                    })
                    .catch(err => reject(err))
            })
        },
        async fetchUserOptions(state:any){
            try {
                const response = await fetch(`${state.getters.apiURL}utilizador/?p=${state.getters.userData.id}`,{
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })

                // fetch and parse user options
                // and save them on state
                if(response.ok){
                    const data = await response.json()
                    state.commit('setUserOptions',data[0])
                    return data
                }else{
                    return false
                }
            }catch (e) {
                console.error(e)
                return false
            }
        },
        setUserDefaultDocumentType(state:any){
            // set document type globally first to avoid fails after
            state.commit('setTypeId', parseInt(state.getters.goldylocksConfig.documento_defeito))

            // if the user has specific document type applies it
            if (Number(state.getters.userDefaultDocumentType) > 0) {
                state.commit('setTypeId', parseInt(state.getters.userDefaultDocumentType))
            }
        },
        setUserDefaultDocumentSeries(state:any){
            if (Object.keys(state.getters.getSessionData).length) {
                state.commit('setSeries', state.getters.getSessionData.serie_defeito)
            } else {
                state.commit('setSeries', state.getters.goldylocksConfig.serie_defeito)
            }
        },
        logout(state: any) {
            return new Promise<void>((resolve, reject) => {
                state.commit('clearToken')
                resolve()
            })
        }
    },
}

export {
    Authentication
}
