import {swal} from "@/libs/POSSwal";

const fnSessions = {
    actions: {
        async fnCloseSession(state: any) {
            if (await swal.confirm('Deseja fechar a caixa?')) {
                state.dispatch('showStatusMessage', `A terminar sessão de caixa...`)

                try {
                    let userSessionData = await state.dispatch('fetchSessionData')

                    if (userSessionData) {
                        await state.dispatch('endUserSession', {
                            user_id: state.getters.userData.id,
                            station_id: userSessionData.id_caixa,
                        })
                    }

                    state.commit('addToPrintQueue', {
                        invoiceID: userSessionData.id_sessao,
                        documentType: 'fecho',
                    })


                    state.commit('activeView', 'PrinterSelection')
                } catch (err) {
                    console.error(err)
                }
            }
        },
        fnOpenUserClosedSessionsView(state: any) {
            state.commit('activeView', 'UserClosedSessions')
        }
    },
}

export {
    fnSessions,
}
