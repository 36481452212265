
import {defineComponent} from "vue";

export default defineComponent({
  name: "DetailedItemList",
  data() {
    return {
      componentName: "DetailedItemList"
    }
  },
  components: {},
  props:[],
  computed: {},
  methods: {},
  watch: {},
  mounted() {}
})
