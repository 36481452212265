import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["uuid"]
const _hoisted_2 = { class: "select-none px-4 py-1 bg-origin-padding uppercase font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    uuid: _ctx.button.uuid,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.executeFn && _ctx.executeFn(...args))),
    class: _normalizeClass([[{'opacity-40':!_ctx.beingEdited},{'ring-2 ring-blue-900':this.button.fn === 'fnPage'}], "select-none rounded-xl shadow cursor-pointer text-xs lg:text-sm flex flex-col justify-end"]),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['rounded-b rounded-2',{'label':_ctx.hasImage}])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.button.label), 1)
    ], 2)
  ], 14, _hoisted_1))
}