
import {defineComponent} from "vue";
import {mapMutations} from "vuex";

export default defineComponent({
  name: 'OfflineView',
  methods: {
    ...mapMutations(['lockOperationsSection']),
    reload() {
      window.location.reload()
    }
  },
  mounted() {
    this.lockOperationsSection()
  }
})
