
import {defineComponent} from 'vue';
import OperationsSection from "@/components/OperationsSection.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ContentView from "@/components/ContentView.vue";
import Modals from "@/components/Modals.vue";
import Keyboard from "@/components/Keyboards/Keyboard.vue";
import MobileHeader from "@/components/MobileHeader.vue";
import MobileFooter from "@/components/MobileFooter.vue";
import MobileOperations from "@/components/MobileOperations.vue";
import {GoldylocksAgentKeepAlive} from "@/libs/GoldylocksAgentKeepAlive";
import {BarcodeScanner} from "@/libs/BarcodeScanner";
import {fnItems} from "@/store/modules/POSFunctions/fnItems";
import POSnotyf from "@/libs/POSnotyf";

export default defineComponent({
  name: 'Home',
  components: {
    MobileOperations,
    Keyboard,
    Modals,
    ContentView,
    OperationsSection,
    //Mobile Components
    MobileHeader,
    MobileFooter
  },
  data() {
    return {
      isOperationsMobileOpen: false
    }
  },
  computed: {
    ...mapGetters(['apiURL', 'pages', 'isStatusOpen', 'itemsCount', 'appName', 'appVersion', 'isModalVisible', 'userUsesSession', 'lockedOperationsSection', 'isPortrait', 'isKeyboardOpen'])
  },
  methods: {
    ...mapActions(['fnAddItem', 'fnOpenView', 'showStatusMessage', 'closeStatusMessage', 'fetchDocumentTypes', 'fetchPaymentMethods', 'fetchSeries', 'fetchGoldylocksSettings', 'newWorkDocument', 'fetchItems', 'fetchPagesFromAPI', 'generatePages', 'fnLogout', 'fetchSessionData', 'openUserSessionStartView', 'fetchSessionStations', 'fetchTablesZones', 'checkPortrait', 'fetchPriceLines']),
    ...mapMutations(['setStatusLogProgress', "addStatusLogMessage", 'refreshWindowSize']),
    toggleOperationsMobileStatus: function () {
      this.isOperationsMobileOpen = !this.isOperationsMobileOpen;
    },
    showItemsPage() {
      // start new document information
      this.newWorkDocument()

      // close status view and start next view
      this.closeStatusMessage({
        nextView: 'Items',
      })
    }
  },
  async mounted() {

    // loading message
    await this.showStatusMessage(`<div class="flex flex-col items-center">
        <div class="text-2xl text-gray-500">${this.appName}</div>
        <div class="font-light text-xs">${this.appVersion}</div>
        <div class="text-sm font-light mt-6">A carregar dados</div>
    </div>`)

    // INITIAL SETUP AND DATA FETCHING
    this.addStatusLogMessage('A obter configurações...')

    // check if user is logged in and fetch settings
    try {
      await this.fetchGoldylocksSettings()

      this.addStatusLogMessage('A obter linhas de preço...')
      await this.fetchPriceLines()

      this.addStatusLogMessage('A obter artigos...')
      await this.fetchItems()

      this.addStatusLogMessage('A obter tipos de documento...')
      await this.fetchDocumentTypes()

      this.addStatusLogMessage('A obter séries de documentos...')
      await this.fetchSeries()

      this.addStatusLogMessage('A obter metodos de pagamento...')
      await this.fetchPaymentMethods()

      this.addStatusLogMessage('A obter páginas...')
      await this.fetchPagesFromAPI()

      // generate first pages if empty
      if (this.pages.length === 0) {
        await this.generatePages()
      }

      // get tables
      this.addStatusLogMessage('A obter mesas...')
      await this.fetchTablesZones()

      // check if user has session enabled
      if (this.userUsesSession) {
        this.addStatusLogMessage('A verificar caixa...')
        let sessionData = await this.fetchSessionData()

        this.addStatusLogMessage('A obter caixas...')
        await this.fetchSessionStations()

        // no session is active for the user
        if (sessionData.length === 0) {
          await this.openUserSessionStartView()
        } else {
          this.showItemsPage()
        }
      } else {
        this.showItemsPage()
      }

      // /* start Goldylocks agent keepalive */
      // /* remove comment to build with capacitor */
      // if (/(Android|iPad|iPhone|iPod)/i.test(window.navigator.userAgent)) {
      //   await GoldylocksAgentKeepAlive.start(this.apiURL)
      // }

      await this.checkPortrait()

    } catch (err) {
      await this.fnLogout()
    }

    /**
     * Window resize event
     */
    window.addEventListener('resize', () => {
      this.checkPortrait()
    });


    /**
     * onKeyDown event for the barcode scanners
     * checks if the event is not already running
     */
    if (!BarcodeScanner.isListening) {

      // sets the listening flag
      BarcodeScanner.isListening = true

      // creates the event listener
      window.addEventListener('keydown', (ev) => {

        // store read char
        BarcodeScanner.readChar(ev.key)

        // set barcode scanner onEnter callback
        BarcodeScanner.onEnter(async (scannedCode) => {
          const isAdded = await this.fnAddItem(scannedCode)

          if (!isAdded) {
            POSnotyf.error(`<strong>${scannedCode}</strong><br><br>Artigo ou código de barras não registado!`)
          }
        })
      })
    }

  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.checkPortrait()
    });
  },
});
