const Keyboard = {
    state: {
        isKeyboardOpen: false,
        lastKeypress: '',
        lastInputUUID: '',
        lastKeypressTimestamp: 0,
        keyboardInputLabel: '',
        keyboardInputContent: '',
    },
    mutations: {
        openKeyboard(state: any) {
            state.isKeyboardOpen = true
        },
        closeKeyboard(state: any) {
            state.isKeyboardOpen = false
        },
        toggleKeyboard(state: any) {
            state.isKeyboardOpen = !state.isKeyboardOpen
        },
        setLastKeypress(state: any, lastKeypress: string) {
            state.lastKeypress = lastKeypress
            state.lastKeypressTimestamp = new Date().getTime()
        },
        setLastInputUUID(state: any, uuid: string) {
            state.lastKeypress = ''
            state.lastInputUUID = uuid
        },
        setKeyboardInputContent(state: any, content: string) {
            state.keyboardInputContent = (content) ? content : ''
        },
        setKeyboardInputLabel(state: any, label: string) {
            state.keyboardInputLabel = label
        },
    },
    getters: {
        isKeyboardOpen(state: any) {
            return state.isKeyboardOpen
        },
        getLastKeypress(state: any): any {
            return [state.lastInputUUID, state.lastKeypress, state.lastKeypressTimestamp]
        },
        getLastInputData(state: any): any {
            return [state.lastInputUUID, state.keyboardInputContent, state.lastKeypressTimestamp]
        },
        getKeyboardInputContent(state: any) {
            return state.keyboardInputContent
        },
        getKeyboardInputLabel(state: any) {
            return state.keyboardInputLabel
        },
    },
    actions: {
        openKeyboard(state: any) {
            state.commit('openKeyboard')
        },
        closeKeyboard(state: any) {
            state.commit('closeKeyboard')
        },
        toggleKeyboard(state: any, uuid: string) {
            state.commit('setLastInputUUID', uuid)
            state.commit('toggleKeyboard')
        }
    }
}

export {
    Keyboard
}
