
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import DocumentTypeLabel from "@/components/DocumentTypeLabel.vue";
import DocumentSerialLabel from "@/components/DocumentSerialLabel.vue";
import OperationsFastMenu from "@/components/OperationsFastMenu.vue";
import TableSelectionView from "@/components/TableSelectionView.vue";

export default defineComponent({
  name: 'OperationsSectionFooter',
  components: {
    DocumentTypeLabel,
    DocumentSerialLabel,
    OperationsFastMenu
  },
  props: [],
  data() {
    return {
      fastMenuState: false,
      width: 0
    }
  },
  computed: {
    ...mapGetters(['savedTableZone', 'activeView','isTableSelected', 'activePriceLineDescription', 'activePriceLine', 'isDefaultPriceLine', 'previousTotal', 'workDocumentTotal', "itemsList", "workDocumentItems", 'defaultSettingsPageNumber', 'itemsListHasMoreItems', 'getCurrentItemsListIndex', 'workDocumentTypeName', 'workDocumentSeries']),
    showNextItemButton() {
      return this.itemsListHasMoreItems;
    },
    showPreviousItemButton() {
      return (this.getCurrentItemsListIndex > 0)
    },
    totalShown(): number {
      return (this.workDocumentTotal !== 0) ? this.workDocumentTotal : this.previousTotal
    },
  },
  methods: {
    ...mapMutations(["activeView", 'saveTableZone','currentTablesZone','activeTableZoneID']),
    ...mapActions(['saveTableItems', 'generatePages', 'exec', "fnPage", "fnGeneratePages", 'fnCreateInvoiceFromWorkDocument', 'incrementCurrentItemsListIndex', 'decrementCurrentItemsListIndex', 'fnClearItems', 'fnOpenView']),
    logout() {
      this.exec({fn: 'fnLogout'})
          .then(() => {
            this.$router.push('/login')
          })
    },
    openSettings: function () {
      this.fnOpenView('Items');
      this.fnPage(this.defaultSettingsPageNumber)
    },
    toggleFastMenu: function () {
      this.fastMenuState = !this.fastMenuState
    },
    async createInvoice() {
      try {
        await this.fnCreateInvoiceFromWorkDocument()
      } catch (err) {
        console.error(err)
      }
    },
    async saveTable(){
      //save zone ID to call after close table
      await this.saveTableZone()
      //save table data
      await this.saveTableItems(false)
      this.fnOpenView('TableSelection')
    }
  },
  watch: {
    workDocumentItems: function (newValue: any, oldValue: any) {
      if (newValue.length > oldValue.length) {
        // show next item button
      } else if (newValue.length == 0) {
        // no items on the list
      }
    }
  }
})
