import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "font-semibold flex flex-col justify-center" }
const _hoisted_3 = { class: "text-4xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToInitialPage && _ctx.goToInitialPage(...args))),
      class: "flex-1 h-16 bg-raisinBlack-600 flex justify-center items-center cursor-pointer select-none"
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: "images/logo_horizontal.webp",
          alt: "logo",
          class: "h-8"
        })
      ], -1)
    ])),
    (_ctx.currentTable.id_mesa > 0)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "px-8 bg-green-600 text-white",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openView('TableSelection')))
        }, [
          _createElementVNode("span", _hoisted_2, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-xs" }, "Mesa", -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.currentTable.id_mesa), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "px-8 bg-orange-600 text-white",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openView('TableSelection')))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("span", { class: "font-semibold flex flex-col justify-center" }, [
            _createElementVNode("span", { class: "text-xs" }, "Sem"),
            _createElementVNode("span", { class: "text-xl font-semibold" }, "Mesa")
          ], -1)
        ])))
  ]))
}