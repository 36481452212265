const fnSystem = {
    state: {
        isElectronApp: navigator.userAgent.toLowerCase().indexOf(' electron/') > -1,
        isPortrait: !window.matchMedia("(min-width: 640px)").matches as boolean
    },
    getters: {
        isPortrait(state: any) {
            return state.isPortrait
        },
        isElectronApp(state: any): boolean {
            return state.isElectronApp
        },
    },
    mutations: {
        changePortrait(state: any) {
            state.isPortrait = !window.matchMedia("(min-width: 640px)").matches
        },
    },
    actions: {
        fnQuit(state: any) {
            state.dispatch('electronAppQuit')
        },
        checkPortrait(state: any) {
            state.commit('changePortrait')
        }
    },
}

export {
    fnSystem,
}
