import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "text-sm truncate text-gray-700" }
const _hoisted_3 = { class: "flex truncate gap-2" }
const _hoisted_4 = { class: "text-blue-500 font-semibold" }
const _hoisted_5 = { class: "text-gray-700 truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "flex-1 flex flex-col justify-center select-none cursor-pointer mt-2 bg-white shadow hover:scale-95 transform transition px-4 py-1 rounded mr-2",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setCustomer && _ctx.setCustomer(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.customer.nome), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.customer.nif), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.customer.cp), 1)
      ])
    ]),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editCustomer && _ctx.editCustomer(...args))),
      class: "flex flex-col justify-center select-none cursor-pointer mt-2 bg-white shadow hover:scale-90 transform transition px-4 py-1 rounded mr-2"
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1.5",
        stroke: "currentColor",
        class: "w-6 h-6 opacity-70"
      }, [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
        })
      ], -1)
    ]))
  ]))
}