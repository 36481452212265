import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main flex flex-col items-center h-screen" }
const _hoisted_2 = {
  key: 0,
  class: "grid grid-cols-1 w-96 mt-8"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex gap-2" }
const _hoisted_5 = {
  key: 1,
  class: "grid grid-cols-1 w-96 mt-8"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex gap-2" }
const _hoisted_8 = { class: "border-l px-4" }
const _hoisted_9 = { class: "mt-8 w-96" }
const _hoisted_10 = { class: "mt-8 w-96" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "px-8 text-2xl font-semibold mt-32" }, " Documento criado com sucesso! ", -1)),
    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "px-8 py-2 font-thin" }, " Selecione uma impressora para imprimir o documento. ", -1)),
    (!_ctx.agentIsSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.agents, (agent) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.selectAgent(agent.id)),
              key: agent.id,
              class: "button h-16 flex flex-col justify-center px-8 font-semibold text-gray-600 cursor-pointer hover:shadow"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _cache[4] || (_cache[4] = _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  class: "h-6 w-6",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  stroke: "currentColor"
                }, [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  })
                ], -1)),
                _createTextVNode(" " + _toDisplayString(agent.descricao), 1)
              ])
            ], 8, _hoisted_3))
          }), 128)),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refreshAgents && _ctx.refreshAgents(...args))),
            class: "border px-4 py-2 w-full flex justify-center hover:shadow"
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              class: "h-4 w-4",
              fill: "none",
              viewBox: "0 0 24 24",
              stroke: "currentColor"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
                d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.agentIsSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.printers, (printer) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_ctx.printDocument(printer.impressora)),
              key: printer.impressora,
              class: "button h-16 flex flex-col justify-center font-semibold text-gray-600 cursor-pointer hover:shadow"
            }, [
              _createElementVNode("div", _hoisted_7, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex justify-center w-24" }, [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    class: "h-6 w-6",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor"
                  }, [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                    })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(printer.impressora), 1)
              ])
            ], 8, _hoisted_6))
          }), 128)),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.refreshPrinters && _ctx.refreshPrinters(...args))),
            class: "mt-1 button px-4 py-2 w-full flex justify-center"
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("svg", {
              xmlns: "http://www.w3.org/2000/svg",
              class: "h-4 w-4",
              fill: "none",
              viewBox: "0 0 24 24",
              stroke: "currentColor"
            }, [
              _createElementVNode("path", {
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
                d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              })
            ], -1)
          ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendBillEmail && _ctx.sendBillEmail(...args))),
        class: "bg-green-600 hover:bg-green-500 text-white px-4 py-2 w-full"
      }, "Enviar por Email ")
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelPrinting && _ctx.cancelPrinting(...args))),
        class: "bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 w-full"
      }, "Cancelar ")
    ])
  ]))
}