import {createApp, h} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.css'
import './assets/tailwind.css'

// capacitor hide splash screen
import {SplashScreen} from "@capacitor/splash-screen";

createApp({
    render: () => h(App),
    async mounted() {
        /* hide splash screen */
        await SplashScreen.hide()
    },
}).use(store).use(router).mount('#app')
