
import {defineComponent} from "vue";
import {ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon} from "@heroicons/vue/outline";
import SelectList from "@/components/Lists/SelectList.vue";
import OperationsSectionDetails from "@/components/OperationsSectionDetails.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {WorkDocumentItem} from "@/interfaces/WorkDocumentItem";


export default defineComponent({
  name: "SplitByPeopleView",
  data() {
    return {
      componentName: "SplitByPeopleView",
      selectedItem: {
        description: "",
        id: "",
        quantityPerPerson: 0,
        quantityPerPersonTotal: 0,
        difference: 0
      } as WorkDocumentItem,
      isAnItemSelected: false,
      isAWorkDocumentItemSelected: false,
      nItems: 0,
      showModal: false,
      //use to now what person bill we are working
      activeList: 1,
    }

  },
  components: {
    SelectList,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    OperationsSectionDetails
  },
  computed: {
    ...mapGetters(['getListDividedPerson', 'getSplitByPeople', 'getNumOfPeople', 'workDocumentItems', 'getRemainingSplittedItems', 'fnSave', 'getWorkDocumentSelectedItem', 'getInvoiceViewState', 'isPortrait', 'itemsListHasMoreItems', 'getCurrentItemsListIndex']),
    showNextItemButton(): any {
      return this.itemsListHasMoreItems
    },
    showPreviousItemButton(): any {
      return (this.getCurrentItemsListIndex > 0)
    },
    formattedItemsList(): any {

      let i = this.activeList - 1;

      return this.getListDividedPerson[i].items.map((item: any) => {
        item.quantity = item.quantityPerPersonTotal
        item['formatted'] = `<span class="text-3xl"> ${item.quantityPerPersonTotal}</span> &times; ${item.description}`

        return item
      })

    },
    totalPrice(): string {
      let total = 0;

      for (let i in this.formattedItemsList) {
        total += Number(this.formattedItemsList[i].price) * Number(this.formattedItemsList[i].quantityPerPersonTotal)

        if ((total * 100) % 1 > 0) {
          total = Math.floor(total * 100) / 100 + 0.01;
        }

      }


      // Return the value with 2 decimals
      return total.toFixed(2);
    },


    numDivided(): number {
      return this.getNumOfPeople
    }
  },
  methods: {
    ...mapMutations(['clearSplitByPeople','clearNumOfPeople','clearlistDividedPerson','clearSplittedItems','clearItems','personIncrementSplittedItemLine','clearlistDividedPersonList', 'setWorkdocumentSelectedRow', 'clearWorkDocumentSelectedRow', 'setOriginalSplitItems', 'PersonRemoveLineFromSplittedItems', 'personAddLineToSplittedItems', 'addLineToSplittedItems', 'removeWorkDocumentSelectedItem', 'personIncrementSplittedItemQuantity', 'moveBill']),
    ...mapActions(['divideListByPeople','saveTableItems','copyListDividedPerson','IncrementSplittedItemLineOption','addItemOption', 'copyRemainingSplittedItemsToWorkDocument', 'copyOriginalSplitItemsToWorkDocument', 'fnOpenView', 'fnRemoveSelectedItem', 'changeSelectedRowQuantity', 'incrementCurrentItemsListIndex', 'decrementCurrentItemsListIndex']),

    haveItems(index){
      return this.getListDividedPerson[index].items.length > 0
    },

    selectItem(item: WorkDocumentItem): void {

      this.selectedItem = item;
      this.splitLineByPerson();
    },
    clearSelectedItem() {
      this.selectedItem = {
        description: "",
        id: "",
        quantityPerPerson: 0,
        quantityPerPersonTotal: 0,
        difference: 0,
        price_without_tax: 0,
        tax: '',
        tax_rate: 0,
        uuid: "",
        hasAssociatedItems: false
      }
    },
    splitLineByPerson() {

      if (this.selectedItem.quantityPerPersonTotal > 0) {

        //verify if divide is not exactly equal per person
        if (this.selectedItem.difference !== 0) {

          // Calculate new quantity by adding the difference to the original quantity per person
          let newQuantity = this.selectedItem.quantityPerPerson + this.selectedItem.difference
              newQuantity = Number(newQuantity.toFixed(2))

          // If the new quantity matches the total, update both the total and per person quantities, happens when the divide item is not exactly.
          if (newQuantity === this.selectedItem.quantityPerPersonTotal) {
            this.selectedItem.quantity = this.selectedItem.quantityPerPersonTotal
            this.selectedItem.quantityPerPersonTotal = this.selectedItem.quantityPerPerson
          } else {
            // Otherwise, set the quantity to the original per person value
            this.selectedItem.quantity = this.selectedItem.quantityPerPerson;
          }
        }
        else{
          // divide is equal so quantity per person always match
          this.selectedItem.quantity = this.selectedItem.quantityPerPerson;
        }

        // call wrapper to adjust item to the function addItem
        // isQuantityPerPerson: true to addItemOption now only pass quantity Per Person
        this.addItemOption({item: this.selectedItem, isQuantityPerPerson: true, isQuantityPerPersonTotal: false })
        // the Bill value is to now witch person get their part of the item remove from their list
        this.PersonRemoveLineFromSplittedItems({line: this.selectedItem, bill: this.activeList - 1})

      }
    },
    transfer() {
      // Transfer All items from the person paying to workDocument
      let bill = this.activeList - 1
      let items = this.getListDividedPerson[bill].items
      if (items.length > 0) {
        for(let item of items) {
          // There is a loop because the function  addItem only process 1 item at time
          // the Bill value is to now witch person get all item remove from their list
          this.addItemOption({item: item, isQuantityPerPerson: false, isQuantityPerPersonTotal: true })
        }

      }
      // clear the bill on the split
      this.clearlistDividedPersonList(bill)


    },

    personRestoreSplittedLineFromWorkDocument() {
// get item from work Document
      if (this.getWorkDocumentSelectedItem.quantity) {
        let _index = this.workDocumentItems.findIndex((item: any) => {
          return item.id == this.getWorkDocumentSelectedItem.id
        })
        let _workDocumentLength = this.workDocumentItems.length

        // Check if the item already exists, if not, create it
        if (this.formattedItemsList.some((item: any) => item.id == this.getWorkDocumentSelectedItem.id)) {

          // call the function to move entire line from WorkDocuments to split
          this.personIncrementSplittedItemLine({
            line: this.getWorkDocumentSelectedItem,
            bill: this.activeList - 1
          })



        } else

          this.personAddLineToSplittedItems({line: this.getWorkDocumentSelectedItem, bill: this.activeList - 1})
        //Remove item from work document
        this.fnRemoveSelectedItem()
        if (this.workDocumentItems.length > 0) {
          if (_index + 2 > _workDocumentLength)
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index - 1])
          else
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index])
        }

      }
    },
    personRestoreItemsFromWorkDocument() {
      // get item from workDocument by the quantityPerPerson
      if (this.getWorkDocumentSelectedItem.quantity > 0) {
        let _index = this.workDocumentItems.findIndex((item: any) => {
          return item.id == this.getWorkDocumentSelectedItem.id
        })
        let _workDocumentLength = this.workDocumentItems.length

        // increase split bill list quantity for the item by quantityPerPerson
        this.personIncrementSplittedItemQuantity({
          line: this.getWorkDocumentSelectedItem,
          bill: this.activeList - 1
        })


        // see if the quantity is the last where is the difference
        if(this.getWorkDocumentSelectedItem.quantity === this.getWorkDocumentSelectedItem.quantityPerPerson + this.getWorkDocumentSelectedItem.difference){
          // if comming true remove the quantityPerperson + difference
          this.changeSelectedRowQuantity(this.getWorkDocumentSelectedItem.quantity - (this.getWorkDocumentSelectedItem.quantityPerPerson + this.getWorkDocumentSelectedItem.difference))

        }
        else{
          this.changeSelectedRowQuantity(this.getWorkDocumentSelectedItem.quantity - this.getWorkDocumentSelectedItem.quantityPerPerson)

        }


        // if  quantity = 0 remains remove the line from the work document
        if (this.getWorkDocumentSelectedItem.quantity === 0) {
          this.fnRemoveSelectedItem()
        }

        let _item = this.workDocumentItems.find((item: any) => item.id == this.getWorkDocumentSelectedItem.id)

        // clear selected workdocument item
        this.clearWorkDocumentSelectedRow()

        if (this.workDocumentItems.length > 0) {
          if (_item)
            this.setWorkdocumentSelectedRow(_item)
          else if (_index + 2 > _workDocumentLength)
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index - 1])
          else
            this.setWorkdocumentSelectedRow(this.workDocumentItems[_index])
        }

      }
    },

    reset(){

      // reset the divideByPeople
      this.clearItems()
      this.divideListByPeople()
      this.activeList = 1
    },

    cancelSplit() {
      // cancel the splitByPeople go to tableSelectionView

      this.copyListDividedPerson()
      this.clearlistDividedPerson()
      this.clearSplitByPeople()
      this.clearNumOfPeople()
      this.saveTableItems()
      this.fnOpenView('TableSelection')
    },

    toggleList(index: number) {
      this.activeList = index;
    }
  },
  watch: {

    selectedItem(newItem) {
      this.isAnItemSelected = newItem.id.length != 0

      if (this.selectedItem.id != "") {
        this.clearWorkDocumentSelectedRow()
      }
    },
    getWorkDocumentSelectedItem(newItem) {
      this.isAWorkDocumentItemSelected = Object.keys(newItem).length !== 0

      if (Object.keys(this.getWorkDocumentSelectedItem).length != 0) {
        this.selectedItem = {
          description: "",
          id: "",
          price: 0,
          quantity: 0,
          quantityPerPerson: 0,
          quantityPerPersonTotal: 0
        } as WorkDocumentItem
      }
    },

    formattedItemsList(newList, oldList) {
      if (newList.length < oldList.length && this.selectedItem.id.length > 0)
        if (newList.length != 0) {
          let _index = oldList.findIndex((item: any) => item.id == this.selectedItem.id)
          if (_index + 1 > newList.length)
            this.selectedItem = newList[_index - 1]
          else
            this.selectedItem = newList[_index]
        } else {
          this.selectedItem = {description: "", id: "", price: 0, quantity: 0} as WorkDocumentItem
        }
    },

  },
  mounted() {
    //for reset function need to setOriginalSplitItems
    this.setOriginalSplitItems(this.workDocumentItems)
    this.clearItems()
  },
  unmounted() {
    //if user click on select table or goldyLocks
    this.copyListDividedPerson()
    this.clearlistDividedPerson()
    this.clearSplitByPeople()
    this.clearNumOfPeople()
    this.saveTableItems()

  }
})
