
import {defineComponent} from "vue";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {swal} from "@/libs/POSSwal";
import POSnotyf from "@/libs/POSnotyf";

export default defineComponent({
  name: 'DeliveryView',
  components: {
    KeyboardInput
  },

  data() {
    return {
      selectedCustomerID: 0 as number,
      selectedCustomerData: null as any,
      customerPendingPickups: [] as any,
    }
  },
  computed: {
    hasSelectedCustomer(): boolean {
      return this.selectedCustomerID !== 0
    },
    ...mapGetters(['customerSearchString', 'customersSearchResults']),
    customerSearchString: {
      get(): string {
        return this.customerSearchString
      },
      set(newString: string) {
        if (newString.length > 0) {
          this.setCustomerSearchString(newString)
          this.searchCustomers()
        }
      }
    },
  },
  methods: {
    ...mapMutations(['setCustomerSearchString', 'clearCustomerSearchResults']),
    ...mapActions(['searchCustomers', 'fetchCustomerPendingPickups', 'setLineQuantityAsProcessed']),

    // select customer and fetch corresponding pending pickups
    async setCustomerID(customerID: number, customerData: any) {
      this.selectedCustomerID = customerID
      this.selectedCustomerData = customerData

      // fetch selected customer pending pickups
      try {
        this.customerPendingPickups = await this.fetchCustomerPendingPickups(customerID)
      } catch (e) {
        console.error(e)
      }
    },
    async processPendingLine(lineID: number) {

      // show line delivered confirmation
      const deliveredConfirmation = await swal.confirm(`Deseja marcar uma unidade deste artigo como <strong>entregue</strong>?`)

      if (deliveredConfirmation) {

        // set line as delivered
        await this.setLineQuantityAsProcessed(lineID)

        // fetch selected customer pending pickups
        try {
          this.customerPendingPickups = await this.fetchCustomerPendingPickups(this.selectedCustomerID)
          POSnotyf.success(`Artigo marcada como <strong>entregue</strong> com sucesso!`)
        } catch (e) {
          console.error(e)
          POSnotyf.error(`Não foi possivel marcar o artigo como entregue!`)
        }
      }
    },
    clearCustomerID() {
      this.selectedCustomerID = 0
    },
    roundValue(value: string): number {
      return parseInt(value)
    }
  },
  mounted() {
    this.clearCustomerSearchResults()
    this.selectedCustomerID = 0
  }
})
