
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";
import {XIcon} from "@heroicons/vue/solid"

export default defineComponent({
  name: 'ModalVATSelection',
  components: {NumericKeyboard, XIcon},
  props: [],
  data() {
    return {
      minimumVATChars: 9,
      value: '',
    }
  },
  computed: {
    validVAT() {
      return this.value.trim().length >= this.minimumVATChars
    }
  },
  methods: {
    ...mapActions(['setCustomerByVAT', 'closeActiveModal']),
    numpadClicked(value: string) {
      if (value === "<") {
        if (this.value) {
          this.value = this.value.slice(0, -1)
        }
      } else {
        this.value += value;
      }
    },
    close() {
      this.value = ''
      this.closeActiveModal()
    },
    async save() {
      if (this.validVAT) {
        await this.setCustomerByVAT(this.value.trim());
        this.closeActiveModal();
      }
    }
  },
  mounted() {
  }
})
