import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "bg-gray-100 lg:w-1/3 rounded shadow p-8 max-h-screen" }
const _hoisted_2 = { class: "flex justify-between items-center text-black font-semibold text-xl select-none mb-5" }
const _hoisted_3 = { class: "flex justify-end" }
const _hoisted_4 = { class: "flex justify-items-stretch" }
const _hoisted_5 = {
  ref: "searchItemsList",
  class: "flex flex-col justify-items-stretch mt-8 h-96 overflow-x-hidden overflow-y-scroll"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "border py-3 px-4 bg-white transition duration-75 hover:bg-gray-200 shadow flex justify-between items-center" }
const _hoisted_8 = { class: "text-sm md:text-xl font-semibold" }
const _hoisted_9 = { class: "text-gray-500 text-sm" }
const _hoisted_10 = { class: "bg-blue-100 text-gray-600 p-1 rounded shadow font-semibold flex gap-1" }
const _hoisted_11 = { class: "grid grid-cols-2 gap-2 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyboardInput = _resolveComponent("KeyboardInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex gap-4 items-center" }, [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          "stroke-width": "1.5",
          stroke: "currentColor",
          class: "h-8"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            d: "m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          })
        ]),
        _createElementVNode("p", null, "Pesquisar artigos")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "bg-red-400 text-center hover:bg-red-500 rounded text-white h-10 w-10"
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            class: "h-full w-full",
            fill: "none",
            viewBox: "0 0 24 24",
            stroke: "currentColor",
            "stroke-width": "2"
          }, [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M6 18L18 6M6 6l12 12"
            })
          ], -1)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_KeyboardInput, {
        onEnterPressed: _ctx.selectFirstItem,
        "auto-focus": "true",
        modelValue: _ctx.itemSearchString,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemSearchString) = $event))
      }, null, 8, ["onEnterPressed", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSearchItems, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "cursor-pointer",
          onClick: ($event: any) => (_ctx.fnAddItem(item.cod_barras))
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(item.nome), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(item.cod_barras), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_10, [
                _cache[6] || (_cache[6] = _createElementVNode("span", null, "€", -1)),
                _createElementVNode("span", null, _toDisplayString(item.pvpfinal), 1)
              ])
            ])
          ])
        ], 8, _hoisted_6))
      }), 256))
    ], 512),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.scrollUp && _ctx.scrollUp(...args))),
        class: "bg-white hover:bg-gray-50 border shadow flex justify-center"
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          "stroke-width": "1.5",
          stroke: "currentColor",
          class: "h-12"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            d: "m4.5 18.75 7.5-7.5 7.5 7.5"
          }),
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            d: "m4.5 12.75 7.5-7.5 7.5 7.5"
          })
        ], -1)
      ])),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.scrollDown && _ctx.scrollDown(...args))),
        class: "bg-white hover:bg-gray-50 border shadow flex justify-center"
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("svg", {
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          "stroke-width": "1.5",
          stroke: "currentColor",
          class: "h-12"
        }, [
          _createElementVNode("path", {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            d: "m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
          })
        ], -1)
      ]))
    ])
  ]))
}