import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-4 p-1 lg:p-4 text-gray-600 flex flex-col"
}
const _hoisted_2 = { class: "flex flex-col mt-2 lg:mt-4" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "grid grid-cols-2 gap-4 my-2 lg:my-4" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "border-t grid grid-cols-2 lg:flex lg:flex-col gap-4 pt-1 lg:pt-4" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = {
  key: 1,
  class: "flex h-full w-full justify-center items-center p-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyboardInput = _resolveComponent("KeyboardInput")!

  return (_ctx.buttonData.position >= 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-sm lg:text-xl font-semibold border-b" }, " Editar botão ", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("label", {
              for: "label",
              class: "font-semibold sm:text-xs lg:text-base mb-1"
            }, "Texto", -1)),
            _createVNode(_component_KeyboardInput, {
              class: "border lg:p-1 sm:text-xs lg:text-base",
              id: "label",
              type: "text",
              modelValue: _ctx.buttonData.label,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.buttonData.label) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[7] || (_cache[7] = _createElementVNode("label", {
                for: "backgroundColor",
                class: "font-semibold sm:text-xs lg:text-base"
              }, "Cor fundo", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "backgroundColor",
                type: "color",
                class: "w-full",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.buttonData.backgroundColor) = $event))
              }, null, 512), [
                [_vModelText, _ctx.buttonData.backgroundColor]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[8] || (_cache[8] = _createElementVNode("label", {
                for: "textColor",
                class: "font-semibold sm:text-xs lg:text-base"
              }, "Cor texto", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "textColor",
                type: "color",
                class: "w-full",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.buttonData.textColor) = $event))
              }, null, 512), [
                [_vModelText, _ctx.buttonData.textColor]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[9] || (_cache[9] = _createElementVNode("p", { class: "font-semibold sm:text-xs lg:text-base" }, "Função:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "hidden",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.buttonData.fn) = $event))
              }, null, 512), [
                [_vModelText, _ctx.buttonData.fn]
              ]),
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.fnOpenModal('ModalPageFunctions'))),
                class: "w-full h-full truncate bg-green-200 hover:bg-green-300 rounded-sm p-0 lg:p-2 sm:text-xs lg:text-base"
              }, _toDisplayString(_ctx.buttonData.fnDescription ?? 'Sem Função'), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[10] || (_cache[10] = _createElementVNode("label", {
                for: "btnParam",
                class: "font-semibold sm:text-xs lg:text-base"
              }, "Paramêtro", -1)),
              _createVNode(_component_KeyboardInput, {
                type: "text",
                id: "btnParam",
                class: "border lg:p-1 sm:text-xs lg:text-base",
                modelValue: _ctx.buttonData.params,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.buttonData.params) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[12] || (_cache[12] = [
        _createElementVNode("div", { class: "text-gray-600 animate-bounce text-center" }, "Clique num botão para editar", -1)
      ])))
}