
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {v4} from "uuid";

export default defineComponent({
  name: 'EditModeFooterSection',
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['currentPage', 'currentEditModeButtonData', 'getCopiedStyle', 'getCopiedButton', 'canGoForwardPagination'])
  },
  methods: {
    ...mapMutations(['clearEditModeButtonData', 'styleClipboard', 'buttonClipboard', 'editModeButtonData']),
    ...mapActions(['toggleEditMode', 'removePageButton', 'applyCopiedStyleToCurrentButton', 'createNewPage']),
    save() {
      this.toggleEditMode()
    },
    async remove() {
      await this.removePageButton({
        pageNumber: this.currentPage,
        buttonPosition: parseInt(this.currentEditModeButtonData.position),
      })

      this.clearEditModeButtonData()
    },
    copyStyle() {
      this.styleClipboard({
        textColor: this.currentEditModeButtonData.textColor,
        backgroundColor: this.currentEditModeButtonData.backgroundColor,
      })

      /* clear current selected button data */
      this.clearEditModeButtonData()
    },
    pasteStyle() {
      this.applyCopiedStyleToCurrentButton()
    },
    copyButton() {
      /* copy select button data to the clipboard */
      this.buttonClipboard(this.currentEditModeButtonData)

      /* clear current selected button data */
      this.clearEditModeButtonData()
    },
    pasteButton() {
      /* store position */
      let position = this.currentEditModeButtonData.position

      /* get copied button data */
      /* must be copied using the spread operator in order to deep copy it */
      let copiedButton = {...this.getCopiedButton}

      /* apply new position*/
      copiedButton.position = position

      /* generate new UUID */
      copiedButton.uuid = v4()

      /* paste copied button data into edit mode UI */
      this.editModeButtonData(copiedButton)
    }
  },
  mounted() {
  }
})
