import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "container mx-auto h-screen flex justify-center items-center bg-gray-50" }
const _hoisted_2 = { class: "bg-white w-80 sm:w-96 rounded shadow p-6" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "py-4" }
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "flex gap-2 justify-center" }
const _hoisted_8 = {
  key: 0,
  class: "py-4"
}
const _hoisted_9 = { class: "py-2" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = { class: "py-2" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = {
  key: 1,
  class: "py-2"
}
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "grid grid-cols-3 pin-keypad" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = {
  key: 0,
  class: "flex justify-center items-center animate-spin"
}
const _hoisted_18 = {
  xmlns: "http://www.w3.org/2000/svg",
  class: "h-6 w-6",
  style: {"transform":"scaleX(-1)"},
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor",
  "stroke-width": "2"
}
const _hoisted_19 = { key: 0 }

import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import POSnotyf from "@/libs/POSnotyf";
import {useRouter} from "vue-router";


import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import Keyboard from "@/components/Keyboards/Keyboard.vue";

const __default__ = defineComponent({
    name: 'Login',
    components: {
        Keyboard,
        KeyboardInput,
    },
    computed: {
        ...mapGetters(['isKeyboardOpen', 'isElectronApp']),
    },
    methods: {
        ...mapActions(['fetchToken', 'fnQuit']),
    },
})

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const store = useStore()
const router = useRouter()

/* login type variable */
const loginType = ref('pin')
const currentPIN = ref('')
const password = ref('')
const loading = ref(false)
const minimumPINLength = 6

const alias = computed({
    get(): string {
        return store.getters.alias
    },
    set(newAlias: string) {
        store.commit('setAlias', newAlias)
    }
})

const username = computed({
    get(): string {
        return store.getters.username
    },
    set(newUsername: string) {
        store.commit('setUsername', newUsername)
    }
})

/* PIN watcher */
watch(currentPIN, async () => {
    if (currentPIN.value.length === minimumPINLength) {
        await login()
    }
})

const login = async () => {

    /* length checks */
    if (loginType.value === 'pin' && currentPIN.value.length < minimumPINLength) {
        return false;
    }

    try {
        loading.value = true

        if (loginType.value === 'password') {
            await store.dispatch('fetchToken', {
                username: username.value,
                password: password.value,
            })
        } else if (loginType.value === 'pin') {
            await store.dispatch('fetchTokenUsingPIN', currentPIN.value)
        }

        loading.value = false
        await router.push('/')
    } catch (e) {
        /* reset loading flag */
        loading.value = false

        /* reset PIN value */
        currentPIN.value = ''

        /* show error */
        POSnotyf.error(`Senha incorreta ou licença inválida!`)
        console.error(e.message)
    }
}

/**
 * clear inserted PIN
 */
const clearPIN = () => {
    currentPIN.value = ''
}

/**
 * set current login type
 * @param newLoginType
 */
const setLoginType = (newLoginType: string) => {
    loginType.value = newLoginType
    clearPIN()
}

/**
 * append number to current PIN
 * @param loginNumber
 */
const appendLoginNumber = (loginNumber: string) => {
    currentPIN.value = currentPIN.value.concat(loginNumber)
}

const removeLoginNumber = () => {
    currentPIN.value = currentPIN.value.substr(0, currentPIN.value.length - 1)
}

onMounted(() => {
    store.dispatch('clearLocalStorage')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(login, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "flex justify-center pb-4" }, [
                  _createElementVNode("img", {
                    class: "w-64",
                    src: "images/POS.png",
                    alt: "logo"
                  })
                ], -1)),
                _cache[18] || (_cache[18] = _createElementVNode("p", { class: "font-semibold text-sm" }, "Endereço do seu Goldylocks", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-xs text-gray-400" }, "https://app.goldylocks.pt/", -1)),
                  _createVNode(KeyboardInput, {
                    inputClass: 'text-left text-xs',
                    mainClass: 'border-b shadow-sm',
                    modelValue: alias.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((alias).value = $event)),
                    required: ""
                  }, null, 8, ["modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (setLoginType('pin'))),
                class: _normalizeClass([{'border-gray-800':loginType.value === 'pin'}, "p-3 rounded shadow border flex flex-col items-center"])
              }, _cache[19] || (_cache[19] = [
                _createStaticVNode("<div class=\"text-xs font-bold text-gray-800\" data-v-50482279>PIN</div><svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" data-v-50482279><path d=\"M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z\" fill=\"currentColor\" data-v-50482279></path><path d=\"M9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16C16 15.4477 15.5523 15 15 15H9Z\" fill=\"currentColor\" data-v-50482279></path><path d=\"M9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z\" fill=\"currentColor\" data-v-50482279></path><path d=\"M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z\" fill=\"currentColor\" data-v-50482279></path><path d=\"M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z\" fill=\"currentColor\" data-v-50482279></path><path d=\"M16 9C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.55228 15.4477 9 16 9Z\" fill=\"currentColor\" data-v-50482279></path><path d=\"M17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12Z\" fill=\"currentColor\" data-v-50482279></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6ZM6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V6C5 5.44772 5.44772 5 6 5Z\" fill=\"currentColor\" data-v-50482279></path></svg>", 2)
              ]), 2),
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (setLoginType('password'))),
                class: _normalizeClass([{'border-gray-800':loginType.value === 'password'}, "p-3 rounded shadow border flex flex-col items-center"])
              }, _cache[20] || (_cache[20] = [
                _createElementVNode("div", { class: "text-xs font-bold text-gray-800" }, "Utilizador", -1),
                _createElementVNode("svg", {
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  "stroke-width": "1.5",
                  stroke: "currentColor",
                  class: "w-6 h-6"
                }, [
                  _createElementVNode("path", {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    d: "M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  })
                ], -1)
              ]), 2)
            ]),
            (loginType.value === 'password')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[21] || (_cache[21] = _createElementVNode("p", { class: "font-semibold" }, "Utilizador", -1)),
                      _createVNode(KeyboardInput, {
                        inputClass: 'text-center',
                        mainClass: 'border-b shadow-sm',
                        modelValue: username.value,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((username).value = $event)),
                        required: ""
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _cache[22] || (_cache[22] = _createElementVNode("p", { class: "font-semibold" }, "Senha", -1)),
                      _createVNode(KeyboardInput, {
                        inputClass: 'text-center',
                        mainClass: 'border-b shadow-sm',
                        type: 'password',
                        modelValue: password.value,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((password).value = $event)),
                        required: ""
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (loginType.value === 'pin')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", null, [
                    _createElementVNode("progress", {
                      value: currentPIN.value.length,
                      max: "6",
                      class: "w-full h-2"
                    }, null, 8, _hoisted_14)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (appendLoginNumber('1')))
                    }, "1"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (appendLoginNumber('2')))
                    }, "2"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (appendLoginNumber('3')))
                    }, "3"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (appendLoginNumber('4')))
                    }, "4"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (appendLoginNumber('5')))
                    }, "5"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (appendLoginNumber('6')))
                    }, "6"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (appendLoginNumber('7')))
                    }, "7"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (appendLoginNumber('8')))
                    }, "8"),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (appendLoginNumber('9')))
                    }, "9"),
                    _createElementVNode("a", {
                      href: "#",
                      class: "flex justify-center",
                      onClick: removeLoginNumber
                    }, _cache[23] || (_cache[23] = [
                      _createElementVNode("svg", {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24",
                        "stroke-width": "1.5",
                        stroke: "currentColor",
                        class: "w-6 h-6"
                      }, [
                        _createElementVNode("path", {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
                        })
                      ], -1)
                    ])),
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (appendLoginNumber('0')))
                    }, "0")
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                type: "submit",
                disabled: loading.value,
                class: _normalizeClass(["w-full rounded text-white p-2", loading.value ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-400 hover:bg-green-500'])
              }, [
                (loading.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(), _createElementBlock("svg", _hoisted_18, _cache[24] || (_cache[24] = [
                        _createElementVNode("path", {
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          d: "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        }, null, -1)
                      ])))
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(" ENTRAR ")
                    ], 64))
              ], 10, _hoisted_16)
            ])
          ])
        ], 32),
        (_ctx.isElectronApp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createElementVNode("button", {
                onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.fnQuit && _ctx.fnQuit(...args))),
                class: "w-full rounded bg-red-400 text-white px-2 py-1 hover:bg-red-500 mt-2 text-sm"
              }, "FECHAR ")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isKeyboardOpen)
      ? (_openBlock(), _createBlock(Keyboard, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})