
import {defineComponent, ref, onMounted, Ref} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import POSnotyf from "@/libs/POSnotyf";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";

export default defineComponent({
  name: "ModalItemSearch",
  setup() {
  },
  data() {
    return {}
  },
  components: {KeyboardInput},
  props: [],
  computed: {
    ...mapGetters(['getSearchItems', 'getItemSearchString']),
    itemSearchString: {
      get(): string {
        return this.getItemSearchString
      },
      set(value: string) {
        this.setItemsSearchString(value)
      }
    }
  },
  methods: {
    ...mapMutations(['setItemsSearchString']),
    ...mapActions(['closeActiveModal', 'fnAddItem']),
    scrollUp() {
      this.$refs.searchItemsList.scrollBy({top: -74, behavior: 'smooth'});
    },
    scrollDown() {
      this.$refs.searchItemsList.scrollBy({top: 74, behavior: 'smooth'});
    },
    /**
     * select the item on the first result
     */
    selectFirstItem() {
      // check if the first row exists
      if (this.getSearchItems.length > 0) {
        // add the item to the working document
        this.fnAddItem(this.getSearchItems[0].cod_barras)
      }
    },
    close() {
      this.closeActiveModal()
    },
    escapeKey(event: any) {

      // escape key closes modal
      if (event.key === `Escape`) {
        this.closeActiveModal()
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.escapeKey);
  },
  unmounted() {
    window.removeEventListener('keydown', this.escapeKey);
  }
})
