
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "DocumentNifLabel",
  data() {
    return {
      nifName: "000000000"
    }
  },
  props: [],
  computed: {
    ...mapGetters(['getSelectedCustomerData']),
  },
  methods: {},
  watch: {},
  mounted() {
  }
})
