import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "primary-bg-color h-full sm:h-screen w-full flex flex-col justify-center items-center p-14 gap-6" }
const _hoisted_2 = { class: "w-full flex flex-col sm:flex-row justify-between" }
const _hoisted_3 = { class: "flex gap-2 mt-4" }
const _hoisted_4 = { class: "uppercase font-bold text-white" }
const _hoisted_5 = { class: "flex gap-2 mt-1" }
const _hoisted_6 = { class: "uppercase font-semibold text-white text-sm" }
const _hoisted_7 = { class: "w-full flex gap-4 flex-wrap justify-around" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flex flex-col gap-1 mt-2" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "flex flex-col sm:flex-row-reverse gap-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("div", null, [
        _createElementVNode("div", { class: "text-white uppercase text-4xl sm:text-2xl font-semibold" }, "ABERTURA CAIXA"),
        _createElementVNode("div", { class: "text-gray-200 font-thin text-sm sm:text-base" }, [
          _createTextVNode("Selecione a "),
          _createElementVNode("strong", null, "caixa"),
          _createTextVNode(" a utilizar neste posto.")
        ])
      ], -1)),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "uppercase text-white font-thin" }, "Utilizador:", -1)),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.userData.username), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "uppercase text-white font-thin text-sm" }, "Data/Hora:", -1)),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.dateTime), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSessionStations, (sessionStation) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass([[{'ring-4 ring-green-600':this.selectedSessionStation === sessionStation}], "primary-button p-6 h-15 sm:h-20 w-15 md:w-36 text-xs uppercase"]),
          onClick: ($event: any) => (_ctx.selectSessionStation(sessionStation))
        }, _toDisplayString(sessionStation.descricao_caixa), 11, _hoisted_8))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openSessionCapitalModal && _ctx.openSessionCapitalModal(...args))),
        innerHTML: _ctx.workingCapitalButtonText,
        class: "cursor-pointer bg-amber-600 text-white hover:bg-amber-700 py-2 px-4 sm:py-4 rounded font-thin select-none uppercase"
      }, null, 8, _hoisted_10)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        class: "bg-green-600 text-white hover:bg-green-700 w-64 py-4 rounded font-semibold text-xl select-none",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.startSession && _ctx.startSession(...args)))
      }, "INICIAR SESSÃO "),
      _createElementVNode("button", {
        class: "bg-red-500 hover:bg-red-600 text-white text-sm @hover:bg-raisinBlack-700 w-64 py-1 rounded select-none",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.fnLogout && _ctx.fnLogout(...args)))
      }, "CANCELAR ")
    ])
  ]))
}