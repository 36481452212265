
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";
import {swal} from "@/libs/POSSwal";

export default defineComponent({
  name: 'SessionStartView',
  components: {KeyboardInput},
  data() {
    return {
      selectedSessionStation: null as any,
      dateTime: '' as string,
    }
  },
  computed: {
    ...mapGetters(['sessionStations', 'userData', 'getSessionWorkingCapital']),
    getSessionStations(): any {

      // select first station
      if (this.sessionStations.length === 1) {
        this.selectSessionStation(this.sessionStations[0])
      }

      return this.sessionStations
    },
    workingCapitalButtonText(): string {
      if (this.getSessionWorkingCapital > 0) {
        return `<div class="flex flex-col justify-between items-center ">
                  <div class="text-xs">Fundo maneio</div>
                  <div class="font-bold">${this.getSessionWorkingCapital} &euro;</div>
              </div>`
      } else {
        return `<div class="flex justify-center gap-1 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                    Fundo de maneio
                </div>`
      }
    }
  },
  methods: {
    ...mapMutations(['unlockOperationsSection', 'activeView', 'sessionWorkingCapital']),
    ...mapActions(['newWorkDocument', 'open', 'fnLogout', 'fnOpenModal', 'fetchGoldylocksDateTime', 'startNewSession', "showStatusMessage", "closeStatusMessage"]),
    async selectSessionStation(sessionStation: any) {
      this.selectedSessionStation = sessionStation
      this.sessionWorkingCapital(sessionStation.fundo_maneio)
    },
    async startSession() {
      // check if the station has been selected
      if (this.selectedSessionStation) {

        this.unlockOperationsSection()

        // send API request to start user new session
        this.showStatusMessage('A iniciar sessão de caixa...')

        try {
          await this.startNewSession({
            station_id: this.selectedSessionStation.id_caixa,
            user_id: this.userData.id,
            start_value: this.getSessionWorkingCapital,
          })

          // start work document
          await this.newWorkDocument()

          this.closeStatusMessage()
        } catch (err) {

          // show error if the session cant be started
          console.error(err)
          await swal.error(err)
        }

      }
    },
    openSessionCapitalModal() {
      this.fnOpenModal('ModalWorkingCapital')
    }
  },
  async mounted() {
    this.dateTime = await this.fetchGoldylocksDateTime()
  }
})
