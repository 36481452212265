
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import PageButton from "@/components/PageButton.vue";

export default defineComponent({
  name: 'ItemsView',
  components: {PageButton},
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['currentButtonsPagination', 'currentPage', 'currentPageButtons', 'currentPageDimensions', 'maximumPageButtons', 'activeButtonUUID', 'currentEditModeButtonData', 'editMode', 'canGoBackPagination', 'canGoForwardPagination']),
    gridRowsClass() {
      return `grid-rows-${(this.currentPageDimensions as any).rows_mobile} sm:grid-rows-${(this.currentPageDimensions as any).rows}`
    },
    gridColumnsClass() {
      return `grid-cols-${(this.currentPageDimensions as any).columns_mobile} sm:grid-cols-${(this.currentPageDimensions as any).columns}`
    }
  },
  methods: {
    ...mapActions(['toggleEditMode', 'setButtonPosition', 'setActiveButtonUUID', 'fnMoveToNextPagination', 'fnMoveToPreviousPagination']),
    triggerClick() {
      // reactivate the moved button to continue movement with keys
      let el = document.querySelector(`[uuid="${this.activeButtonUUID}"]`)
      let eventClick = new Event('click')
      if (el) {
        el.dispatchEvent(eventClick)
      }
    },
    async moveButton(ev: any) {

      let {columns, rows} = this.currentPageDimensions
      let maximumPageButtons = this.maximumPageButtons

      if (this.editMode) {

        // variable to store the new calculated position
        let position: number = Number(this.currentEditModeButtonData.position)

        // convert values to numbers
        columns = Number(columns)
        rows = Number(rows)

        // change the position index
        if (ev.key === "ArrowRight" && (position + 1) % columns !== 0) {
          position++
        } else if (ev.key === "ArrowLeft" && (position - 1) >= 0 && position % columns !== 0) {
          position--
        } else if (ev.key === "ArrowUp" && (position > (columns - 1)) && position >= ((maximumPageButtons * this.currentButtonsPagination) + columns)) {
          position -= columns
        } else if (ev.key === "ArrowDown" && position < ((maximumPageButtons * this.currentButtonsPagination) + maximumPageButtons) - columns) {
          position += columns
        }


        // change the button position on the array
        await this.setButtonPosition({
          pageNumber: this.currentPage,
          buttonUUID: this.activeButtonUUID,
          newPosition: position,
        })

        // reactivate the moved element to continue movement with keys
        this.triggerClick()
      }
    }
  },
  mounted() {
  }
})
