import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "text-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMobileOperations && _ctx.openMobileOperations(...args))),
    class: "h-16 w-screen bg-raisinBlack-600 hover:bg-gray-700 text-white flex justify-between items-center text-2xl"
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "font-semibold text-2xl" }, "Total: ", -1)),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.workDocumentTotal.toFixed(2)) + " €", 1)
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "ml-5 text-green-500 w-16 fle" }, [
      _createElementVNode("svg", {
        width: "32",
        height: "32",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M5.63605 7.75735L7.05026 6.34314L12.7071 12L7.05029 17.6568L5.63608 16.2426L9.87869 12L5.63605 7.75735Z",
          fill: "currentColor"
        }),
        _createElementVNode("path", {
          d: "M12.7071 6.34314L11.2929 7.75735L15.5356 12L11.2929 16.2426L12.7072 17.6568L18.364 12L12.7071 6.34314Z",
          fill: "currentColor"
        })
      ])
    ], -1))
  ]))
}