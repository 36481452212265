import {swal} from "@/libs/POSSwal";

const fnPages = {
    state: {
        previousPage: 0,
    },
    getters: {
        previousPage(state: any) {
            return state.previousPage
        }
    },
    mutations: {
        savePreviousPage(state: any, pageNumber: number) {
            state.previousPage = pageNumber
        }
    },
    actions: {
        fnPage(state: any, pageNumber: number) {
            state.commit('savePreviousPage', state.getters.currentPage)
            state.commit('setCurrentPage', pageNumber)
        },
        fnPreviousPage(state: any) {
            state.commit('setCurrentPage', state.getters.previousPage)
        },
        fnMoveToNextPagination(state: any) {
            if (state.getters.canGoForwardPagination)
                state.commit('moveToNextButtonsPagination')
        },
        fnMoveToPreviousPagination(state: any) {
            if (state.getters.canGoBackPagination)
                state.commit('moveToPreviousButtonsPagination')
        },
        fnGeneratePages: async function (state: any) {
            /* confirm user operation */
            const userConfirmation = await swal.confirm(`<strong>Esta operação elimina todas as configurações feitas!</strong><br>Deseja continuar?`)

            if (userConfirmation) {
                /* ask user to keep the main page configuration */
                const keepMainPage = await swal.confirm(`Deseja manter a página inicial?`,{
                    cancelButtonText: `Não`,
                })

                /* set to keep or destroy main page */
                if (keepMainPage) {
                    state.commit('keepMainPage')
                } else {
                    state.commit('dontKeepMainPage')
                }

                /* number of columns and rows */
                const columns = await swal.range('Insira o numero de colunas por página:', 2, 8, 5)
                const rows = await swal.range('Insira o numero de linhas por página:', 2, 8, 5)

                /* save to the state the columns and rows to be used */
                state.commit('setDefaultColumns', columns)
                state.commit('setDefaultRows', rows)

                /* render the new pages */
                state.dispatch('generatePages')
            }

        },

        fnOpenView: function (state: any, view: string) {
            state.commit('activeView', view)
        },
        fnOpenModal: function (state: any, modal: string) {
            state.dispatch('openModal', modal)
        }
    },
}

export {
    fnPages
}
