
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "MobileHeader",
  data() {
    return {
      componentName: "MobileHeader"
    }
  },
  components: {},
  props: [],
  computed: {
    ...mapGetters(['userUsesSession', 'getSessionData', 'currentTable']),
    checkUserSession: function () {
      return ((this.userUsesSession && Object.keys(this.getSessionData).length > 0) || !this.userUsesSession)
    }
  },
  methods: {
    ...mapMutations(['setCurrentPage']),
    ...mapActions(['fnOpenView', 'fetchSessionData']),
    goToInitialPage() {
      if (this.checkUserSession) {
        this.fnOpenView('Items')
        this.setCurrentPage(0)
      }
    },
    openView(viewName: string) {
      this.fnOpenView(viewName)
    },
  },
  watch: {},
  mounted() {
  }
})
