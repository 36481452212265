import {createStore} from 'vuex'
import {Settings} from "@/store/modules/Settings";
import {Authentication} from "@/store/modules/Authentication";
import {Items} from "@/store/modules/Items";
import {Pages} from "@/store/modules/Pages";
import {Families} from "@/store/modules/Families";
import {Page} from "@/store/modules/Page";
import {WorkDocument} from "@/store/modules/WorkDocument";
import {DocumentTypes} from "@/store/modules/DocumentTypes";
import {Series} from "@/store/modules/Series";
import {Customers} from "@/store/modules/Customers";
import {POSFunctions} from "@/store/modules/POSFunctions";
import {fnPages} from "@/store/modules/POSFunctions/fnPages";
import {fnItems} from "@/store/modules/POSFunctions/fnItems";
import {fnAuthentication} from "@/store/modules/POSFunctions/fnAuthentication";
import {OperationsItems} from "@/store/modules/OperationsItems";
import {EditMode} from "@/store/modules/EditMode";
import {ContentView} from "@/store/modules/ContentView";
import {PaymentMethods} from "@/store/modules/PaymentMethods";
import {StatusView} from "@/store/modules/StatusView";
import {Agents} from "@/store/modules/Agents";
import {Modals} from "@/store/modules/Modals";
import {Keyboard} from "@/store/modules/Keyboard";
import {Invoicing} from "@/store/modules/Invoicing";
import {fnWorkingDocument} from "@/store/modules/POSFunctions/fnWorkingDocument";
import {fnCustomers} from "@/store/modules/POSFunctions/fnCustomers";
import {Electron} from "@/store/modules/Electron";
import {fnSystem} from "@/store/modules/POSFunctions/fnSystem";
import {Sessions} from "@/store/modules/Sessions";
import {fnSessions} from "@/store/modules/POSFunctions/fnSessions";
import {fnInvoiceCopy} from "@/store/modules/POSFunctions/fnInvoiceCopy";
import {TablesManagement} from "@/store/modules/TablesManagement";
import {System} from "@/store/modules/System";
import {SplitInvoice} from "@/store/modules/SplitInvoice";
import {fnTableManagement} from "@/store/modules/POSFunctions/fnTableManagement";
import {fnHardware} from "@/store/modules/POSFunctions/fnHardware";
import {fnPickup} from "@/store/modules/POSFunctions/fnPickup";
import {Pickup} from "@/store/modules/Pickup";
import {Base64Images} from "@/store/modules/Base64Images";
import {fnReports} from "@/store/modules/POSFunctions/fnReports";
import {Reports} from "@/store/modules/Reports";
import {LicensePlate} from "@/store/modules/LicensePlate";
import {MoveTable} from "@/store/modules/MoveTable";
import {fnFeaturesItems} from "./modules/POSFunctions/fnFeaturesItems";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Settings,
        Authentication,
        Families,
        Items,
        Pages,
        Page,
        WorkDocument,
        DocumentTypes,
        Series,
        Customers,
        POSFunctions,
        fnPages,
        fnItems,
        fnAuthentication,
        fnWorkingDocument,
        fnCustomers,
        fnSystem,
        OperationsItems,
        EditMode,
        ContentView,
        PaymentMethods,
        StatusView,
        Agents,
        Modals,
        Keyboard,
        Invoicing,
        Electron,
        Sessions,
        fnSessions,
        fnInvoiceCopy,
        TablesManagement,
        System,
        SplitInvoice,
        fnTableManagement,
        fnHardware,
        Pickup,
        fnPickup,
        Base64Images,
        fnReports,
        Reports,
        LicensePlate,
        MoveTable,
        fnFeaturesItems,
    }
})