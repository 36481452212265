
import {defineComponent} from "vue";
import {ChevronDownIcon} from "@heroicons/vue/outline";
import {mapActions, mapGetters, mapMutations} from "vuex";
import contentView from "@/components/ContentView.vue";

export default defineComponent({
  name: "OperationsFastMenu",
  data() {
    return {
      componentName: "OperationsFastMenu"
    }
  },
  emits: ['close'],
  props: [],
  components: {
    ChevronDownIcon
  },
  computed: {
    contentView() {
      return contentView
    },
    ...mapGetters(['isTableSelected','activeView', 'workDocumentItemsCount']),
  },
  methods: {
    ...mapActions(['toggleEditMode', 'fnOpenModal', 'fnOpenView','addNewFamiliesToPos']),
    ...mapMutations(['setMoveTable']),
    setEditMode() {
      this.toggleEditMode()
      this.close()
    },
    openModal(modalName: string) {
      this.fnOpenModal(modalName)
      this.close()
    },
    openView(viewName: string) {
      this.fnOpenView(viewName)
      this.close()
    },
    close: function () {
      this.$emit('close');
    },
    moveTable(){
      this.setMoveTable(true)
      this.fnOpenView('tableSelection')
      this.close()
    },

  },
  watch: {},
  mounted() {
  }
})
