
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import ModalItemQuantity from "@/components/Modals/ModalItemQuantity.vue";
import ModalItemPrice from "@/components/Modals/ModalItemPrice.vue";
import ModalClientSearch from "@/components/Modals/ModalClientSearch.vue";
import ModalDocumentType from "@/components/Modals/ModalDocumentType.vue";
import ModalDocumentSerie from "@/components/Modals/ModalDocumentSerie.vue";
import ModalNewCustomer from "@/components/Modals/ModalNewCustomer.vue";
import ModalPageFunctions from "@/components/Modals/ModalPageFunctions.vue";
import ModalPaymentMethods from "@/components/Modals/ModalPaymentMethods.vue";
import ModalTableSelect from "@/components/Modals/ModalTableSelect.vue";
import ModalSwitchEmployee from "@/components/Modals/ModalSwitchEmployee.vue";
import ModalVATSelection from "@/components/Modals/ModalVATSelection.vue";
import ModalWorkingCapital from "@/components/Modals/ModalWorkingCapital.vue";
import ModalPriceLine from "@/components/Modals/ModalPriceLine.vue";
import ModalItemObservations from "@/components/Modals/ModalItemObservations.vue";
import ModalSendEmail  from "@/components/Modals/ModalSendEmail.vue";
import ModalPersonQuantity from "@/components/Modals/ModalPersonQuantity.vue";
import ModalPlate from "@/components/Modals/ModalPlate.vue";
import ModalItemSearch from "@/components/Modals/ModalItemSearch.vue";
import ModalFeatures from "@/components/Modals/ModalFeatures.vue";


export default defineComponent({
  name: 'Modals',
  components: {
    ModalItemQuantity,
    ModalItemPrice,
    ModalClientSearch,
    ModalDocumentType,
    ModalDocumentSerie,
    ModalPriceLine,
    ModalNewCustomer,
    ModalPaymentMethods,
    ModalPageFunctions,
    ModalTableSelect,
    ModalSwitchEmployee,
    ModalVATSelection,
    ModalWorkingCapital,
    ModalItemObservations,
    ModalSendEmail,
    ModalPersonQuantity,
    ModalPlate,
    ModalItemSearch,
    ModalFeatures,
  },
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getActiveModal']),
    activeModal() {
      return this.getActiveModal
    }
  },
  methods: {
    ...mapActions(['closeActiveModal']),
  },
  mounted() {
  }
})
