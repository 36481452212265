
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NumericKeyboard from "@/components/Keyboards/NumericKeyboard.vue";

export default defineComponent({
    name: 'ModalItemQuantity',
    components: {NumericKeyboard},
    props: [],
    data() {
        return {
            inputQuantity: '',
        }
    },
    computed: {
        ...mapGetters(['itemWaitingForQuantity']),
    },
    methods: {
        ...mapActions(['closeActiveModal', 'setAddItemQuantity', 'fnAddItem']),
        ...mapMutations(['clearItemWaitingForQuantity']),
        numpadClicked(value: string) {
            if (value === '<') {
                // delete last number
                if (this.inputQuantity) {
                    this.inputQuantity = this.inputQuantity.slice(0, -1);
                }
            } else {
                this.inputQuantity += value
            }
        },
        close() {
            this.clearItemWaitingForQuantity()
            this.closeActiveModal()
        },
        save() {
            this.setAddItemQuantity(parseFloat(this.inputQuantity))
            this.closeActiveModal()

            /* add item waiting for quantity */
            if (this.itemWaitingForQuantity !== null) {
                this.fnAddItem(this.itemWaitingForQuantity)
            }
        }
    },
    mounted() {
    }
})
