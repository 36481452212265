
import {defineComponent} from "vue";
import OperationsSectionHeader from "@/components/OperationsSectionHeader.vue";
import OperationsSectionFooter from "@/components/OperationsSectionFooter.vue";
import OperationsSectionDetails from "@/components/OperationsSectionDetails.vue";
import {mapGetters} from "vuex";
import EditModeDetailsSection from "@/components/EditModeDetailsSection.vue";
import EditModeFooterSection from "@/components/EditModeFooterSection.vue";

export default defineComponent({
  name: 'OperationsSection',
  components: {EditModeFooterSection, EditModeDetailsSection, OperationsSectionDetails, OperationsSectionFooter, OperationsSectionHeader},
  props: [],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['editMode']),
  },
  methods: {},
  mounted() {
  }
})
