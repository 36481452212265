import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main h-screen select-none text-white flex flex-col items-center justify-around gap-1 p-14" }
const _hoisted_2 = { class: "w-full flex justify-evenly items-center" }
const _hoisted_3 = {
  key: 0,
  class: "w-40"
}
const _hoisted_4 = { class: "w-full flex justify-evenly items-center" }
const _hoisted_5 = { class: "flex flex-col items-center" }
const _hoisted_6 = { class: "w-72 sm:w-96 grid grid-cols-6 gap-1" }
const _hoisted_7 = { class: "col-span-4 font-semibold text-xl" }
const _hoisted_8 = { class: "flex items-center gap-3" }
const _hoisted_9 = { class: "flex flex-col w-3/4" }
const _hoisted_10 = { class: "text-sm sm:text-lg truncate font-semibold" }
const _hoisted_11 = { class: "text-lg truncate" }
const _hoisted_12 = { class: "col-span-1 font-semibold text-xl" }
const _hoisted_13 = { class: "col-span-1 font-semibold text-xl" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "grid gap-2 grid-cols-2 lg:grid-cols-3" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "w-72 py-2" }
const _hoisted_19 = { class: "flex items-center justify-center text-xl font-light opacity-80" }
const _hoisted_20 = { class: "w-1/2 text-right" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "flex items-center justify-center" }
const _hoisted_24 = { class: "w-1/2 text-2xl font-light" }
const _hoisted_25 = { class: "w-1/2" }
const _hoisted_26 = { class: "w-72" }
const _hoisted_27 = { class: "flex flex-col gap-3 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserIcon = _resolveComponent("UserIcon")!
  const _component_CalculatorIcon = _resolveComponent("CalculatorIcon")!
  const _component_NumericKeyboard = _resolveComponent("NumericKeyboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col items-center" }, [
          _createElementVNode("p", { class: "w-72 sm:w-96 text-center font-semibold p-2 text-xl" }, " Cliente ")
        ], -1)),
        (_ctx.currentTable.id_mesa !== 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fnSelectCustomer && _ctx.fnSelectCustomer(...args))),
                class: "button cursor-pointer select-none flex flex-col px-4 justify-center h-16 sm:h-24 font-thin text-sm sm:text-2xl"
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_UserIcon, { class: "h-12 w-1/4" }),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getSelectedCustomerData.nif || 'Consumidor Final'), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getSelectedCustomerData.nome), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.fnSelectCustomerVAT && _ctx.fnSelectCustomerVAT(...args))),
                class: "button cursor-pointer select-none flex flex-col px-8 justify-center items-center h-16 sm:h-24 font-thin text-sm sm:text-2xl"
              }, [
                _createVNode(_component_CalculatorIcon, { class: "hidden sm:block h-12" }),
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-xs font-semibold" }, "NIF", -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.getTransport && _ctx.getTransport(...args))),
                class: "button cursor-pointer select-none flex flex-col px-8 justify-center items-center h-16 sm:h-24 font-thin text-sm sm:text-2xl"
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("p", { class: "text-xs font-semibold" }, [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                    class: "size-6"
                  }, [
                    _createElementVNode("path", {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      d: "M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                    })
                  ]),
                  _createTextVNode(" Matricula")
                ], -1)
              ]))
            ])
          ])
        ])
      ])
    ]),
    (!_ctx.showChangeScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "font-semibold mt-6 p-6 text-xl" }, " Método de pagamento ", -1)),
          _createElementVNode("div", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethods, (paymentMethod) => {
              return (_openBlock(), _createElementBlock("div", {
                onClick: ($event: any) => (_ctx.selectMethod(paymentMethod)),
                key: paymentMethod.meio_pagamento,
                class: "button cursor-pointer select-none flex flex-col items-center text-center justify-center h-28 sm:h-40 w-40 sm:w-48 font-thin text-xl sm:text-2xl"
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon(paymentMethod)), { class: "h-12" })),
                _createTextVNode(" " + _toDisplayString(paymentMethod.meio_pagamento), 1)
              ], 8, _hoisted_16))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showChangeScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "font-semibold mt-6 text-4xl text-center" }, " Pagamento ", -1)),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-1/2" }, [
                _createElementVNode("p", null, "Entregue")
              ], -1)),
              _createElementVNode("div", _hoisted_20, [
                (_ctx.changeValue > 0)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_21, "Em falta"))
                  : (_openBlock(), _createElementBlock("p", _hoisted_22, "Troco"))
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("p", null, _toDisplayString(_ctx.moneyGiven) + " €", 1)
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("p", {
                  class: _normalizeClass(['w-full font-bold text-4xl text-right', (_ctx.changeValue > 0) ? 'text-red-500' : 'text-green-500'])
                }, _toDisplayString(_ctx.changeValueString) + " €", 3)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_NumericKeyboard, {
              class: "text-gray-600",
              onClicked: _ctx.appendMoneyGiven
            }, null, 8, ["onClicked"])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("button", {
              class: "flex bg-green-500 hover:bg-green-600 w-full rounded py-4 px-3",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.savePaymentMethod(_ctx.paymentMethodSelected)))
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("span", { class: "uppercase font-medium flex-1" }, "Terminar", -1),
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                class: "w-6 h-6"
              }, [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  d: "M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                })
              ], -1)
            ])),
            _createElementVNode("button", {
              class: "flex items-center bg-yellow-500 hover:bg-yellow-600 w-full rounded py-1 px-3 font-light",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleChangeView && _ctx.toggleChangeView(...args)))
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 24 24",
                "stroke-width": "1.5",
                stroke: "currentColor",
                class: "w-4 h-4"
              }, [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  d: "M15.75 19.5L8.25 12l7.5-7.5"
                })
              ], -1),
              _createElementVNode("span", { class: "flex-1" }, "Voltar", -1)
            ]))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}