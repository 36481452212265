
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectList from "@/components/Lists/SelectList.vue";
import {SearchIcon} from "@heroicons/vue/outline";
import POSnotyf from "@/libs/POSnotyf";

export default defineComponent({
  name: "ModalPriceLine",
  data() {
    return {}
  },
  components: {
    SelectList,
    SearchIcon
  },
  props: [],
  computed: {
    ...mapGetters(['priceLines']),
  },
  methods: {
    ...mapMutations(['activePriceLine', 'activateDefaultPriceLine']),
    ...mapActions(['closeActiveModal']),
    setDefault() {
      this.activateDefaultPriceLine()
      this.close()
      POSnotyf.success(`<strong>Ativada</strong> linha de preço por defeito.`)
    },
    selected(priceLine: any) {
      this.activePriceLine(priceLine['linha'])
      POSnotyf.success(`<strong>Ativada</strong> linha de preço <strong>${priceLine['linha']}.`)
      this.close()
    },
    close() {
      this.closeActiveModal()
    }
  },
  watch: {},
  mounted() {
  }
})
