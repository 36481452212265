const DocumentTypes = {
    state: {
        documentTypes: localStorage.getItem('documentTypes') || '[]',
    },
    mutations: {
        documentTypes(state: any, documentTypes: any) {
            state.documentTypes = JSON.stringify(documentTypes)
            localStorage.setItem('documentTypes', JSON.stringify(documentTypes))
        }
    },
    getters: {
        documentTypes(state: any) {
            return JSON.parse(state.documentTypes)
        },
    },
    actions: {
        getDocumentTypeData(state: any, documentTypeId: any) {
            return state.getters.documentTypes.find((documentType: any) => {
                return parseInt(documentType.id_tipo_documento) === parseInt(documentTypeId)
            })
        },
        fetchDocumentTypes(state: any) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}tiposdocumento/?tipo=venda`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(documentTypes => {
                        state.commit('documentTypes', documentTypes)
                        resolve(documentTypes)
                    })
                    .catch(err => reject(err))
            })
        }
    }
}

export {
    DocumentTypes
}
