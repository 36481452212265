import POSnotyf from "@/libs/POSnotyf";

const fnPickup = {
    actions: {
        fnOpenDeliveryView(state: any) {
            state.commit('activeView', 'Delivery')
        },
        fnPickupDateWeekday(state: any, weekdayNumber: number) {

            let date = new Date()
            let daysToAdd = 0

            const weekdays = [
                'Domingo',
                'Segunda-feira',
                'Terça-feira',
                'Quarta-feira',
                'Quinta-feira',
                'Sexta-feira',
                'Sábado'
            ];

            // get current weekday number
            const currentWeekDay = date.getDay()

            // check how many days to add
            if (currentWeekDay >= weekdayNumber) {
                daysToAdd = (7 - (currentWeekDay - weekdayNumber))
            } else {
                daysToAdd = weekdayNumber - currentWeekDay
            }

            // increment days into the date object
            date.setDate(date.getDate() + daysToAdd)

            // get processed date
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()

            // return calculated pickup date
            state.commit('pickupDate', `${year}/${month}/${day}`)

            POSnotyf.success(`Definido dia de <strong>levantamento</strong> para<br><br><strong class="text-2xl">${year}/${month}/${day}</strong><br><span class="italic">${weekdays[weekdayNumber]}</span>`)
        },
        fnClearPickupDate(state: any) {
            state.commit('clearPickupDate')
            POSnotyf.success(`Removido dia de levantamento definido!`)
        }
    }
}

export {
    fnPickup
}