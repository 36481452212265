
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "MobileFooter",
  data() {
    return {
      componentName: "MobileHeader",
    }
  },
  components: {},
  emits: [
    'openMobileOperations'
  ],
  computed: {
    ...mapGetters(['workDocumentTotal'])
  },
  methods: {
    openMobileOperations() {
      this.$emit('openMobileOperations')
    }
  },
  watch: {},
  mounted() {
  }
})
