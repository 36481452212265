
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import OperationsSectionDetailsRow from "@/components/OperationsSectionDetailsRow.vue";

export default defineComponent({
  name: 'OperationsSectionDetails',
  components: {OperationsSectionDetailsRow},
  props: ['maxListSize'],
  data() {
    return {
      detailsRowHeight: 62,
      start: 0,
      end: 0
    }
  },
  computed: {
    ...mapGetters(['itemsList', 'inicialRowLength', "workDocumentItems", 'isPortrait']),
  },
  methods: {
    ...mapMutations(['setMaximumVisibleListRows']),
    maximumGridRows() {
      if(this.maxListSize > 0) return this.maxListSize

      let elemSize = 0
      if(this.$refs.OperationsSectionDetails != undefined){
        elemSize = (this.$refs.OperationsSectionDetails as any).parentNode.clientHeight
      }
      let value = Math.floor(elemSize / this.detailsRowHeight)

      return value ? value : 1
    },
  },
  mounted() {
    this.end = this.maximumGridRows()
    this.setMaximumVisibleListRows(this.end);
    
    window.addEventListener('resize', () => {
      this.end = this.maximumGridRows()
      this.setMaximumVisibleListRows(this.end);
    });
  },
  beforeDestroy() { 
    window.removeEventListener('resize', () => {
      this.setMaximumVisibleListRows(this.maximumGridRows());
    });
  },
})
