
import {defineComponent} from "vue";

export default defineComponent({
  name: 'NumericKeyboardKey',
  components: {},
  emits: ['clicked'],
  props: ['clickValue', 'text'],
  data() {
    return {}
  },
  computed: {},
  methods: {
    clicked() {
      this.$emit('clicked', this.clickValue)
    }
  },
  mounted() {
  }
})
