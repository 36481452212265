import {v4} from "uuid";

const Page = {
    state: {
        currentPage: 0,
        currentButtonsPagination: 0,
        visitedPages: [],
    },
    mutations: {
        setCurrentPage(state: any, page: any) {
            state.currentPage = parseInt(page)
            state.currentButtonsPagination = 0
            state.visitedPages.push(page)
        },
        moveToNextButtonsPagination(state: any) {
            state.currentButtonsPagination++
        },
        moveToPreviousButtonsPagination(state: any) {
            if (state.currentButtonsPagination > 0) {
                state.currentButtonsPagination--
            }
        }
    },
    getters: {
        currentPage(state: any) {
            return state.currentPage
        },
        currentPageData(state: any, getters: any) {
            let pages = getters.pages
            return pages.find((page: any) => {
                return (page && page.id === state.currentPage)
            })
        },
        lastButtonsArrayFilledPosition(state: any, getters: any): number {

            if (!getters.currentPageData) return 0

            let pageButtonsData = getters.currentPageData.buttons ?? []
            let index = pageButtonsData.length

            while (index-- && !pageButtonsData[index]) {
                /* decrement search index until last used positon on the array */
            }

            return index
        },
        canGoBackPagination(state: any): boolean {
            return state.currentButtonsPagination > 0
        },
        canGoForwardPagination(state: any, getters: any): boolean {
            let currentButtonsPage = getters.currentButtonsPagination
            let maximumPageButtons = getters.maximumPageButtons ?? 0

            return (getters.lastButtonsArrayFilledPosition >= ((currentButtonsPage + 1) * maximumPageButtons))
        },
        currentButtonsPagination(state: any) {
            return state.currentButtonsPagination
        },
        currentPageDimensions(state: any, getters: any): any {
            if (getters.currentPageData) {
                return {
                    columns: getters.currentPageData.columns || 5,
                    rows: getters.currentPageData.rows || 5,
                    columns_mobile: getters.currentPageData.columns_mobile || 1,
                    rows_mobile: getters.currentPageData.rows_mobile || 1,
                }
            } else {
                return {
                    columns: 0,
                    rows: 0,
                    columns_mobile: 0,
                    rows_mobile: 0,
                }
            }
        },
        maximumPageButtons(state: any, getters: any) {
            let {columns, rows, columns_mobile, rows_mobile} = getters.currentPageDimensions

            // check if the resolution is mobile
            if (getters.isMobileResolution) {
                return columns_mobile * rows_mobile
            } else {
                return columns * rows
            }
        },
        currentPageMaximumColumns(state: any, getters: any) {
            let {columns, columns_mobile} = getters.currentPageDimensions
            if (getters.isMobileResolution) {
                return columns_mobile
            } else {
                return columns
            }
        },
        currentPageMaximumRows(state: any, getters: any) {
            let {rows, rows_mobile} = getters.currentPageDimensions
            if (getters.isMobileResolution) {
                return rows_mobile
            } else {
                return rows
            }
        },
        currentPageButtons(state: any, getters: any) {

            /* checks if page data is already available */
            if (getters.currentPageData) {

                /* main variables for page rendering */
                let pageButtonsData = getters.currentPageData.buttons
                let renderedPageButtons = [] as any
                let currentButtonsPage = getters.currentButtonsPagination
                let maximumPageButtons = getters.maximumPageButtons ?? 0


                /* get page interval buttons */
                renderedPageButtons = pageButtonsData.slice((maximumPageButtons * currentButtonsPage), (maximumPageButtons * currentButtonsPage) + maximumPageButtons)

                /* fill empty buttons */
                for (let i = 0; i < maximumPageButtons; i++) {

                    /* check if undefined position or empty object */
                    if (typeof renderedPageButtons[i] === "undefined" || (typeof renderedPageButtons[i] === "object" && Object.keys(renderedPageButtons[i] ?? {}).length === 0)) {
                        renderedPageButtons[i] = {
                            uuid: v4(),
                            label: '',
                            backgroundColor: '#464957',
                            textColor: '#FFFFFF',
                            position: (maximumPageButtons * currentButtonsPage) + i
                        }
                    }
                }

                /* return buttons to be rendered */
                return renderedPageButtons
            } else {
                console.error('page_data_unavailable')
            }
        },
    },
    actions: {
        resetCurrentPage(state: any) {
            state.currentPage = 0
        },
        nextPage(state: any) {
            state.currentPage++
        },
        previousPage(state: any) {
            (state.currentPage > 0) ? state.currentPage-- : state.currentPage
        }
    },
}

export {
    Page
}