import {PrintInvoiceParams} from "@/interfaces/PrintInvoiceParams";
import {PrintQueueJob} from "@/interfaces/PrintQueueJob";

const Agents = {
    state: {
        agents: [],
        agentPrinters: [],
        printQueue: [],
    },
    mutations: {
        setAgents(state: any, agents: any) {
            state.agents = agents
        },
        setAgentPrinters(state: any, agentPrinters: any) {
            state.agentPrinters = agentPrinters
        },
        addToPrintQueue(state: any, params: PrintQueueJob) {
            state.printQueue.push(params)
        },
        clearPrintQueue(state: any) {
            state.printQueue = []
        },
        processPrintQueue(state: any) {
            state.printQueue.shift()
        },
    },
    getters: {
        getAgents(state: any) {
            return state.agents
        },
        getAgentPrinters(state: any) {
            return state.agentPrinters
        },
        getAgentSlipPrinters(state: any) {
            return state.agentPrinters.filter((printer: any) => {
                return printer.tipo === 'talao'
            })
        },
        noAgentAvailable(state: any) {
            return state.agents.length === 0
        },
        onlyOneAgentAvailable(state: any) {
            return state.agents.length === 1
        },
        noPrinterAvailable(state: any, getters: any) {
            if (state.agents.length === 0) {
                return true
            } else {
                return getters.getAgentSlipPrinters.length === 0;
            }
        },
        onlyOnePrinterAvailable(state: any, getters: any) {
            return getters.getAgentSlipPrinters.length === 1;

        },
        printQueue(state: any) {
            return state.printQueue
        },
        pendingPrintJobs(state: any, getters: any) {
            return (getters.printQueue.length > 0)
        },
        nextPrintQueueJob(state: any, getters: any) {
            if (getters.pendingPrintJobs)
                return getters.printQueue[0]
        },
    },
    actions: {
        fetchAgents(state: any) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}agentes`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(agents => {
                        state.commit('setAgents', agents)
                        resolve(agents)
                    })
                    .catch(err => reject(err))
            })
        },
        fetchAgentPrinters(state: any, agentID: string) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}impressorasagente/?p=${agentID}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(printers => {
                        state.commit('setAgentPrinters', printers)
                        resolve(printers)
                    })
                    .catch(err => reject(err))
            })
        },
        printDocument(state: any, params: PrintInvoiceParams) {
            return new Promise<void>((resolve, reject) => {
                fetch(`${state.getters.apiURL}agenteimprimir/?p=${params.agentID}&d=${params.invoiceID}&td=${params.documentType}&i=${params.printerName}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.text())
                    .then(res => {
                        if (res === 'ok') {
                            resolve()
                        } else {
                            reject(res)
                        }
                    })
                    .catch(err => reject(err))
            })
        },
        specificAfterPrintActions(state: any, printJob: PrintQueueJob) {

            // logout after print session end slip
            if (printJob.documentType === 'fecho' && printJob.logout !== false) {
                state.dispatch('fnLogout')
            }
        },
        async processPrintQueue(state: any, params: PrintInvoiceParams) {
            let queue = state.getters.printQueue

            // process print queue if there are waiting jobs
            if (queue.length > 0) {
                for (let printJob of queue) {

                    // send print job to Goldylocks API
                    await state.dispatch('printDocument', {
                        ...params,
                        ...printJob,
                    })

                    // remove print job from the queue
                    state.commit('processPrintQueue')

                    // execute specific operations after printing
                    state.dispatch('specificAfterPrintActions', printJob)
                }
            } else {
                // print current invoice
                await state.dispatch('printCurrentInvoice', params)
            }

        },
        openDrawer(state: any, stationID: string) {
            return new Promise<void>((resolve, reject) => {

                let formData = new FormData();
                formData.append('id_agente', stationID)

                fetch(`${state.getters.apiURL}abrirgaveta`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    },
                    body: formData,
                })
                    .then(res => res.text())
                    .then(res => {
                        (res === 'ok') ? resolve() : reject()
                    })
                    .catch(err => reject(err))
            })
        }
    },
}

export {
    Agents
}