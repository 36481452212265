import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "h-full w-full bg-raisinBlack-500" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex flex-col px-4 py-2" }
const _hoisted_4 = { class: "px-4 flex flex-col" }
const _hoisted_5 = { class: "bg-white px-4 py-1 flex justify-between mb-1" }
const _hoisted_6 = { class: "flex sm:gap-8 flex-col sm:flex-row" }
const _hoisted_7 = { class: "w-40" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "text-xs" }
const _hoisted_10 = { class: "font-thin text-sm" }
const _hoisted_11 = { class: "text-xs" }
const _hoisted_12 = { class: "flex items-end" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "flex flex-col px-4 py-2" }
const _hoisted_16 = { class: "py-4" }
const _hoisted_17 = { class: "text-white uppercase text-xl" }
const _hoisted_18 = { class: "text-xs font-thin" }
const _hoisted_19 = { class: "flex flex-col gap-1" }
const _hoisted_20 = { class: "bg-white h-24 sm:h-16 flex flex-col sm:flex-row justify-between px-4 py-2" }
const _hoisted_21 = { class: "flex flex-col" }
const _hoisted_22 = { class: "font-bold" }
const _hoisted_23 = { class: "text-xs" }
const _hoisted_24 = { class: "font-thin" }
const _hoisted_25 = { class: "flex justify-between gap-1" }
const _hoisted_26 = { class: "flex flex-row sm:flex-col gap-2 sm:gap-0 bg-orange-800 h-8 sm:h-full text-white font-semibold px-1 sm:px-2 py-1 rounded" }
const _hoisted_27 = { class: "flex items-center justify-center" }
const _hoisted_28 = ["innerHTML"]
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { class: "flex flex-row sm:flex-col gap-2 sm:gap-0 bg-indigo-600 h-8 sm:h-full text-white font-semibold px-1 sm:px-2 py-1 rounded" }
const _hoisted_31 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KeyboardInput = _resolveComponent("KeyboardInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hasSelectedCustomer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "py-4" }, [
              _createElementVNode("div", { class: "text-white uppercase text-xl" }, " Levantamento / Pickup "),
              _createElementVNode("div", { class: "text-gray-50 font-thin" }, [
                _createTextVNode(" Pesquisar artigos por levantar de clientes. Selecione o "),
                _createElementVNode("strong", null, "cliente"),
                _createTextVNode(" em baixo. ")
              ])
            ], -1)),
            _createElementVNode("div", null, [
              _createVNode(_component_KeyboardInput, {
                modelValue: _ctx.customerSearchString,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customerSearchString) = $event)),
                "auto-focus": true
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customersSearchResults, (customer) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(customer.nome), 1),
                    _createElementVNode("div", _hoisted_9, "Cód. " + _toDisplayString(customer.id), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_10, "NIF: " + _toDisplayString(customer.nif), 1),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(customer.morada), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", {
                    class: "bg-green-600 h-12 w-12 rounded shadow flex items-center justify-center text-white",
                    onClick: ($event: any) => (_ctx.setCustomerID(customer.id, customer))
                  }, _cache[2] || (_cache[2] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      class: "h-8 w-8",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                      "stroke-width": "2"
                    }, [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M9 5l7 7-7 7"
                      })
                    ], -1)
                  ]), 8, _hoisted_13)
                ])
              ]))
            }), 256))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[3] || (_cache[3] = _createTextVNode(" Levantamento / Pickup ")),
                _createElementVNode("span", _hoisted_18, "(" + _toDisplayString(_ctx.selectedCustomerID) + " - " + _toDisplayString(_ctx.selectedCustomerData.nome) + ")", 1)
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-gray-50 font-thin" }, [
                _createTextVNode(" Selecione as "),
                _createElementVNode("strong", null, "linhas"),
                _createTextVNode(" a dar como entregues / levantados. ")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_19, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerPendingPickups, (pickup) => {
                return (_openBlock(), _createElementBlock("div", null, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, _toDisplayString(pickup.descricao), 1),
                      _createElementVNode("div", _hoisted_23, [
                        _createTextVNode(_toDisplayString(pickup.documento) + " ", 1),
                        _createElementVNode("span", _hoisted_24, "(" + _toDisplayString(pickup.data) + ")", 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-xs hidden sm:visible" }, "Entregues", -1)),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("span", {
                            class: "text-xs",
                            innerHTML: _ctx.roundValue(pickup.satisfeito)
                          }, null, 8, _hoisted_28),
                          _cache[5] || (_cache[5] = _createTextVNode(" / ")),
                          _createElementVNode("span", {
                            class: "text-lg font-bold",
                            innerHTML: _ctx.roundValue(pickup.quantidade)
                          }, null, 8, _hoisted_29)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-xs hidden sm:visible" }, "Entrega em", -1)),
                        _createElementVNode("span", null, _toDisplayString(pickup.data_entrega), 1)
                      ]),
                      _createElementVNode("button", {
                        class: "h-12 w-12 bg-green-600 rounded shadow text-white flex justify-center items-center",
                        onClick: ($event: any) => (_ctx.processPendingLine(pickup.id_movimento))
                      }, _cache[8] || (_cache[8] = [
                        _createElementVNode("svg", {
                          xmlns: "http://www.w3.org/2000/svg",
                          class: "h-8 w-8",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor",
                          "stroke-width": "2"
                        }, [
                          _createElementVNode("path", {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          })
                        ], -1)
                      ]), 8, _hoisted_31)
                    ])
                  ])
                ]))
              }), 256))
            ])
          ])
        ]))
  ]))
}