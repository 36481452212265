const fnTableManagement = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fnDeselectTable(state: any) {
            state.commit('currentTable', null)
            state.commit('clearItems')
        },
    }
}

export {
    fnTableManagement
}
