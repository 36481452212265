
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "TableSelectionView",
  data() {
    return {
      componentName: "TableSelectionView",
      status: false
    }
  },
  components: {},
  props: [],
  computed: {
    ...mapGetters(['getMoveTable','savedTableZone','tablesZones', 'tables','activeTableZoneID','getCurrentTablesZone']),
  },
  methods: {
    ...mapMutations(['setSecondTableData','setSecondeTableid','setMoveTable','setSecondTable','currentTablesZone', 'currentTable', 'workDocumentItemRaw', "setCurrentPage"]),
    ...mapActions(['goToTableZone','fetchTablesZones', 'fetchZoneTablesStatus', 'activateFirstTablesZone', "fnOpenView", 'fnDeselectTable']),

    setTablesZone(tableZoneID: number) {
      this.currentTablesZone(tableZoneID)
      this.fetchZoneTablesStatus(tableZoneID)
    },
    selectTable(tableData: any) {

      if(this.getMoveTable){
        // verify if view was call to transfer items between tables or to select table
        this.setMoveTable(false)
        // get tableData from the second table for after moving item save tem on original table if needed
        this.setSecondTable(JSON.parse(tableData.artigos_json))
        this.setSecondTableData(tableData)
        this.fnOpenView('MoveTable')
      }
      else{

        this.currentTable(tableData)
        this.workDocumentItemRaw(tableData.artigos_json)
        this.fnOpenView('Items')
        this.setCurrentPage(0)

      }

    },
    busyTable(tableData: any) {
      return (tableData.artigos_json !== '[]')
    }
  },
  watch: {},
  async mounted() {


    await this.activateFirstTablesZone(this.savedTableZone)


  }
})
