
import {defineComponent, ref, onMounted, Ref} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import POSnotyf from "@/libs/POSnotyf";
import KeyboardInput from "@/components/Inputs/KeyboardInput.vue";

export default defineComponent({
  name: "ModalFeatures",
  setup() {},
  data() {
    return {
      selectedFeatures: [],
    }
  },
  components: {KeyboardInput},
  props: [],
  computed: {
    ...mapGetters(['getFeaturesType']),

  },
  methods: {
    ...mapMutations(['clearFeaturesType', 'addFeaturesToItems']),
    ...mapActions(['closeActiveModal']),
    scrollUp() {
      this.$refs.searchFeatures.scrollBy({top: -74, behavior: 'smooth'});
    },
    scrollDown() {
      this.$refs.searchFeatures.scrollBy({top: 74, behavior: 'smooth'});
    },

    close() {
      this.closeActiveModal()
    },
    escapeKey(event: any) {

      // escape key closes modal
      if (event.key === `Escape`) {
        this.closeActiveModal()
      }
    },
    addFeature() {
      this.addFeaturesToItems(this.selectedFeatures)
    },
  },
  mounted() {
    window.addEventListener('keydown', this.escapeKey);

  },
  unmounted() {
    window.removeEventListener('keydown', this.escapeKey);
    this.clearFeaturesType();
  }
})
