import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "h-full flex w-full relative",
  ref: "OperationsSectionDetails"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OperationsSectionDetailsRow = _resolveComponent("OperationsSectionDetailsRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "OperationsSectionDetails",
      class: _normalizeClass(_ctx.isPortrait ? 'h-full flex-1 grid grid-cols-1 grid-rows-' + _ctx.end : 'flex flex-col w-full absolute top-0')
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsList.slice(0,_ctx.end), (itemRow, index) => {
        return (_openBlock(), _createBlock(_component_OperationsSectionDetailsRow, {
          key: index,
          "row-data": itemRow,
          class: _normalizeClass({'h-full w-full':_ctx.isPortrait})
        }, null, 8, ["row-data", "class"]))
      }), 128))
    ], 2)
  ], 512))
}