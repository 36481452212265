import {v4} from "uuid";

const DefaultOperationsButtons = [
    {
        uuid: v4(),
        position: 19,
        label: `Comentários`,
        backgroundColor: '#1f1d2b',
        textColor: '#fff',
        fn: 'fnFeatures',
        params: '',
        system: true,
        custom: false,
    },
    {
        uuid: v4(),
        position: 20,
        label: `Gaveta`,
        backgroundColor: '#1f1d2b',
        textColor: '#fff',
        fn: 'fnOpenDrawer',
        params: '',
        system: true,
        custom: false,
    },{
        uuid: v4(),
        position: 21,
        label: `Pesquisar artigos`,
        backgroundColor: '#12a0ec',
        textColor: '#fff',
        fn: 'fnSearchItems',
        params: '',
        system: true,
        custom: false,
    },
    {
        uuid: v4(),
        position: 22,
        label: `Quantidade`,
        backgroundColor: '#1f1d2b',
        textColor: '#fff',
        fn: 'fnChangeItemQuantity',
        params: '',
        system: true,
        custom: false,
    },
    {
        uuid: v4(),
        position: 23,
        label: `Alterar preço`,
        backgroundColor: '#1f1d2b',
        textColor: '#fff',
        fn: 'fnChangeItemPrice',
        params: '',
        system: true,
        custom: false,
    },
    {
        uuid: v4(),
        position: 24,
        label: `Fechar conta`,
        backgroundColor: '#27ae60',
        textColor: '#fff',
        fn: 'fnCreateInvoiceFromWorkDocument',
        params: '',
        system: true,
        custom: false,
    },
]

export {
    DefaultOperationsButtons
}