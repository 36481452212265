
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: 'PageButton',
  components: {},
  props: ['button'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['base64Images', 'currentPage', 'editMode', 'currentEditModeButtonData', 'activeButtonUUID', 'itemsThumbnailBaseURL']),
    style(): any {
      return [
        this.buttonBackground,
        `color:${this.button.textColor}`,
      ].join('')
    },
    beingEdited(): boolean {
      return (this.editMode && this.button.uuid === this.activeButtonUUID) || !this.editMode
    },
    buttonBackground: function (): string {

      let imageURL = null

      if (this.button.imageURL) {

        if (typeof this.base64Images[this.button.imageURL] !== "undefined") {

          imageURL = `data:image/jpeg;base64, ${this.base64Images[this.button.imageURL]}`

        } else {
          /* set button background as the server image  URL*/
          imageURL = this.button.imageURL

          /* fetch image as base 64 */
          this.fetchImageAsBase64(this.button.imageURL)
        }
      } else if (this.button.imagem) {
        imageURL = `${this.itemsThumbnailBaseURL}${this.button.imagem}_thumb.jpg`
      }

      let image: string = `
            background-image: url('${imageURL}');
            background-repeat: no-repeat, repeat;
            background-position: center;
            background-size: cover;
      `;

      return (imageURL) ? image : `background-color:${this.button.backgroundColor};`
    },
    hasImage(): boolean {
      return (this.button.imagem || this.button.imageURL)
    }
  },
  methods: {
    ...mapMutations(['editModeButtonData', 'setActiveButtonUUID']),
    ...mapActions(['existsBase64ImageData', 'fetchImageAsBase64', 'exec', 'setButtonPosition']),
    executeFn() {
      if (this.editMode) {
        // EDIT MODE ACTION
        this.setActiveButtonUUID(this.button.uuid)
        this.editModeButtonData(this.button)
      } else {
        // NORMAL MODE ACTION
        this.exec({
          fn: this.button.fn || '',
          params: this.button.params || '',
        })
      }
    },
  },
  mounted() {
  }
})
