const LicensePlate = {
    state: {
        licensePlate: '' as string,
    },
    mutations: {
        insertLicensePlate(state: any, data: string) {
            state.licensePlate = data.toUpperCase()
        },

        clearLicensePlate(state: any) {
            state.licensePlate = ''
        },
    },
    getters: {
        getLicensePlate(state: any): string {
            return state.licensePlate
        },
    }
}

export {
    LicensePlate,
}