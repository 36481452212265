
import {defineComponent} from "vue";
import {SearchIcon, XIcon} from "@heroicons/vue/outline";
import SelectList from "@/components/Lists/SelectList.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "ModalPageFunctions",
  data() {
    return {
      componentName: "ModalPageFunctions",
      showParameters: false,
      selectedFunctionData: {},
      selectedFunctionParameters: ''
    }
  },
  components: {
    SearchIcon,
    XIcon,
    SelectList
  },
  props: [],
  computed: {
    ...mapGetters(['getPOSFunctions'])
  },
  methods: {
    ...mapActions(['closeActiveModal', 'setSelectedNewFunction', 'setSelectedNewFunctionParam']),
    ...mapMutations(['setSelectedNewFunction', 'setSelectedNewFunctionParam']),
    selected(data: any) {
      this.showParameters = data.usesParameter
      this.selectedFunctionData = data
    },
    save() {
      if(this.showParameters && this.selectedFunctionParameters === '') return

      this.setSelectedNewFunction(this.selectedFunctionData)
      this.setSelectedNewFunctionParam(this.selectedFunctionParameters)
      this.closeActiveModal()
    },
  },
  watch: {},
  mounted() {
  }
})
