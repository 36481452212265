const BarcodeScanner = {
    isListening: false,
    currentText: '',
    onEnterCallback: null,
    lastReadCharTime: 0,
    maximumScannedCharTimeInterval: 50,

    /**
     * clear store code
     */
    clearReadText() {
        this.currentText = ''
    },

    /**
     * add new char to the stored code
     * @param newChar
     */
    readChar(newChar: string) {
        const currentUnixTime = new Date().getTime()

        // check the last time a char has been read
        // if its greater than maximumScannedCharTimeInterval it clears the stored text
        if ((currentUnixTime - this.lastReadCharTime) > this.maximumScannedCharTimeInterval) {
            this.clearReadText()
        }

        // update last read time
        this.lastReadCharTime = currentUnixTime

        if (newChar === 'Enter' && this.currentText.length > 0) {
            // call onEnter
            this.executeOnEnterCallback()
        } else if (typeof newChar === 'string' && newChar.length === 1) {
            // append char to read code
            this.currentText += newChar
        }
    },

    /**
     * set the callback to be called when enter is pressed
     * @param callback
     */
    onEnter(callback: any) {
        if (typeof callback === 'function') this.onEnterCallback = callback
    },

    /**
     * execute the onEnter stored callback passing as parameter the stored code
     */
    executeOnEnterCallback() {
        if (typeof this.onEnterCallback === 'function') {
            // set a constant with the current read code
            const scannedCode = this.currentText

            // clear read code on the object
            this.clearReadText()

            // execute the onEnterCallback method
            this.onEnterCallback(scannedCode)
        }
    }
}

export {
    BarcodeScanner,
}