import {swal} from "@/libs/POSSwal";

const fnReports = {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        fnShowOnlineMovements(state: any) {
            state.dispatch('fnOpenView', 'OnlineMovements')
        },
        fnShowCurrentSessionTotals(state: any) {
            state.dispatch('fnOpenView', 'CurrentSessionTotals')
        }
    },
}

export {
    fnReports,
}
