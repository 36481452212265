const PaymentMethods = {

    state: {
        paymentMethods: localStorage.getItem('paymentMethods') || '[]',
    },
    mutations: {
        paymentMethods(state: any, paymentMethods: any) {
            state.paymentMethods = JSON.stringify(paymentMethods)
            localStorage.setItem('paymentMethods', JSON.stringify(paymentMethods))
        },
    },
    getters: {
        paymentMethods(state: any) {
            return JSON.parse(state.paymentMethods)
        },
    },
    actions: {
        fetchPaymentMethods(state: any) {
            return new Promise<any>((resolve, reject) => {
                fetch(`${state.getters.apiURL}metodospagamento`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`
                    }
                })
                    .then(res => res.json())
                    .then(paymentMethods => {
                        state.commit('paymentMethods', paymentMethods)
                        resolve(paymentMethods)
                    })
                    .catch(err => reject(err))
            })
        },
        getPaymentMethodById(state: any, paymentMethodId: string) {
            for (let paymentMethod of state.getters.paymentMethods) {
                if (paymentMethod.id_meio_pagamento === paymentMethodId) {
                    return paymentMethod
                }
            }
        },
    },
}

export {PaymentMethods}