import {Base64ImageData} from "@/interfaces/Base64ImageData";
import {encode} from "node-base64-image";

const Base64Images = {
    state: {
        base64Images: {},
    },
    mutations: {
        addBase64Image(state: any, base64ImageData: Base64ImageData) {
            if (base64ImageData.base64.length > 0) {
                state.base64Images[base64ImageData.url] = base64ImageData.base64
            }
        },
        clearBase64Images(state: any) {
            state.base64Images = {}
        }
    },
    getters: {
        base64Images(state: any): any {
            return state.base64Images
        }
    },
    actions: {
        async fetchImageAsBase64(state: any, url: string) {

            const base64 = await encode(url, {string: true})

            state.commit('addBase64Image', {
                url,
                base64,
            })
        },
        existsBase64ImageData(state: any, imageURL: string) {
            return (typeof state.getters.base64Images[imageURL] !== "undefined")
        },
    },
}

export {
    Base64Images,
}
