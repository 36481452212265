const ContentView = {
    state: {
        activeView: 'Items' as string,
        lockedOperationsSection: false as boolean,
    },
    getters: {
        activeView(state: any) {
            return state.activeView
        },
        lockedOperationsSection(state: any) {
            return state.lockedOperationsSection
        }
    },
    mutations: {
        activeView(state: any, activeView: string) {
            state.activeView = activeView
        },
        lockOperationsSection(state: any) {
            state.lockedOperationsSection = true
        },
        unlockOperationsSection(state: any) {
            state.lockedOperationsSection = false
        },
    },
    actions: {
        openUserSessionStartView(state: any) {
            state.commit('lockOperationsSection', true)
            state.commit('activeView', 'SessionStart')
        }
    },
}

export {
    ContentView
}
